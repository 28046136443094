import React from "react";
import { useState, useEffect, useRef } from "react";
import "../reglementStyle/reglementStyle.css";
import "../reglementStyle/ajoutReglement.css";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import { ToastContainer, toast } from "react-toastify";
import InputAdornment from "@mui/material/InputAdornment";
import { addMonths, format, parseISO } from "date-fns";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";

import {
  DateField,
  DateTimePicker,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { Yard } from "@mui/icons-material";

export const AjoutReglement = ({ setAjoutReglement }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const fermer = () => {
    setAjoutReglement(false);
  };
  const [modePaiement, setModePaiement] = useState("");

  const handleModePaiementChange = (e) => {
    setModePaiement(e.target.value);
  };

  const searchTextFieldRef = useRef(null);
  const [LP, setLP] = useState("o");
  const [idClient, setIdClient] = useState("");
  const [nomClient, setNomClient] = useState("");
  const [dateEntree, setDateEntree] = useState("");
  const [dateResrv, setDateResrv] = useState("");
  const [adresse, setAdresse] = useState("");
  const [num, setNum] = useState("");
  const [search, setSearch] = useState("");
  const [mail, setMail] = useState("");
  const [idStockage, setIdStockage] = useState("");
  const [idLocation, setIdLocation] = useState("");
  const [loyer, setLoyer] = useState("");
  const [vol, setVol] = useState("");
  const [client, setClient] = useState([]);
  const [location, setLocation] = useState([]);
  const [valeurPaie, setValeurPaie] = useState("");
  const [diffMPaie, setDiffMPaie] = useState(0);
  const [dateDebut, setDateDebut] = useState("2021-01-01");
  const [etatInput, setEtatInput] = useState(true);
  const currentDate = new Date().toISOString().slice(0, 10);
  const [datePaie, setDatePaie] = useState(currentDate);
  const [titreDifM, setTitreDifM] = useState("Manquant / Surplus");
  const [dateFin, setDateFin] = useState("2021 - 01 - 01");
  const [dateDebutLP, setDateDebutLP] = useState("");
  const [dateFinLP, setDateFinLP] = useState("");
  const [valeurPaieLP, setValeurPaieLP] = useState("");
  const [datePaieLP, setDatePaieLP] = useState("");
  const isMobilePortrait = useMediaQuery("(max-width:1207px)  ");

  const [idLocLP, setIdLocLP] = useState("");
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      chercherLoc();
    }
  };
  // maka info momba ilay location
  const chercherLoc = () => {
    if (/^\s*$/.test(search)) {
      viderChamp();
      // Le texte est vide ou ne contient que des espaces
    } else {
      // search = search.trim();
      //  setSearch(search.trimStart());
      axios
        .post(`${apiUrl}IHM/api/?search=${search}`)
        .then((response) => {
          const locationsData = response.data;
          // setClient(locationsData);
          console.log(response.data, "makaclient");
          setNomClient(locationsData[0].nom);
          setIdClient(locationsData[0].idClient);

          const adresse = `${response.data[0].ville} - ${response.data[0].codePostal} - ${response.data[0].adresse}`;
          const num = `${response.data[0].num1} - ${response.data[0].num2}`;
          const mail = `${response.data[0].mail} - ${response.data[0].mail2}`;
          setAdresse(adresse);
          setNum(num);
          setMail(mail);

          // maka info location;
          infoLocation(locationsData[0].idClient);
        })
        .catch((error) => {
          console.log(error);
          console.log("t6 lty ah ,t6", error);
        });
    }
    // maka info client;
  };
  const infoLocation = (idCli) => {
    axios
      .post(`${apiUrl}IHM/api/paiement.php?idCli=${idCli}`)
      .then(async (response) => {
        setEtatInput(false);
        console.log(response);
        const locationsData = response.data;
        // setClient(locationsData);
        setIdLocation(locationsData[0].idLocation);
        setVol(locationsData[0].totVolume);
        setLoyer(locationsData[0].loyer);
        setDiffMPaie(locationsData[0].diffMPaie);
        setIdStockage(locationsData[0].idStockage);
        setDateEntree(locationsData[0].dateEntree);
        setDateResrv(locationsData[0].dateReservation);
        dernPaiement(locationsData[0].idLocation);
        //THISSS
        await setDateDebut(locationsData[0].dateReservation);
      })
      .catch((error) => {
        setEtatInput(true);
        console.error("error");
        console.log("t6 lty ah ,t6");
        toast.warn("ce client n'a pas de location en cours");
      });
  };
  //info du dernier paiement
  const dernPaiement = (idLoc) => {
    console.log("dernierpaiement");
    axios
      .get(`${apiUrl}IHM/api/paiement.php?idLoc=${idLoc}`)
      .then(async (response) => {
        const LPData = response.data;

        console.log(LPData[0].datePaie, "locationsData[0].datePaie");
        setDateDebutLP(LPData[0].dateDebut);
        setDateFinLP(LPData[0].dateFin);

        await setDateDebut(LPData[0].dateFin);

        setValeurPaieLP(LPData[0].valeurPaie);
        setDatePaieLP(LPData[0].datePaie);
        setIdLocLP(LPData[0].idPaiement);

        setLP("o");
      })
      .catch((error) => {
        console.error("error");
        console.log(error, "error");
        setLP("n");
      });
  };

  const viderChamp = () => {
    setEtatInput(true);
    setClient("");
    setIdLocation("");
    setLoyer("");
    setMail("");
    setNomClient("");
    setIdClient("");
    setIdStockage("");
    setDateEntree("");
    setDateFin("");
    setAdresse("");
    setDateResrv("");
    setDatePaieLP("");
    setDateDebutLP("");
    setDateFinLP("");
    setNum("");
    setValeurPaieLP("");
    setVol("");
  };

  const calculerDateFin = () => {
    const date = new Date(dateDebut);
    console.log(dateDebut, "dateDebut");
    console.log(date, "date");
    //ajoute 1 mois
    const nouvelleDate = addMonths(date, 1);

    const dateFormatee = dayjs(nouvelleDate);

    setDateFin(dateFormatee.format("YYYY-MM-DD"));
  };

  useEffect(() => {
    const asyncFunction = async () => {
      setSelectedDateDebut(dayjs(dateDebut));
      await calculerDateFin();
      console.log("appel au formatage de ceci", dateFin);
    };

    asyncFunction();
  }, [dateDebut]);

  const ajoutReglem = async (e) => {
    e.preventDefault();
    console.log("ty le mampifangojy dateFinAjoutreglement", dateFin);
    if (dateDebut === "" || dateFin === "") {
      toast.warn("Date limite non rempli");

      return; // Arrêter la soumission du formulaire
    }

    if (modePaiement === "") {
      toast.warn("Veuillez verifier le champ mode de paiement");

      return; // Arrêter la soumission du formulaire
    }
    if (valeurPaie === "") {
      toast.warn("Veuillez verifier le champ valeur payée");

      return; // Arrêter la soumission du formulaire
    }
    if (idClient === "") {
      toast.warn("Veuillez entrer le nom ou numéro téléphone  du Client ");

      return;
    } else {
      setEtatInput(true);
      const dataPaie = {
        idLocation: idLocation,
        idStockage: idStockage,
        typePaie: modePaiement,
        datePaie: datePaie,
        valeurPaie: valeurPaie,
        dateDebut: dateDebut,
        dateFin: dateFin,
      };
      console.log("dateFinaza e", dataPaie.dateFin);
      try {
        await axios
          .put(`${apiUrl}IHM/api/paiement.php`, dataPaie)
          .then((response) => {
            console.log(response.data);
            console.log("okeyyy");
            setAjoutReglement(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log("AIZAEEE error");
        alert("paiement NON REUSSIE");
      }
    }
  };
  //pour mettre par défaut le curseur sur le recherche
  useEffect(() => {
    if (searchTextFieldRef.current) {
      searchTextFieldRef.current.focus();
    }
  }, []);

  useEffect(() => {
    formaterFin(dateFin);
  }, [dateFin]);

  const [erreur, setErreur] = useState(false);

  const handleValeurPaieChange = (e) => {
    const inputValue = e.target.value;

    // Vérifie si l'entrée est un nombre positif
    if (/^[+]?\d*\.?\d*$/.test(inputValue) || inputValue === "") {
      setValeurPaie(inputValue);

      const loyerFloat = parseFloat(loyer);
      const inputValueFloat = parseFloat(inputValue);
      // Calculer la différence entre les nombres entiers

      setDiffMPaie(inputValueFloat - loyerFloat);

      setErreur(false);
    } else {
      setErreur(true);
    }
  };
  useEffect(() => {
    // Vérifier si diffMPaie est négatif ou non
    if (diffMPaie < 0) {
      setTitreDifM("Manquant");
    } else {
      setTitreDifM("Surplus");
    }
  }, [diffMPaie]);

  const formattedDate = (dateRecup) => {
    if (dateRecup === "0000-00-00" || dateRecup === "") {
      return ``;
    }
    const date = new Date(dateRecup);
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth()}`
        : date.getMonth().toString();
    const year = date.getFullYear();
    return `${day}-${(parseInt(month) + 1)
      .toString()
      .padStart(2, "0")}-${year}`;
  };

  const [selectedDate, setSelectedDate] = useState(dayjs(dateFin));
  const [selectedDateDebut, setSelectedDateDebut] = useState(dayjs(dateDebut));
  const [selectedDatePaie, setSelectedDatePaie] = useState(dayjs(datePaie));

  const handleDateChangeDebut = (date) => {
    const dateko = new Date(date);
    const year = dateko.getFullYear();
    const month = dateko.getMonth() + 1; // Les mois sont indexés de 0 à 11, donc ajoutez 1
    const day = dateko.getDate();

    // Créez une chaîne de date au format "YYYY-MM-DD"
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    console.log(formattedDate, "tsy ay tony");
    setDateDebut(formattedDate);
  };

  const handleDateChange = (date) => {
    const dateko = new Date(date);
    const year = dateko.getFullYear();
    const month = dateko.getMonth() + 1; // Les mois sont indexés de 0 à 11, donc ajoutez 1
    const day = dateko.getDate();

    // Créez une chaîne de date au format "YYYY-MM-DD"
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    console.log(formattedDate, "tsy ay tony");
    setDateFin(formattedDate);
  };

  const handleDateChangePaie = (date) => {
    const dateko = new Date(date);
    const year = dateko.getFullYear();
    const month = dateko.getMonth() + 1; // Les mois sont indexés de 0 à 11, donc ajoutez 1
    const day = dateko.getDate();

    // Créez une chaîne de date au format "YYYY-MM-DD"
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    console.log(formattedDate, "tsy ay tony");
    setDatePaie(formattedDate);
  };

  const formaterFin = (date) => {
    const affiche = dayjs(date);
    setDateFormatee(affiche.format("DD-MM-YYYY"));
    setSelectedDate(dayjs(date));
  };
  const [dateFormatee, setDateFormatee] = useState("");
  // const [andrana, setAndrana] = useState(dayjs("03 - 15 - 03"));
  // const handleAndrana = (date) => {
  //   setAndrana(date);
  //   setDateFin(date);
  // };

  return (
    <div>
      <div className=" ">
        {!isMobilePortrait && (
          <div className="modalContainerLoc">
            <div>
              <CancelIcon
                style={{ cursor: "pointer" }}
                sx={{ marginLeft: "95%", fontSize: 40, marginTop: "-26px" }}
                onClick={() => setAjoutReglement(false)}
              />
              <h3 style={{ marginLeft: "40%" }}>
                Paiement pour la location n° {idLocation}
              </h3>{" "}
              <br />
              <br />
            </div>
            <br />{" "}
            <TextField
              id="standard-search"
              label="Adresse mail / numero / nom"
              type="text"
              variant="standard"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                viderChamp();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon
                      color="primary"
                      onClick={() => chercherLoc()}
                      style={{ cursor: "pointer" }}
                      fontSize="large"
                    />
                  </InputAdornment>
                ),
              }}
              style={{ width: "30%", marginLeft: "10%" }}
              //emplacement par défaut de souris
              inputRef={searchTextFieldRef}
              //bouton entrée
              onKeyDown={handleKeyDown}
            />
            <br />
            <form className="form" onSubmit={ajoutReglem}>
              <span className="parent-span-rgl">
                <span className="left-span-rgl">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      //  alignItems: "center",
                    }}
                  ></div>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="modePaiement-label">
                      Mode de Paiement
                    </InputLabel>

                    <Select
                      labelId="modePaiement-label"
                      id="modePaiement"
                      value={modePaiement}
                      onChange={handleModePaiementChange}
                      disabled={etatInput}
                    >
                      <MenuItem value="vir">Virement bancaire</MenuItem>
                      <MenuItem value="espc">Espèce</MenuItem>
                      <MenuItem value="cBl">carte bleu</MenuItem>
                      <MenuItem value="cq">Chèque</MenuItem>
                      <MenuItem value="ABOcBl">ABO carte bleu</MenuItem>
                    </Select>
                  </FormControl>
                  <br /> <br />
                  <TextField
                    id="standard-search"
                    label="Valeur payée (€)"
                    type="text"
                    variant="standard"
                    value={valeurPaie}
                    onChange={handleValeurPaieChange}
                    error={erreur}
                    helperText={erreur ? "Entrez un nombre positif" : ""}
                    style={{ width: "100%" }}
                  />
                  <br />
                  <br />
                  <TextField
                    id="standard-search"
                    label={titreDifM}
                    type="text"
                    variant="standard"
                    value={`${diffMPaie} €`}
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        textAlign: "right",
                        width: "86%",
                      },
                    }}
                    disabled
                  />
                  <br />
                  <br />
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      format="DD-MM-YYYY"
                      handleDateChangePaie
                      label="Date de paiement"
                      value={selectedDatePaie}
                      onChange={handleDateChangePaie}
                      style={{ display: "none" }}
                      disabled={etatInput}
                    />
                  </DemoContainer>
                  <br />
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DemoContainer components={["DatePicker"]}>
                        <DemoItem>
                          <DatePicker
                            format="DD-MM-YYYY"
                            label="Date Debut"
                            value={selectedDateDebut}
                            onChange={handleDateChangeDebut}
                            style={{ display: "none" }}
                            disabled={etatInput}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </Grid>
                    <Grid item xs={6}>
                      <DemoContainer components={["DatePicker"]}>
                        <DemoItem>
                          <DatePicker
                            format="DD-MM-YYYY"
                            label="Date Fin"
                            value={selectedDate}
                            onChange={handleDateChange}
                            style={{ display: "none" }}
                            disabled={etatInput}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </Grid>
                  </Grid>
                  <br /> <br />
                  <br />
                  <br />
                </span>
                <span className="right-span-rgl">
                  <div
                    className="LP"
                    style={{
                      // textAlign: "center",
                      fontWeight: "800",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "17px",
                      color: "#EC994B",
                      margin: "10px 0",
                    }}
                  >
                    {" "}
                    {idClient} - {nomClient}
                    <br />
                  </div>
                  Mail:
                  <span className="ajoutA"> {mail} </span>
                  <br />
                  Téléphone :<span className="ajoutA"> {num} </span>
                  <br />
                  Adresse :<span className="ajoutA"> {adresse} </span>
                  <br />
                  Box : <span className="ajoutA"> {idStockage}</span> <br />
                  Volume total : <span className="ajoutA"> {vol} m³</span>
                  <br /> Date de réservation :
                  <span className="ajoutA"> {formattedDate(dateResrv)} </span>
                  <br />
                  Date d'entrée :
                  <span className="ajoutA"> {formattedDate(dateEntree)} </span>
                  <br />
                  Loyer mensuel : <span className="ajoutA"> {loyer} € </span>
                  <br /> <br />
                  <br /> <br />
                  {LP === "n" ? (
                    <div>
                      <span className="ajoutA">
                        Premier paiement pour cette location{" "}
                      </span>
                    </div>
                  ) : (
                    <div className="LP">
                      <div
                        className="LP"
                        style={{
                          // textAlign: "center",
                          fontWeight: "800",
                          fontFamily: "Arial, sans-serif",
                          fontSize: "17px",
                          color: "#EC994B",
                          margin: "10px 0",
                        }}
                      >
                        DERNIER PAIEMENT <br />
                      </div>
                      Numero Paiement :
                      <span className="ajoutA"> {idLocLP}</span> <br />
                      Dernier paiement :
                      <span className="ajoutA">
                        {" "}
                        {formattedDate(datePaieLP)}
                      </span>{" "}
                      <br />
                      Date limite : du
                      <span className="ajoutA">
                        {" "}
                        {formattedDate(dateDebutLP)}
                      </span>{" "}
                      au{" "}
                      <span className="ajoutA">
                        {" "}
                        {formattedDate(dateFinLP)}
                      </span>
                      <br />
                      Valeur payé :
                      <span className="ajoutA"> {valeurPaieLP} € </span>
                    </div>
                  )}
                </span>
              </span>
              <Button
                className="myBouton"
                variant="contained"
                type="submit"
                sx={{ marginLeft: "40%", width: "auto", padding: "5px" }}
                disabled={etatInput}
              >
                Confirmer{" "}
              </Button>
            </form>
          </div>
        )}{" "}
        {isMobilePortrait && (
          <div className="modalContainerLoc">
            <div>
              <CancelIcon
                style={{ cursor: "pointer" }}
                sx={{ marginLeft: "90%", fontSize: 30, marginTop: "-6px" }}
                onClick={() => setAjoutReglement(false)}
              />

              <div style={{ textAlign: "center" }}>
                {" "}
                <span
                  style={{
                    display: "inline-block",
                    fontSize: 20,
                  }}
                >
                  Paiement pour la location n° {idLocation}
                </span>
              </div>
            </div>
            <br />
            <TextField
              id="standard-search"
              label="Adresse mail / numero / nom"
              type="text"
              variant="standard"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                viderChamp();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon
                      color="primary"
                      onClick={() => chercherLoc()}
                      style={{ cursor: "pointer" }}
                      fontSize="large"
                    />
                  </InputAdornment>
                ),
              }}
              style={{ width: "80%", marginLeft: "10%" }}
              //emplacement par défaut de souris
              inputRef={searchTextFieldRef}
              //bouton entrée
              onKeyDown={handleKeyDown}
            />
            <br />
            <div style={{ textAlign: "left", marginLeft: "10%" }}>
              <div
                className="LP"
                style={{
                  textAlign: "center",
                  fontWeight: "800",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "17px",
                  color: "#EC994B",
                  margin: "10px 0",
                }}
              >
                {idClient} - {nomClient}
                <br /> Mail:
                <span className="ajoutA"> {mail} </span>
              </div>
              Box : <span className="ajoutA"> {idStockage}</span> <br />
              Volume total : <span className="ajoutA"> {vol} m³</span>
              <br /> Date de réservation :
              <span className="ajoutA"> {formattedDate(dateResrv)} </span>
              <br />
              Date d'entrée :
              <span className="ajoutA"> {formattedDate(dateEntree)} </span>
              <br />
              Loyer mensuel : <span className="ajoutA"> {loyer} € </span>
              <br /> <br />
              {LP === "n" ? (
                <div>
                  <span className="ajoutA">
                    Premier paiement pour cette location{" "}
                  </span>
                </div>
              ) : (
                <div className="LP">
                  <div
                    className="LP"
                    style={{
                      // textAlign: "center",
                      fontWeight: "700",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "17px",
                      color: "#EC994B",
                      marginTop: "7px 0",
                    }}
                  >
                    DERNIER PAIEMENT : <br />
                  </div>
                  Numero Paiement :<span className="ajoutA"> {idLocLP}</span>{" "}
                  <br />
                  Dernier paiement :
                  <span className="ajoutA">
                    {" "}
                    {formattedDate(datePaieLP)}
                  </span>{" "}
                  <br />
                  Date limite : du
                  <span className="ajoutA">
                    {" "}
                    {formattedDate(dateDebutLP)}
                  </span>{" "}
                  au <span className="ajoutA"> {formattedDate(dateFinLP)}</span>
                  <br />
                  Valeur payé :
                  <span className="ajoutA"> {valeurPaieLP} € </span>
                </div>
              )}
            </div>
            <br />
            <form className="form" onSubmit={ajoutReglem}>
              <span className=" ">
                <span className=" ">
                  <FormControl variant="standard" style={{ width: "120%" }}>
                    <InputLabel id="modePaiement-label">
                      Mode de Paiement
                    </InputLabel>

                    <Select
                      labelId="modePaiement-label"
                      id="modePaiement"
                      value={modePaiement}
                      onChange={handleModePaiementChange}
                      disabled={etatInput}
                    >
                      <MenuItem value="vir">Virement bancaire</MenuItem>
                      <MenuItem value="espc">Espèce</MenuItem>
                      <MenuItem value="cBl">carte bleu</MenuItem>
                      <MenuItem value="cq">Chèque</MenuItem>
                      <MenuItem value="ABOcBl">ABO carte bleu</MenuItem>
                    </Select>
                  </FormControl>
                  <br /> <br />
                  <TextField
                    id="standard-search"
                    label="Valeur payée (€)"
                    type="text"
                    variant="standard"
                    value={valeurPaie}
                    onChange={handleValeurPaieChange}
                    error={erreur}
                    helperText={erreur ? "Entrez un nombre positif" : ""}
                    style={{ width: "120%" }}
                  />
                  <br />
                  <br />
                  <TextField
                    id="standard-search"
                    label={titreDifM}
                    type="text"
                    variant="standard"
                    value={`${diffMPaie} €`}
                    style={{ width: "120%" }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        textAlign: "right",
                        width: "120%",
                      },
                    }}
                    disabled
                  />
                  <br /> <br />
                  <div style={{ width: "120%" }}>
                    <DemoContainer
                      components={["DatePicker"]}
                      style={{ width: "120%" }}
                    >
                      <DatePicker
                        format="DD-MM-YYYY"
                        handleDateChangePaie
                        label="Date de paiement"
                        value={selectedDatePaie}
                        onChange={handleDateChangePaie}
                        style={{ display: "none" }}
                        disabled={etatInput}
                      />
                    </DemoContainer>
                    <br />
                    <DemoContainer
                      components={["DatePicker"]}
                      style={{ width: "120%" }}
                    >
                      <DatePicker
                        format="DD-MM-YYYY"
                        label="Date Debut"
                        value={selectedDateDebut}
                        onChange={handleDateChangeDebut}
                        style={{ width: "50%", display: "none" }}
                        disabled={etatInput}
                      />
                    </DemoContainer>
                    <br />
                    <DemoContainer
                      components={["DatePicker"]}
                      style={{ width: "120%" }}
                    >
                      <DatePicker
                        format="DD-MM-YYYY"
                        label="Date Fin"
                        value={selectedDate}
                        onChange={handleDateChange}
                        style={{ width: "120%", display: "none" }}
                        disabled={etatInput}
                      />
                    </DemoContainer>
                  </div>
                </span>
                <span className=" ">
                  <br /> <br />
                  {/* {LP === "n" ? (
                    <div>
                      <span className="ajoutA">
                        Premier paiement pour cette location{" "}
                      </span>
                    </div>
                  ) : (
                    <div className="LP">
                      <div
                        className="LP"
                        style={{
                          // textAlign: "center",
                          fontWeight: "800",
                          fontFamily: "Arial, sans-serif",
                          fontSize: "17px",
                          color: "#EC994B",
                          margin: "10px 0",
                        }}
                      >
                        DERNIER PAIEMENT <br />
                      </div>
                      Numero Paiement :
                      <span className="ajoutA"> {idLocLP}</span> <br />
                      Dernier paiement :
                      <span className="ajoutA">
                        {" "}
                        {formattedDate(datePaieLP)}
                      </span>{" "}
                      <br />
                      Date limite : du
                      <span className="ajoutA">
                        {" "}
                        {formattedDate(dateDebutLP)}
                      </span>{" "}
                      au{" "}
                      <span className="ajoutA">
                        {" "}
                        {formattedDate(dateFinLP)}
                      </span>
                      <br />
                      Valeur payé :
                      <span className="ajoutA"> {valeurPaieLP} € </span>
                    </div>
                  )} */}
                </span>
              </span>
              <Button
                className="myBouton"
                variant="contained"
                type="submit"
                sx={{ marginLeft: "40%", width: "auto", padding: "5px" }}
                disabled={etatInput}
              >
                Confirmer{" "}
              </Button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};
