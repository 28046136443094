import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { AuthProvider, useAuth } from "./pages/login/loginPage/AuthContext";
import App from "./App";
import { Login } from "./pages/login/loginPage/login";

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  return isAuthenticated ? <Element {...rest} /> : <Navigate to="/login" />;
};

const AppRouter = () => {
  const { login } = useAuth();

  useEffect(() => {
    // Écoute les événements de déconnexion provenant d'autres onglets
    const eventChannel = new BroadcastChannel("auth");

    //pour deconnecter tout les sessions dans les tab du navigateurs  quand l'un est déconnecté
    const handleAuthEvent = (event) => {
      if (event.data === "logout") {
        // Effectue la déconnexion
        localStorage.removeItem("isAuthenticated");
        // Appelle la fonction de déconnexion de votre contexte d'authentification
        login(null);
        // Redirige vers la page de connexion
        window.location.href = "/login";
      }
    };

    eventChannel.addEventListener("message", handleAuthEvent);

    return () => {
      eventChannel.removeEventListener("message", handleAuthEvent);
    };
  }, [login]);

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<ProtectedRoute element={App} />} />
        </Routes>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default AppRouter;
