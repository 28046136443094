import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../ClientStyle/client.css";
import * as ClientStyle from "../ClientStyle/clientStyle";
//import Button from "@mui/material/Button";
import { AjoutClient } from "./ajoutClient";
import { ModifierClient } from "./modifierClient";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from 'primereact/button';
import { SuprClient } from "./suprClient";
import useMediaQuery from "@mui/material/useMediaQuery";
import FiltreClient from "./FiltreClient";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { Toast } from "primereact/toast";
import Grid from '@mui/material/Grid';
import correctEncoding from "../../correctEncoding";
import EmailLink from "../../sendMail";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  Modal,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { Bar } from "react-chartjs-2";
import { fontWeight } from "@mui/system";

export const Client = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const isMobilePortrait = useMediaQuery("(max-width:1207px) ");
  const [titre, setTitre] = useState("Liste des clients");
  const style = {
    marginTop: "50%",
    paddingLeft: "50%",
    transform: "translate(-50%, -50%)",
  };
  const [valueFiltre, setValueFiltre] = React.useState("tout");
  const [recherche, setRecherche] = useState("");
  const [rescentCli, setRescentCli] = useState("");

  const handleChange = async (event) => {
    setValueFiltre(event.target.value);

    if (event.target.value === "nonActif") {
      setTitre("Liste des clients non actifs");
    } else if (event.target.value === "actif") {
      setTitre("Liste des clients actifs");
    } else {
      setTitre("Liste des Clients");
    }


    // axios

    //   .put(
    //     `${apiUrl}IHM/api/?recherche=${recherche}&filtre=${event.target.value}`
    //   )

    //   .then((response) => {
    //     const clientsData = response.data;
    //     setClients(clientsData);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };

  const searchTextFieldRef = useRef(null);

  const [selectedClient, setSelectedClient] = useState({
    idClient: "",
  });

  const [clients, setClients] = useState([]);

  useEffect(() => {
    filtrerClient(recherche);
    setRescentCli("");
  }, [valueFiltre]);

  // const listerClient = useCallback(() => {
  //   axios
  //     .get(`${apiUrl}IHM/api/?filtre=${valueFiltre}`)
  //     .then((response) => {
  //       const clientsData = response.data;
  //       setClients(clientsData);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // });

  const filtrerClient = (param) => {
    setRecherche(param.trimStart());
    // setCurrentPage(1);

    axios
      .put(`${apiUrl}IHM/api/?recherche=${param}&filtre=${valueFiltre}`)

      .then((response) => {
        const clientsData = response.data;
        console.log(response.data);
        setClients(clientsData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //mipasser anle page akary amle modifier ary de mila manao anty const vaovao inty  anavahany ny ajout sy ny update
  const [modifierClient, setModifierClient] = useState(false);
  const [suprClient, setSuprClient] = useState(false);

  //*************************************************************TABLEAU TABLEAU TABLEAU******************************************************** */

  const [visible, setVisible] = useState(false);

  const vide = (param) => {
    return param === "" ? "N" : "O";
  };

  useEffect(() => {
    renderClientList();

    if (searchTextFieldRef.current) {
      searchTextFieldRef.current.focus();
    }
  }, []);
  const actionTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          onClick={() => handleEdit(rowData.idClient)}
        />
        <Button
          icon="pi pi-trash"
          onClick={() => handleDelete(rowData.idClient)}
        />
      </>
    );
  };
  const handleEdit = (clientId) => {
    setSelectedClient(clientId);
    setRescentCli(clientId);
    setModifierClient(true);
  };

  const handleDelete = (clientId) => {
    setSelectedClient(clientId);
    setRescentCli("");
    setSuprClient(true);
  };
  const [tooltipText, setTooltipText] = useState("");
  const formattedDate = (dateRecup) => {
    if (dateRecup === "0000-00-00") {
      return `-`;
    }
    const date = new Date(dateRecup);
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth()}`
        : date.getMonth().toString();
    const year = date.getFullYear();
    return `${day}-${(parseInt(month) + 1)
      .toString()
      .padStart(2, "0")}-${year}`;
  };
  const renderTooltip = (rowData) => {
    return `${rowData.differenceJours} jours à payer depuis ${formattedDate(
      rowData.dateFin
    )} `;
  };
  const renderTooltipSansP = (rowData) => {
    if (rowData.dateEntree === null) {
      return `Aucun paiement depuis sa réservation : ${formattedDate(
        rowData.dateReservation
      )}`;
    } else {
      return `Aucun paiement depuis son entrée : ${formattedDate(
        rowData.dateEntree
      )}`;
    }
  };

  const renderTooltipAjour = (rowData) => {
    return `À jour jusqu'au ${formattedDate(rowData.dateFin)} `;
  };
  const toast = useRef(null);

  const renderClientList = () => {
    return (
      <div>
        <Grid container item xs={12} md={9} spacing={2} direction="column">

          <Toast ref={toast} />
          <div className="toutCL">
            {isMobilePortrait && (
              <div>
                <div className="center flex" style={{ paddingBottom: "10px" }}>
                  <div>
                    <FiltreClient
                      valueFiltre={valueFiltre}
                      handleChange={handleChange}
                      recherche={recherche}
                      filtrerClient={filtrerClient}
                      searchTextFieldRef={searchTextFieldRef}
                    />
                  </div>

                  <div></div>

                  <Button
                    label="Client"
                    onClick={() => setVisible(true)}
                    icon="pi pi-plus"
                    severity="info"
                    style={{ marginLeft: '650px', verticalAlign: 'middle' }}
                  />


                </div>
                <h2 className="titreTableau">{titre} </h2>
                <ClientStyle.StyledPaper>
                  <div className=" ">
                    <div
                      style={{
                        // width: "80%",
                        margin: "0 auto",
                      }}
                    >
                      <DataTable
                        value={clients}
                        className="p-datatable"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 8, 10, 25, 50, 500]}
                        rowClassName={(rowData) =>
                          `${rowData.encours === "oui" ? "enCours-bgd " : ""}${rowData.idClient === rescentCli
                            ? "rescentCli custom-row-class"
                            : "custom-row-class"
                          }`
                        }
                        columnSeparator="   "
                        // paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        paginatorTemplate={
                          "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        }
                        paginatorRight={
                          <span style={{ margin: "10px", fontWeight: "bold" }}>
                            Résultats : {clients.length}
                          </span>
                        }
                      >
                        <Column
                          style={{
                            width: "8vw",
                            maxWidth: "8vw",
                            paddingLeft: "10px",
                          }}
                          header="Client"
                          className="nom-column-content"
                          body={(rowData) => (
                            <>
                              {rowData && (
                                <span
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {rowData.idClient} - {rowData.nom}
                                </span>
                              )}
                            </>
                          )}
                        />

                        <Column
                          style={{
                            width: "4vw",
                            maxWidth: "4vw",
                            whiteSpace: "nowrap",
                          }}
                          className="nom-column-content"
                          header="Situation"
                          body={(rowData) =>
                            rowData.encours === "oui" ? (
                              <span
                                style={{
                                  whiteSpace: "nowrap",
                                }}
                                className="nom-column-content"
                              >
                                {rowData && (
                                  <span
                                    className="nom-column-content"
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {rowData.dateFin === "Sans paiement" ? (
                                      <>
                                        <Tooltip
                                          title={renderTooltipSansP(rowData)}
                                          arrow
                                        >
                                          <i
                                            className="pi pi-circle-fill"
                                            style={{
                                              fontSize: "10px",
                                              padding: "10px",
                                              cursor: "pointer",
                                              color: "#e4b61a",
                                            }}
                                          ></i>
                                        </Tooltip>
                                      </>
                                    ) : Number(rowData.differenceJours) >= 0 ? (
                                      <>
                                        {rowData.differenceJours > 30 ? (
                                          <>
                                            <Tooltip
                                              title={renderTooltip(rowData)}
                                              arrow
                                            >
                                              <i
                                                className="pi pi-circle-fill"
                                                style={{
                                                  fontSize: "10px",
                                                  padding: "10px",
                                                  cursor: "pointer",
                                                  color:
                                                    rowData.differenceJours < 45
                                                      ? "#e2711d"
                                                      : "#da2c38",
                                                }}
                                              ></i>
                                            </Tooltip>
                                          </>
                                        ) : (
                                          <>
                                            <Tooltip
                                              title={renderTooltip(rowData)}
                                              arrow
                                            >
                                              <i
                                                className="pi pi-circle-fill"
                                                style={{
                                                  fontSize: "10px",
                                                  padding: "10px",
                                                  cursor: "pointer",
                                                  color: "#e2711d",
                                                }}
                                              ></i>
                                            </Tooltip>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        À jour
                                        <Tooltip
                                          title={renderTooltipAjour(rowData)}
                                          arrow
                                        >
                                          <i
                                            className="pi pi-circle-fill"
                                            style={{
                                              fontSize: "10px",
                                              padding: "10px",
                                              cursor: "pointer",
                                              color: "#a1c181",
                                            }}
                                          ></i>
                                        </Tooltip>
                                      </>
                                    )}
                                  </span>
                                )}
                                <br />
                              </span>
                            ) : null
                          }
                        />
                        {localStorage.getItem("pr") === "sad" ||
                          localStorage.getItem("pr") === "st" ||
                          localStorage.getItem("pr") === "ad" ? (
                          <Column
                            style={{
                              width: "4vw",
                              maxWidth: "4vw",
                            }}
                            header="Actions"
                            body={(rowData) => (
                              <span style={{ width: "5px", maxWidth: "5px" }}>
                                {rowData && (
                                  <>
                                    <ModeEditOutlineIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleEdit(rowData.idClient)}
                                    />
                                    <Tooltip
                                      title={
                                        rowData && rowData.encours === "oui"
                                          ? "Suppression interdite : Ce client a encore de location en cours"
                                          : ""
                                      }
                                    >
                                      <span>
                                        <DeleteIcon
                                          onClick={() => {
                                            if (
                                              rowData &&
                                              rowData.encours === "oui"
                                            ) {
                                              return; // Ne rien faire si le bouton est désactivé
                                            }

                                            handleDelete(rowData.idClient);
                                          }}
                                          style={{
                                            marginLeft: "10px",
                                            cursor:
                                              rowData && rowData.encours === "oui"
                                                ? "not-allowed"
                                                : "pointer",
                                            opacity:
                                              rowData && rowData.encours === "oui"
                                                ? 0.3
                                                : 1,
                                          }}
                                        />
                                      </span>
                                    </Tooltip>
                                  </>
                                )}
                              </span>
                            )}
                          />
                        ) : null}
                      </DataTable>
                    </div>
                  </div>
                </ClientStyle.StyledPaper>{" "}
              </div>
            )}
            <div className="flex">
              <Modal
                open={visible}
                disableBackdropClick={true}
                aria-describedby="modal-modal-description"
              >
                <AjoutClient
                  setVisible={setVisible}
                  setRescentCli={setRescentCli}
                  onClose={() => {
                    setVisible(false);
                    filtrerClient(""); // Appel de listerClient après la fermeture ,,pour que les données du table se mettent à jour
                  }}
                />
              </Modal>
              <Modal
                open={modifierClient}
                disableBackdropClick={true}
                aria-describedby="modal-modal-description"
              >
                <ModifierClient
                  idSelected={selectedClient}
                  setModifierClient={setModifierClient}
                  onClose={() => {
                    setModifierClient(false);
                    filtrerClient(""); // Appel de listerClient après la fermeture ,,pour que les données du table se mettent à jour
                  }}
                />
              </Modal>
              <Modal
                open={suprClient}
                disableBackdropClick={true}
                aria-describedby="modal-modal-description"
              >
                <SuprClient
                  setSuprClient={setSuprClient}
                  idSelected={selectedClient}
                  onClose={() => {
                    setSuprClient(false);
                    filtrerClient("");
                  }}
                />
              </Modal>
              <div className="gaucheStock">
                <div>
                  {!isMobilePortrait && (
                    <div>
                      <div className="center">
                        {/*<Button
                          variant="contained"
                          color="primary"
                          onClick={() => setVisible(true)}
                        >
                          Ajouter un client
                        </Button> */}


                      </div>{" "}
                      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%'
                      }}>
                        <h3 style={{
                          margin: 0,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: 'calc(100% - 120px)'
                        }}>{titre}</h3> {/* Enlève les marges par défaut du <h2> */}
                        <Button
                          label="Client"
                          onClick={() => setVisible(true)}
                          icon="pi pi-plus"
                          severity="info"
                          style={{
                            backgroundColor: '#146C94',
                            flexShrink: 0 // Prevents button from shrinking
                          }}
                        />
                      </div>
                      <div className=" ">
                        <DataTable
                          value={clients}
                          className="p-datatable"
                          paginator
                          rows={10}
                          rowsPerPageOptions={[5, 8, 10, 25, 50, 500]}
                          tableStyle={{ minWidth: "13rem" }}
                          rowClassName={(rowData) =>
                            `${rowData.encours === "oui" ? "enCours-bgd " : ""
                            }${rowData.idClient === rescentCli
                              ? "rescentCli custom-row-class"
                              : "custom-row-class"
                            }`
                          }
                          columnSeparator="   "
                          // paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                          paginatorTemplate={
                            "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                          }
                          paginatorRight={
                            <span
                              style={{ margin: "10px", fontWeight: "bold" }}
                            >
                              Résultats : {clients.length}
                            </span>
                          }
                        >
                          <Column
                            field="idClient"
                            header="Id"
                            style={{
                              width: "4vw",
                              maxWidth: "4vw",
                              paddingLeft: "10px",
                            }}
                          />
                          <Column
                            header="Nom"
                            style={{ width: "8vw", maxWidth: "8vw" }}
                            className="client-column-content"
                            body={(rowData) => (
                              <>
                                {rowData && (
                                  <span className="client-column-content1">
                                    {correctEncoding(rowData.nom)}
                                  </span>
                                )}
                              </>
                            )}
                          />
                          <Column
                            style={{
                              width: "8vw",
                              maxWidth: "8vw",
                              whiteSpace: "nowrap",
                            }}
                            header="Tél"
                            body={(rowData) => (
                              <span>
                                {rowData && (
                                  <>
                                    <span
                                      style={{
                                        width: "5vw",
                                        maxWidth: "5vw",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {rowData.num1}
                                    </span>
                                    <br />
                                    <span
                                      style={{
                                        width: "5vw",
                                        maxWidth: "5vw",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {" "}
                                      {rowData.num2}
                                    </span>
                                  </>
                                )}
                              </span>
                            )}
                          />
                          <Column
                            style={{
                              width: "8vw",
                              maxWidth: "8vw",
                              padding: "10px",
                              whiteSpace: "nowrap",
                            }}
                            className="client-column-content"
                            header="Mail"
                            body={(rowData) => (
                              <>
                                <>
                                  <span
                                    className="client-column-content1"
                                    style={{ paddingRight: "10px" }}
                                  >
                                    <EmailLink email={rowData.mail} name={correctEncoding(rowData.nom)} states={"1"} />
                                    <br />

                                    {rowData.mail2}
                                  </span>
                                </>
                              </>
                            )}
                          />
                          <Column
                            header="Stockage"
                            style={{
                              width: "8vw",
                              maxWidth: "8vw",
                              padding: "10px",
                              whiteSpace: "nowrap",
                            }}
                            className="client-column-content"
                            body={(rowData) =>
                              rowData.encours === "oui" ? (
                                <span
                                  className="client-column-content1"
                                  style={{
                                    width: "8vw",
                                    maxWidth: "8vw",
                                    padding: "10px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {rowData.idStockage}
                                  <br />
                                </span>
                              ) : null
                            }
                          />
                          <Column
                            field="totVolume"
                            header="Volume"
                            body={(rowData) =>
                              rowData.encours === "oui" ? (
                                <span className="client-column-content1">
                                  {rowData && (
                                    <span>{rowData.totVolume} m³</span>
                                  )}
                                  <br />
                                </span>
                              ) : null
                            }
                          />
                          <Column
                            style={{ width: "20%" }}
                            field="loyer"
                            header="Loyer"
                            body={(rowData) =>
                              rowData.encours === "oui" ? (
                                <span className="client-column-content1">
                                  {rowData && <span>{rowData.loyer} €</span>}
                                  <br />
                                </span>
                              ) : null
                            }
                          />{" "}
                          <Column
                            style={{ width: "30%" }}
                            header="Situation"
                            body={(rowData) =>
                              rowData.encours === "oui" ? (
                                <span className="client-column-content1">
                                  {rowData && (
                                    <span>
                                      {rowData.dateFin === "Sans paiement" ? (
                                        <>
                                          Aucun Paiement
                                          <Tooltip
                                            title={renderTooltipSansP(rowData)}
                                            arrow
                                          >
                                            <i
                                              className="pi pi-circle-fill"
                                              style={{
                                                fontSize: "10px",
                                                padding: "10px",
                                                cursor: "pointer",
                                                color: "#e4b61a",
                                              }}
                                            ></i>
                                          </Tooltip>
                                        </>
                                      ) : Number(rowData.differenceJours) >=
                                        0 ? (
                                        <>
                                          {rowData.differenceJours > 30 ? (
                                            <>
                                              {rowData.differenceJours > 30
                                                ? `${Math.ceil(
                                                  rowData.differenceJours / 30
                                                )} mois à payer`
                                                : `${rowData.differenceJours} jours à payer`}
                                              <Tooltip
                                                title={renderTooltip(rowData)}
                                                arrow
                                              >
                                                <i
                                                  className="pi pi-circle-fill"
                                                  style={{
                                                    fontSize: "10px",
                                                    padding: "10px",
                                                    cursor: "pointer",
                                                    color:
                                                      rowData.differenceJours <
                                                        45
                                                        ? "#e2711d"
                                                        : "#da2c38",
                                                  }}
                                                ></i>
                                              </Tooltip>
                                            </>
                                          ) : (
                                            <>
                                              {`${rowData.differenceJours} jours à
                                        payer`}
                                              <Tooltip
                                                title={renderTooltip(rowData)}
                                                arrow
                                              >
                                                <i
                                                  className="pi pi-circle-fill"
                                                  style={{
                                                    fontSize: "10px",
                                                    padding: "10px",
                                                    cursor: "pointer",
                                                    color: "#e2711d",
                                                  }}
                                                ></i>
                                              </Tooltip>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          À jour
                                          <Tooltip
                                            title={renderTooltipAjour(rowData)}
                                            arrow
                                          >
                                            <i
                                              className="pi pi-circle-fill"
                                              style={{
                                                fontSize: "10px",
                                                padding: "10px",
                                                cursor: "pointer",
                                                color: "#a1c181",
                                              }}
                                            ></i>
                                          </Tooltip>
                                        </>
                                      )}
                                    </span>
                                  )}
                                  <br />
                                </span>
                              ) : null
                            }
                          />
                          {localStorage.getItem("pr") === "sad" ||
                            localStorage.getItem("pr") === "st" ||
                            localStorage.getItem("pr") === "ad" ? (
                            <Column
                              header="Actions"
                              style={{ width: "10vw" }}
                              body={(rowData) => (
                                <>
                                  {rowData && (
                                    <>
                                      <ModeEditOutlineIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleEdit(rowData.idClient)
                                        }
                                      />
                                      <Tooltip
                                        title={
                                          rowData && rowData.encours === "oui"
                                            ? "Suppression interdite : Ce client a encore de location en cours"
                                            : ""
                                        }
                                      >
                                        <span>
                                          <DeleteIcon
                                            onClick={() => {
                                              if (
                                                rowData &&
                                                rowData.encours === "oui"
                                              ) {
                                                return; // Ne rien faire si le bouton est désactivé
                                              }

                                              handleDelete(rowData.idClient);
                                            }}
                                            style={{
                                              marginLeft: "10px",
                                              cursor:
                                                rowData &&
                                                  rowData.encours === "oui"
                                                  ? "not-allowed"
                                                  : "pointer",
                                              opacity:
                                                rowData &&
                                                  rowData.encours === "oui"
                                                  ? 0.3
                                                  : 1,
                                            }}
                                          />
                                        </span>
                                      </Tooltip>
                                    </>
                                  )}
                                </>
                              )}
                            />
                          ) : null}
                        </DataTable>
                      </div>

                    </div>
                  )}
                </div>
              </div>
              {!isMobilePortrait && (
                <div className="droiteStock">
                  <FiltreClient
                    valueFiltre={valueFiltre}
                    handleChange={handleChange}
                    recherche={recherche}
                    filtrerClient={filtrerClient}
                    searchTextFieldRef={searchTextFieldRef}
                  />
                </div>
              )}
            </div>
          </div>{" "}
        </Grid>
      </div>
    );
  };

  //***********************************FIN TABLEAU TABLEAU TABLEAU******************************* */

  // listerClient();

  return <div>{renderClientList()} </div>;
};
