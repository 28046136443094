import React, { useRef, useEffect, useState } from "react";
import Chart from "chart.js/auto";
import axios from "axios";


const apiUrl = process.env.REACT_APP_API_URL;

export const DoughnutChart = () => {
  const chartRef = useRef(null);
  const [totalLocation,setTotalLocation] =  useState(0);
  const [retardLocation,setRetardLocation] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}IHM/api/dashboard.php?loc=enCours`);
        setTotalLocation(response.data.tt); 
        console.log("total des locations :", response.data.tt);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchData();
  }, [apiUrl]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}IHM/api/dashboard.php?loc=retard`);
        setRetardLocation(response.data.retard);
        console.log("total des retards :", response.data.retard);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchData();
  }, [apiUrl]);


const retard = ((retardLocation / totalLocation) * 100).toFixed(0);

const reglo = (((totalLocation - retardLocation) / totalLocation) * 100).toFixed(0);

  useEffect(() => {

    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      const data = {
        labels: [ `En retard:${retard}% (${retardLocation}) `,`En règle: ${reglo}% (${totalLocation - retardLocation}) `,],
        datasets: [
          {
            data: [retard,reglo],
            backgroundColor: ["#FEC868", "#7EAA92"], 
            borderColor: ["#feab1b", "#517b64"],
            borderWidth: 1,
          },
        ],
      };

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 70,
      };

      const doughnutChart = new Chart(ctx, {
        type: "doughnut",
        data: data,
        options: options,
      });

      return () => {
        doughnutChart.destroy(); 
      };
    }
  }, [totalLocation,retardLocation]);


  return (
    <div style={{ marginTop: "5%" }} className="canvas-container">
      <canvas ref={chartRef} />
    </div>
  );
};
