import React from "react";
import "../ClientStyle/ajoutClient.css";
import axios from "axios";
import { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  TextField,
  TextareaAutosize,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
export const ModifierClient = ({ setModifierClient, idSelected, onClose }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [clientsData, setClientsData] = useState([]);
  const manahirana = idSelected;
  useEffect(() => {
    // Vous pouvez placer votre requête ici pour la récupération des données
    axios
      .get(`${apiUrl}IHM/api/?id=${idSelected}`)
      .then((response) => {
        const clientsData = response.data;
        setClientsData(clientsData);
        setNom(clientsData[0].nom);
        setPrenom(clientsData[0].prenom);
        setAdresse(clientsData[0].adresse);
        setCodePostal(clientsData[0].codePostal);
        setVille(clientsData[0].ville);
        setNum1(clientsData[0].num1);
        setNum2(clientsData[0].num2);
        setMail(clientsData[0].mail);
        setMail2(clientsData[0].mail2);
        setNote(clientsData[0].note);

        // console.log(clientsData[0].nom);
        //console.log("oo", { clientsData });
      })
      .catch((error) => {
        console.error("error");
      });
  }, [idSelected]); // Exécutez cette requête lorsque idSelected change

  const [idClient, setIdClient] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [adresse, setAdresse] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [ville, setVille] = useState("");
  const [num1, setNum1] = useState("");
  const [num2, setNum2] = useState("");
  const [mail, setMail] = useState("");
  const [mail2, setMail2] = useState("");
  const [note, setNote] = useState("");

  const faireModification = async (e) => {
    e.preventDefault();
    // Vérifier si les champs nom et prenom ne sont pas vides
    if (!prenom && !nom) {
      // Afficher un message d'erreur ou prendre d'autres mesures appropriées

      return; // Arrêter la soumission du formulaire
    } else {
      const data = {
        idClient: manahirana,
        prenom: prenom,
        nom: nom,
        adresse: adresse,
        codePostal: codePostal,
        ville: ville,
        num1: num1,
        num2: num2,
        mail: mail,
        mail2: mail2,
        note: note,
      };
      setIsSubmitting(true);
      try {
        await axios
          .post(`${apiUrl}IHM/api/`, data)
          .then((response) => {
            console.log(response.data);
            console.log("VAOVAO");
            console.log(manahirana);
            console.log("FARANY");
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log("AIZAEEE error");
      }

      setNom("");
      setAdresse("");
      setCodePostal("");
      setNum1("");
      setVille("");
      setNum2("");
      setMail("");
      setMail2("");
      setNote("");
      setPrenom("");
      setModifierClient(false);
    }
    if (typeof onClose === "function") {
      onClose(); // Appel de la fonction de rappel , manao mise à jour atsy
      console.log("OnClose");
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      faireModification(e);
      setModifierClient(false);
    }
  };
  return (
    <div className=" ">
      <div className="modalContainer">
        <div className="flex items-center">
          <span className="ml-auto">
            {" "}
            <CancelIcon
              style={{ cursor: "pointer" }}
              sx={{ fontSize: 40 }}
              className="   mt-minus-52"
              onClick={() => {
                setModifierClient(false);
                if (typeof onClose === "function") {
                  onClose(); // Appel de la fonction de rappel
                  console.log("OnClose");
                }
              }}
            />
          </span>
        </div>
        <span className="titreEtBtn">
          <div className="w-5/6 mx-auto flex items-center justify-center">
            <h4 className="title font-bold text-lg md:text-2xl lg:text-2xl xl:text-2xl">
              Modifier un client
            </h4>
          </div>
        </span>

        <div className="container">
          <form className="form" onSubmit={faireModification}>
            {clientsData.map((client, index) => (
              <div key={index}>
                <TextField
                  style={{ width: "110%" }}
                  id="standard-search"
                  label="Nom"
                  type="text"
                  variant="standard"
                  value={nom}
                  onChange={(e) => setNom(e.target.value)}
                  required
                />
                <br />
                <br />
                <TextField
                  id="standard-search"
                  label="Adresse"
                  type="text"
                  variant="standard"
                  value={adresse}
                  onChange={(e) => setAdresse(e.target.value)}
                  style={{ width: "110%" }}
                />
                <br />
                <br />
                <TextField
                  id="standard-search"
                  label="Code Postale"
                  type="text"
                  variant="standard"
                  value={codePostal}
                  onChange={(e) => setCodePostal(e.target.value)}
                  style={{ width: "110%" }}
                />
                <br />
                <br />
                <TextField
                  id="standard-search"
                  label="Ville"
                  type="text"
                  variant="standard"
                  value={ville}
                  onChange={(e) => setVille(e.target.value)}
                  style={{ width: "110%" }}
                />
                <br />
                <br />
                <TextField
                  id="standard-search"
                  label="Numéro tel1"
                  type="text"
                  variant="standard"
                  value={num1}
                  onChange={(e) => setNum1(e.target.value)}
                  style={{ width: "110%" }}
                />
                <br />
                <br />
                <TextField
                  id="standard-search"
                  label="Numéro tel2"
                  type="text"
                  variant="standard"
                  value={num2}
                  onChange={(e) => setNum2(e.target.value)}
                  style={{ width: "110%" }}
                />
                <br />
                <br />
                <TextField
                  id="standard-search"
                  label="Adresse mail"
                  type="mail"
                  variant="standard"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  style={{ width: "110%" }}
                />
                <br />
                <br />{" "}
                <TextField
                  id="standard-search"
                  label="Autre adresse mail"
                  type="mail"
                  variant="standard"
                  value={mail2}
                  onChange={(e) => setMail2(e.target.value)}
                  style={{ width: "110%" }}
                />
                <br />
                <br />
                <FormControl variant="standard" style={{ width: "110%" }}>
                  <InputLabel>Note</InputLabel>

                  <TextareaAutosize
                    variant="standard"
                    minRows={3} // Définissez le nombre minimum de lignes
                    maxRows={5} // Définissez le nombre maximum de lignes
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    style={{ width: "110%" }}
                  />
                </FormControl>
                <br />
                <Button
                  className="myBouton"
                  variant="contained"
                  type="submit"
                  sx={{ marginLeft: "40%" }}
                  disabled={isSubmitting}
                >
                  Modifier
                </Button>
              </div>
            ))}
          </form>
        </div>
      </div>
    </div>
  );
};
