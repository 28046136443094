import React, { useEffect, useState } from "react";
import axios from "axios";
import { Panel } from 'primereact/panel';
import "./stockageCP.css";

const apiUrl = process.env.REACT_APP_API_URL;

export const CP = ({ setSearch, setMotRecherche, motRechercheClient, setMotRechercheClient }) => {
    const [triee, setTrie] = useState([]);

    const P = [];
    for (let i = 0; i < 20; i++) {
        P[i] = `P${i}`;
    }

    const G = [];
    for (let j = 0; j < 100; j++) {
        G[j] = `G${j}`;
    }

    useEffect(() => {
        axios.get(`${apiUrl}IHM/api/stockage.php`).then((response) => {
            const listeOccupe = response.data.map((item) => item.idStockage);
            setTrie(listeOccupe);
            console.log(triee);
        });
    }, [apiUrl]);

    const dispo = (valeur) => {
        return triee.includes(valeur) ? "dispo" : "nodispo";
    };

    const handleConteneurClick = (param) => {
        setMotRecherche(param);
        setSearch(true);
        if (param === "") {
            // Si le champ de recherche est vide, réinitialisez les styles et sortez de la fonction
            const divs = document.querySelectorAll(".stock");
            divs.forEach((div) => {
                div.classList.remove("highlight-yellow");
            });
            return;
        }

        const regex = new RegExp(`\\b${param}\\b`, "i");
        const divs = document.querySelectorAll(".stock");
        divs.forEach((div) => {
            const texteDiv = div.textContent;
            //  recherche
            if (regex.test(texteDiv)) {
                // Si le texte de la div correspond à la recherche, appliquez le style
                div.classList.add("highlight-yellow");
                setTimeout(() => {
                    div.classList.remove("highlight-yellow");
                }, 900);

                // Calcul de la position et défilement de la page
                const windowHeight = window.innerHeight;
                const divTopOffset = div.getBoundingClientRect().top;
                const scrollPosition = divTopOffset - windowHeight / 2;

                window.scrollBy({
                    top: scrollPosition,
                    behavior: "smooth",
                });
            } else {
                // Si le texte de la div ne correspond pas à la recherche, supprimez le style
                div.classList.remove("highlight-yellow");
            }
        });
    };

    return (
        <>
            <div className="building">
           <h1><strong>REZ DE CHAUSSÉE</strong></h1> 
                <pre className="line">
                    {"  "}
                    {"\n"}
                    {"                    "}┌──┐{"  "}┌──┐{"  "}┌──┐{"  "}┌──┐{"  "}┌──┐{"\n"}
                    {"                    "}│
                    <a
                        className={`stock Rbox46 
        ${dispo("46")}`}
                        onClick={() => {
                            handleConteneurClick("46");
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        46
                    </a>
                    │{"  "}│<a className={`stock Rbox3 ${dispo("89")}`}
                        onClick={() => {
                            handleConteneurClick("89");
                        }}
                        style={{ cursor: "pointer" }}
                    >89</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox ${dispo("38")}`}
                        onClick={() => {
                            handleConteneurClick("38");
                        }}
                        style={{ cursor: "pointer" }}>38</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox2 ${dispo("78")}`}
                        onClick={() => {
                            handleConteneurClick("78");
                        }}
                        style={{ cursor: "pointer" }}>78</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox ${dispo("95")}`}
                        onClick={() => {
                            handleConteneurClick("95");
                        }}
                        style={{ cursor: "pointer" }}>95</a>
                    <a>
                        │ {"\n"}
                        {"                    "}├──┤{"  "}├──┼──┼──┼──┼──┼──┼──┼──┐ {"\n"}
                        {"                    "}│
                    </a>
                    <a className={`stock Rbox8 ${dispo("47")}`}
                        onClick={() => {
                            handleConteneurClick("47");
                        }}
                        style={{ cursor: "pointer" }}>47</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox3 ${dispo("88")}`}
                        onClick={() => {
                            handleConteneurClick("88");
                        }}
                        style={{ cursor: "pointer" }}>88</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("39")}`}
                        onClick={() => {
                            handleConteneurClick("39");
                        }}
                        style={{ cursor: "pointer" }}>39</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox ${dispo("37")}`}
                        onClick={() => {
                            handleConteneurClick("37");
                        }}
                        style={{ cursor: "pointer" }}>37</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox ${dispo("77")}`}
                        onClick={() => {
                            handleConteneurClick("77");
                        }}
                        style={{ cursor: "pointer" }}>77</a>
                    <a>│{"  "}│</a>
                    <a className={`stock boxL ${dispo("94")}`}
                        onClick={() => {
                            handleConteneurClick("94");
                        }}
                        style={{ cursor: "pointer" }}>94</a>
                    <a>
                        │ {"\n"}
                        {"        "}┌──┬──┬──┬──┼──┤{"  "}└──┼──┤{"  "}├──┤{"  "}├──┤{"  "}└──┘
                        {"  "}
                        {"\n"}
                        {"        "}│
                    </a>
                    <a className={`stock Rbox ${dispo("52")}`}
                  onClick={() => {
                    handleConteneurClick("52");
                  }}
                  style={{ cursor: "pointer" }}>52</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("51")}`}
                  onClick={() => {
                    handleConteneurClick("51");
                  }}
                  style={{ cursor: "pointer" }}>51</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("50")}`}
                  onClick={() => {
                    handleConteneurClick("50");
                  }}
                  style={{ cursor: "pointer" }}>50</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("49")}`}
                  onClick={() => {
                    handleConteneurClick("49");
                  }}
                  style={{ cursor: "pointer" }}>49</a>
                    <a>│</a>
                    <a  className={`stock Rbox ${dispo("48")}`}
                  onClick={() => {
                    handleConteneurClick("48");
                  }}
                  style={{ cursor: "pointer" }}>48</a>
                    <a>│{"     "}│</a>
                    <a className={`stock Rbox ${dispo("40")}`}
                        onClick={() => {
                            handleConteneurClick("40");
                        }}
                        style={{ cursor: "pointer" }}>40</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox ${dispo("36")}`}
                        onClick={() => {
                            handleConteneurClick("36");
                        }}
                        style={{ cursor: "pointer" }}>36</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox ${dispo("76")}`}
                        onClick={() => {
                            handleConteneurClick("76");
                        }}
                        style={{ cursor: "pointer" }}>76</a>
                    <a>
                        │{"  "}
                        {"\n"}
                        {"        "}└──┴──┴──┴──┴──┘{"     "}└──┘{"  "}└──┘{"  "}└──┘{"\n"}
                        {"\n"}
                        {"        "}┌──┬──┬──┬──┬──┬──┐{"  "}┌──┐{"  "}┌──┬──┬──┐{"\n"}
                        {"        "}│
                    </a>
                    <a className={`stock Rbox ${dispo("65")}`}
                        onClick={() => {
                            handleConteneurClick("65");
                        }}
                        style={{ cursor: "pointer" }}>65</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("66")}`}
                        onClick={() => {
                            handleConteneurClick("66");
                        }}
                        style={{ cursor: "pointer" }}>66</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("67")}`}
                        onClick={() => {
                            handleConteneurClick("67");
                        }}
                        style={{ cursor: "pointer" }}>67</a>
                    <a>│</a>
                    <a className={`stock box97 ${dispo("97")}`}
                        onClick={() => {
                            handleConteneurClick("97");
                        }}
                        style={{ cursor: "pointer" }}>97</a>
                    <a>│</a>
                    <a className={`stock boxL ${dispo("96")}`}
                        onClick={() => {
                            handleConteneurClick("96");
                        }}
                        style={{ cursor: "pointer" }}>96</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("41")}`}
                        onClick={() => {
                            handleConteneurClick("41");
                        }}
                        style={{ cursor: "pointer" }}
                    >41</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox ${dispo("35")}`}
                        onClick={() => {
                            handleConteneurClick("35");
                        }}
                        style={{ cursor: "pointer" }}>35</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox ${dispo("32")}`}
                        onClick={() => {
                            handleConteneurClick("32");
                        }}
                        style={{ cursor: "pointer" }}>32</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("92")}`}
                        onClick={() => {
                            handleConteneurClick("92");
                        }}
                        style={{ cursor: "pointer" }}>92</a>
                    <a>│</a>
                    <a className={`stock  Rbox ${dispo("93")}`}
                        onClick={() => {
                            handleConteneurClick("93");
                        }}
                        style={{ cursor: "pointer" }}>93</a>
                    <a>
                        │{"  "}
                        {"\n"}
                        {"        "}└──┴──┴──┴──┴──┼──┤{"  "}├──┤{"  "}├──┼──┴──┘{"\n"}
                        {"                       "}│
                    </a>
                    <a className={`stock Rbox ${dispo("42")}`}
                        onClick={() => {
                            handleConteneurClick("42");
                        }}
                        style={{ cursor: "pointer" }}>42</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox ${dispo("34")}`}
                        onClick={() => {
                            handleConteneurClick("34");
                        }}
                        style={{ cursor: "pointer" }}>34</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox ${dispo("31")}`}
                        onClick={() => {
                            handleConteneurClick("31");
                        }}
                        style={{ cursor: "pointer" }}>31</a>
                    <a>
                        │ {"\n"}
                        {"        "}┌──┬──┬──┬──┬──┼──┤{"  "}├──┤{"  "}├──┼──┐ {"\n"}
                        {"        "}│
                    </a>
                    <a className={`stock Rbox  ${dispo("75")}`}
                        onClick={() => {
                            handleConteneurClick("75");
                        }}
                        style={{ cursor: "pointer" }}>75</a>
                    <a>│</a>
                    <a className={`stock Rbox  ${dispo("71")}`}
                        onClick={() => {
                            handleConteneurClick("71");
                        }}
                        style={{ cursor: "pointer" }}>71</a>
                    <a>│</a>
                    <a className={`stock Rbox  ${dispo("70")}`}
                        onClick={() => {
                            handleConteneurClick("70");
                        }}
                        style={{ cursor: "pointer" }}>70</a>
                    <a>│</a>
                    <a className={`stock Rbox  ${dispo("69")}`}
                        onClick={() => {
                            handleConteneurClick("69");
                        }}
                        style={{ cursor: "pointer" }}>69</a>
                    <a>│</a>
                    <a className={`stock box68 ${dispo("68")}`}
                        onClick={() => {
                            handleConteneurClick("68");
                        }}
                        style={{ cursor: "pointer" }}>68</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("43")}`}
                        onClick={() => {
                            handleConteneurClick("43");
                        }}
                        style={{ cursor: "pointer" }}>43</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox ${dispo("33")}`}
                        onClick={() => {
                            handleConteneurClick("33");
                        }}
                        style={{ cursor: "pointer" }}>33</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox ${dispo("30")}`}
                        onClick={() => {
                            handleConteneurClick("30");
                        }}
                        style={{ cursor: "pointer" }}>30</a>
                    <a>│</a>
                    <a className={`stock moyenBox ${dispo("29")}`}
                        onClick={() => {
                            handleConteneurClick("29");
                        }}
                        style={{ cursor: "pointer" }}>29</a>
                    <a>
                        │{"\n"}
                        {"     "}┌──┼──┴──┴──┴──┴──┴──┴──┴──┘{"  "}└──┴──┘{"\n"}
                        {"     "}│
                    </a>
                    <a className={`stock Rbox7  ${dispo("79")}`}
                        onClick={() => {
                            handleConteneurClick("79");
                        }}
                        style={{ cursor: "pointer" }}>79</a>
                    <a>
                        │{"\n"}
                        {"     "}└──┼──┬──┬──┬──┬──┬──┬──┐{"  "}┌──┬──┐{"\n"}
                        {"        "}│
                    </a>
                    <a className={`stock Rbox7  ${dispo("7")}`}
                        onClick={() => {
                            handleConteneurClick("7");
                        }}
                        style={{ cursor: "pointer" }}> 7</a>
                    <a>│</a>
                    <a className={`stock Rbox7  ${dispo("6")}`}
                        onClick={() => {
                            handleConteneurClick("6");
                        }}
                        style={{ cursor: "pointer" }}> 6</a>
                    <a>│</a>
                    <a className={`stock Rbox7  ${dispo("5")}`}
                        onClick={() => {
                            handleConteneurClick("5");
                        }}
                        style={{ cursor: "pointer" }}> 5</a>
                    <a>│</a>
                    <a className={`stock Rbox7  ${dispo("4")}`}
                        onClick={() => {
                            handleConteneurClick("4");
                        }}
                        style={{ cursor: "pointer" }}> 4</a>
                    <a>│</a>
                    <a className={`stock Rbox7  ${dispo("3")}`}
                        onClick={() => {
                            handleConteneurClick("3");
                        }}
                        style={{ cursor: "pointer" }}> 3</a>
                    <a>│</a>
                    <a className={`stock Rbox7  ${dispo("2")}`}
                        onClick={() => {
                            handleConteneurClick("2");
                        }}
                        style={{ cursor: "pointer" }}> 2</a>
                    <a>│</a>
                    <a className={`stock Rbox7  ${dispo("1")}`}
                        onClick={() => {
                            handleConteneurClick("1");
                        }}
                        style={{ cursor: "pointer" }}> 1</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox  ${dispo("28")}`}
                        onClick={() => {
                            handleConteneurClick("28");
                        }}
                        style={{ cursor: "pointer" }}>28</a>
                    <a>│</a>
                    <a className={`stock Rbox  ${dispo("27")}`}
                        onClick={() => {
                            handleConteneurClick("27");
                        }}
                        style={{ cursor: "pointer" }}>27</a>
                    <a>
                        │{"   "}
                        {"\n"}
                        {"     "}┌──┼──┴──┴──┴──┴──┴──┴──┘{"  "}└──┴──┘ {"\n"}
                        {"     "}│
                    </a>
                    <a className={`stock Rbox ${dispo("8")}`}
                        onClick={() => {
                            handleConteneurClick("8");
                        }}
                        style={{ cursor: "pointer" }}> 8</a>
                    <a>
                        │{"\n"}
                        {"     "}└──┴──┬──┬──┬──┬──┬──┬──┐{"  "}┌──┐{"\n"}
                        {"           "}│
                    </a>
                    <a className={`stock Rbox  ${dispo("9")}`}
                        onClick={() => {
                            handleConteneurClick("9");
                        }}
                        style={{ cursor: "pointer" }}> 9</a>
                    <a>│</a>
                    <a className={`stock Rbox  ${dispo("10")}`}
                        onClick={() => {
                            handleConteneurClick("10");
                        }}
                        style={{ cursor: "pointer" }}>10</a>
                    <a>│</a>
                    <a className={`stock Rbox  ${dispo("11")}`} onClick={() => {
                        handleConteneurClick("11");
                    }} style={{ cursor: "pointer" }}>11</a>
                    <a>│</a>
                    <a className={`stock Rbox  ${dispo("12")}`}
                        onClick={() => {
                            handleConteneurClick("12");
                        }}
                        style={{ cursor: "pointer" }}>12</a>
                    <a>│</a>
                    <a className={`stock Rbox  ${dispo("13")}`}
                        onClick={() => {
                            handleConteneurClick("13");
                        }}
                        style={{ cursor: "pointer" }}>13</a>
                    <a>│</a>
                    <a className={`stock Rbox  ${dispo("14")}`}
                        onClick={() => {
                            handleConteneurClick("14");
                        }}
                        style={{ cursor: "pointer" }}>14</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox2  ${dispo("26")}`}
                        onClick={() => {
                            handleConteneurClick("26");
                        }}
                        style={{ cursor: "pointer" }}>26</a>
                    <a>
                        │{"\n"}
                        {"           "}└──┴──┴──┴──┴──┴──┘{"  "}└──┘{"\n"}
                        {"    "}
                        {"\n"}
                        {"    "}
                        {"\t"}
                        {"\t"}
                    </a>
                </pre>
                <a></a>
            </div>
            <a></a>
            <div className="building">
                <a>
                    <h1><strong>ÉTAGE</strong></h1>
                </a>
                <pre className="line">
                    <a>
                        {"  "}
                        {"\n"}
                        {"                 "}┌──┬──┐{"  "}┌──┐{"  "}┌──┐{"  "}┌──┐{"  "}┌──┐
                        {"\n"}
                        {"                 "}│
                    </a>
                    <a className={` stock box53 ${dispo("53")}`}
                        onClick={() => {
                            handleConteneurClick("53");
                        }}
                        style={{ cursor: "pointer" }}>53</a>
                    <a>│</a>
                    <a className={`stock box54 ${dispo("54")}`}
                        onClick={() => {
                            handleConteneurClick("54");
                        }}
                        style={{ cursor: "pointer" }}>54</a>
                    <a>│{"  "}│</a>
                    <a className={`stock box90 ${dispo("90")}`}
                        onClick={() => {
                            handleConteneurClick("90");
                        }}
                        style={{ cursor: "pointer" }}>90</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox2 ${dispo("85")}`}
                        onClick={() => {
                            handleConteneurClick("85");
                        }}
                        style={{ cursor: "pointer" }}>85</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox2 ${dispo("82")}`}
                        onClick={() => {
                            handleConteneurClick("82");
                        }}
                        style={{ cursor: "pointer" }}>82</a>
                    <a>│{"  "}│</a>
                    <a className={`stock Rbox2 ${dispo("98")}`}
                        onClick={() => {
                            handleConteneurClick("98");
                        }}
                        style={{ cursor: "pointer" }}>98</a>
                    <a>
                        │ {"\n"}
                        {"                 "}└──┼──┤{"  "}├──┼──┼──┼──┼──┼──┼──┼──┐ {"\n"}
                        {"                    "}│
                    </a>
                    <a className={`stock box54 ${dispo("55")}`}
                        onClick={() => {
                            handleConteneurClick("55");
                        }}
                        style={{ cursor: "pointer" }}>55</a>
                    <a>│{"  "}│</a>
                    <a className={`stock box90 ${dispo("91")}`}
                        onClick={() => {
                            handleConteneurClick("91");
                        }}
                        style={{ cursor: "pointer" }}>91</a>
                    <a>│</a>
                    <a className={`stock box54 ${dispo("86")}`}
                        onClick={() => {
                            handleConteneurClick("86");
                        }}
                        style={{ cursor: "pointer" }}>86</a>
                    <a>│{"  "}│</a>
                    <a className={`stock box54 ${dispo("84")}`}
                        onClick={() => {
                            handleConteneurClick("84");
                        }}
                        style={{ cursor: "pointer" }}>84</a>
                    <a>│{"  "}│</a>
                    <a className={`stock box54 ${dispo("81")}`}
                        onClick={() => {
                            handleConteneurClick("81");
                        }}
                        style={{ cursor: "pointer" }}>81</a>
                    <a>│{"  "}│</a>
                    <a style={{ cursor: "pointer" }} className={`stock carton ${dispo("99")}`} onClick={() => {
                        handleConteneurClick("99");
                    }}>99</a>
                    <a>
                        │ {"\n"}┌───┬───┬──┬──┬──┬──┼──┤{"  "}└──┼──┤{"  "}├──┤{"  "}├──┤{"  "}
                        └──┘{"  "}
                        {"\n"}│
                    </a>
                    <a className={` stock Rbox  ${dispo("61B")}`}
                        onClick={() => {
                            handleConteneurClick("61B");
                        }}
                        style={{ cursor: "pointer" }}>61B</a>
                    <a>│</a>
                    <a className={` stock Rbox  ${dispo("61A")}`}
                        onClick={() => {
                            handleConteneurClick("61A");
                        }}
                        style={{ cursor: "pointer" }}>61A</a>
                    <a>│</a>
                    <a className={`stock Rbox  ${dispo("60")}`}
                        onClick={() => {
                            handleConteneurClick("60");
                        }}
                        style={{ cursor: "pointer" }}>60</a>
                    <a>│</a>
                    <a className={` stock Rbox  ${dispo("59")}`}
                        onClick={() => {
                            handleConteneurClick("59");
                        }}
                        style={{ cursor: "pointer" }}>59</a>
                    <a>│</a>
                    <a className={` stock Rbox  ${dispo("58")}`}
                        onClick={() => {
                            handleConteneurClick("58");
                        }}
                        style={{ cursor: "pointer" }}>58</a>
                    <a>│</a>
                    <a className={`stock box57 ${dispo("57")}`}
                        onClick={() => {
                            handleConteneurClick("57");
                        }}
                        style={{ cursor: "pointer" }}>57</a>
                    <a>│</a>
                    <a className={`stock box54 ${dispo("56")}`}
                        onClick={() => {
                            handleConteneurClick("56");
                        }}
                        style={{ cursor: "pointer" }}>56</a>
                    <a>│{"     "}│</a>
                    <a className={`stock box54 ${dispo("87")}`}
                        onClick={() => {
                            handleConteneurClick("87");
                        }}
                        style={{ cursor: "pointer" }}>87</a>
                    <a>│{"  "}│</a>
                    <a className={`stock box54 ${dispo("83")}`}
                        onClick={() => {
                            handleConteneurClick("83");
                        }}
                        style={{ cursor: "pointer" }}>83</a>
                    <a>│{"  "}│</a>
                    <a className={`stock box54 ${dispo("80")}`}
                        onClick={() => {
                            handleConteneurClick("80");
                        }}
                        style={{ cursor: "pointer" }}>80</a>
                    <a>
                        │{"  "}
                        {"\n"}└───┴───┴──┴──┴──┴──┴──┘{"     "}└──┘{"  "}└──┘{"  "}└──┘{"\n"}
                        {"\n"}
                        {"              "}┌──┬──┬──┐{"     "}┌──┐{"    "}
                        {"\n"}
                        {"              "}│
                    </a>
                    <a className={`stock Rbox ${dispo("64")}`}
                        onClick={() => {
                            handleConteneurClick("64");
                        }}
                        style={{ cursor: "pointer" }}>64</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("63")}`}
                        onClick={() => {
                            handleConteneurClick("63");
                        }}
                        style={{ cursor: "pointer" }}>63</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("62")}`}
                        onClick={() => {
                            handleConteneurClick("62");
                        }}
                        style={{ cursor: "pointer" }}>62</a>
                    <a>│{"     "}│</a>
                    <a className={`stock Rbox ${dispo("45")}`}
                        onClick={() => {
                            handleConteneurClick("45");
                        }}
                        style={{ cursor: "pointer" }}>45</a>
                    <a>
                        │{"\n"}
                        {"              "}└──┴──┴──┘{"     "}├──┤{"     "}
                        {"\n"}
                        {"                             "}│
                    </a>
                    <a className={`stock carton ${dispo("44")}`}
                        onClick={() => {
                            handleConteneurClick("44");
                        }}
                        style={{ cursor: "pointer" }}>44</a>
                    <a>
                        │{"\n"}
                        {"              "}┌──┬──┬──┐{"     "}└──┘{"    "}
                        {"\n"}
                        {"              "}│
                    </a>
                    <a className={`stock Rbox ${dispo("74")}`}
                        onClick={() => {
                            handleConteneurClick("74");
                        }}
                        style={{ cursor: "pointer" }}>74</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("73")}`}
                        onClick={() => {
                            handleConteneurClick("73");
                        }}
                        style={{ cursor: "pointer" }}>73</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("72")}`}
                        onClick={() => {
                            handleConteneurClick("72");
                        }}
                        style={{ cursor: "pointer" }}>72</a>
                    <a>
                        │{"   "}
                        {"\n"}
                        {"              "}└──┴──┴──┘{"     "}
                        {"\n"}
                        {"              "}
                        {"\n"}
                        {"                "}┌──┬──┬──┬──┬──┐ {"\n"}
                        {"                "}│
                    </a>
                    <a className={`stock Rbox ${dispo("19")}`}
                        onClick={() => {
                            handleConteneurClick("19");
                        }}
                        style={{ cursor: "pointer" }}>19</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("18")}`}
                        onClick={() => {
                            handleConteneurClick("18");
                        }}
                        style={{ cursor: "pointer" }}>18</a>
                    <a>│</a>
                    <a className={`stock box54 ${dispo("17")}`}
                        onClick={() => {
                            handleConteneurClick("17");
                        }}
                        style={{ cursor: "pointer" }}>17</a>
                    <a>│</a>
                    <a className={`stock box54 ${dispo("16")}`}
                        onClick={() => {
                            handleConteneurClick("16");
                        }}
                        style={{ cursor: "pointer" }}>16</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("15")}`}
                        onClick={() => {
                            handleConteneurClick("15");
                        }}
                        style={{ cursor: "pointer" }}>15</a>
                    <a>
                        │{"\n"}
                        {"    "}┌───┬───────┴──┴──┴──┴──┴──┘{"   "}
                        {"\n"}
                        {"    "}│
                    </a>
                    <a className={`stock Rbox ${dispo("100")}`}
                        onClick={() => {
                            handleConteneurClick("100");
                        }}
                        style={{ cursor: "pointer" }}>100</a>
                    <a>
                        │{"\n"}
                        {"    "}└───┼───┬───┬──┬──┬──┬──┬──┐ {"\n"}
                        {"        "}│
                    </a>
                    <a className={`stock box90 ${dispo("20D")}`}
                        onClick={() => {
                            handleConteneurClick("20D");
                        }}
                        style={{ cursor: "pointer" }}>20D</a>
                    <a>│</a>
                    <a className={`stock box90 ${dispo("20G")}`}
                        onClick={() => {
                            handleConteneurClick("20G");
                        }}
                        style={{ cursor: "pointer" }}>20G</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("21")}`}
                        onClick={() => {
                            handleConteneurClick("21");
                        }}
                        style={{ cursor: "pointer" }}>21</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("22")}`}
                        onClick={() => {
                            handleConteneurClick("22");
                        }}
                        style={{ cursor: "pointer" }}>22</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("23")}`}
                        onClick={() => {
                            handleConteneurClick("23");
                        }}
                        style={{ cursor: "pointer" }}>23</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("24")}`}
                        onClick={() => {
                            handleConteneurClick("24");
                        }}
                        style={{ cursor: "pointer" }}>24</a>
                    <a>│</a>
                    <a className={`stock Rbox ${dispo("25")}`}
                        onClick={() => {
                            handleConteneurClick("25");
                        }}
                        style={{ cursor: "pointer" }}>25</a>
                    <a>
                        │ {"\n"}
                        {"        "}└───┴───┴──┴──┴──┴──┴──┘{"  "}
                        {"\n"}
                        {"    "}
                        {"\n"}
                        {"    "}
                        {"\t"}
                        {"\t"}
                    </a>
                </pre>
                <a></a>
            </div>
            <a></a>
            <div className="building">
                <h1>CONTAINERS</h1>
                <pre className="line container">
                    {"  "}
                    {"\n"}
                    {"    "}
                    {"\n"}
                    {"     "}┌────┐┌────┐┌────┐┌────┐{"            "}
                    ┌────┐┌────┐┌────┐┌────┐┌────┐┌────┐┌────┐┌────┐{"\n"}
                    {"     "}│{"    "}││{"    "}││{"    "}││{"    "}│{"            "}│{"    "}││
                    {"    "}││{"    "}││{"    "}││{"    "}││{"    "}││{"    "}││{"    "}│{"\n"}
                    {"     "}│{"    "}││{"    "}││{"    "}││{"    "}│{"            "}│{"    "}││
                    {"    "}││{"    "}││{"    "}││{"    "}││{"    "}││{"    "}││{"    "}│ {"\n"}
                    {"     "}│ <a className={` stock contenneurP ${dispo(P[0])}`}
                        onClick={() => {
                            handleConteneurClick(P[0]);
                        }}
                        style={{ cursor: "pointer" }}>P0</a> ││{" "}
                    <a className={` stock contenneurP ${dispo(P[1])}`}
                        onClick={() => {
                            handleConteneurClick(P[1]);
                        }}
                        style={{ cursor: "pointer" }}>P1</a> ││ <a  className={`stock contenneurG ${dispo(G[1])}`}
                        onClick={() => {
                          handleConteneurClick(G[1]);
                        }}style={{ cursor: "pointer" }}>G1</a> ││{" "}
                    <a className={`stock contenneurG ${dispo(G[2])}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            handleConteneurClick(G[2]);
                        }}>G2</a> │{"            "}│{"    "}││{"    "}││
                    {"    "}││{"    "}││{"    "}││{"    "}││{"    "}││{"    "}│{"\n"}
                    {"     "}│{"    "}││{"    "}││{"    "}││{"    "}│{"            "}│{"    "}││
                    {"    "}││ <a className={`stock contenneurP ${dispo(P[3])}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            handleConteneurClick(P[3]);
                        }}>P3</a> ││
                    <a className={`stock contenneurP ${dispo(P[13])}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            handleConteneurClick(P[13]);
                        }}>P13</a> ││ <a className={`stock contenneurP ${dispo(P[7])}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                handleConteneurClick(P[7]);
                            }}>P7</a> ││{" "}
                    <a className={`stock contenneurP ${dispo(P[8])}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            handleConteneurClick(P[8]);
                        }}>P8</a> ││ <a className={`stock contenneurP ${dispo(P[9])}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                handleConteneurClick(P[9]);
                            }}>P9</a> ││
                    <a className={`stock contenneurP ${dispo(P[10])}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            handleConteneurClick(P[10]);
                        }}>P10</a> │{"\n"}
                    {"     "}│{"    "}││{"    "}││{"    "}││{"    "}│{"            "}│{"    "}││
                    {"    "}││{"    "}││{"    "}││{"    "}││{"    "}││{"    "}││{"    "}│{"\n"}
                    {"     "}└────┘└────┘│{"    "}││{"    "}│{"            "}│{"    "}││{"    "}
                    │└────┘└────┘└────┘└────┘└────┘└────┘{"\n"}
                    {"           "}┌────┐│{"    "}││{"    "}│{"      "}┌────┐│{" "}
                    <a style={{
                        cursor: "pointer",
                    }}
                        className={`stock contenneurG ${dispo(G[4])}`}
                        onClick={() => {
                            handleConteneurClick(G[4]);
                        }}>G4</a> ││ <a style={{
                            cursor: "pointer",
                        }}
                            className={`stock contenneurG ${dispo(G[3])}`}
                            onClick={() => {
                                handleConteneurClick(G[3]);
                            }}>G3</a> │{"\n"}
                    {"           "}│{"    "}││{"    "}││{"    "}│{"      "}│{"    "}││{"    "}││
                    {"    "}│┌────┐{"\n"}
                    {"           "}│{"    "}││{"    "}││{"    "}│{"      "}│{" "}
                    <a className={` stock contenneurP ${dispo(P[14])}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            handleConteneurClick(P[14]);
                        }}>P14</a>││{"    "}││{"    "}│|{"    "}| {"\n"}
                    {"           "}│ <a  className={` stock contenneurP ${dispo(P[2])}`}
                style={{cursor: "pointer" }}
                onClick={() => {
                  handleConteneurClick(P[2]);
                }}>P2</a> ││{"    "}││{"    "}│
                    {"      "}│{"    "}││{"    "}││{"    "}│| <a className={`stock contenneurP ${dispo(P[4])}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            handleConteneurClick(P[4]);
                        }}>P4</a> |
                    {"  "}
                    {"\n"}
                    {"           "}│{"    "}││{"    "}││{"    "}│{"      "}│{"    "}││{"    "}││
                    {"    "}││{"    "}│{"\n"}
                    {"           "}│{"    "}││{"    "}││{"    "}│{"      "}│{"    "}││{"    "}││
                    {"    "}││{"    "}│ {"\n"}
                    {"           "}└────┘└────┘└────┘{"      "}└────┘└────┘└────┘└────┘{"\n"}
                    {"    "}
                    {"\n"}
                    {"    "}
                    {"\n"}
                    {
                        "                                                                             "
                    }
                    ┌────┐┌────┐{"\n"}
                    {
                        "                                                                             "
                    }
                    │{"    "}││{"    "}│{"\n"}
                    {
                        "                                                                             "
                    }
                    │{"    "}││{"    "}│{"\n"}
                    {
                        "                                                                             "
                    }
                    │ <a className={`stock contenneurP ${dispo(P[12])}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            handleConteneurClick(P[12]);
                        }}>P12</a>││<a className={`stock contenneurP ${dispo(P[11])}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                handleConteneurClick(P[11]);
                            }}>P11</a> │
                    {"\n"}
                    {
                        "                                                                             "
                    }
                    │{"    "}││{"    "}│{"\n"}
                    {
                        "                                                                             "
                    }
                    │{"    "}││{"    "}│{"\n"}
                    {
                        "                                                                             "
                    }
                    │{"    "}││{"    "}│{"\n"}
                    {
                        "                                                                             "
                    }
                    └────┘└────┘{"\n"}
                    {"    "}
                    {"\n"}
                    {"      "}┌──────────────┐{"                                   "}
                    ┌──────────────┐┌──────────────┐{"\n"}
                    {"      "}│{"     "}
                    <a className={`stock contenneurPr ${dispo("M1")}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            handleConteneurClick("M1");
                        }}>M1</a>
                    {"       "}│{"                                   "}│{"     "}
                    <a className={`stock contenneurPr ${dispo(P[5])}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            handleConteneurClick(P[5]);
                        }}>P5</a>
                    {"       "}││{"     "}
                    <a className={`stock contenneurPr ${dispo(P[6])}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            handleConteneurClick(P[6]);
                        }}>P6</a>
                    {"       "}│{"\n"}
                    {"      "}│{"              "}│{"                                   "}│
                    {"              "}││{"              "}│{"\n"}
                    {"      "}└──────────────┘{"                                   "}
                    └──────────────┘└──────────────┘{"\n"}
                    {"      "}┌──────────────┐{"                                 "}
                    {"\n"}
                    {"      "}│{"     "}
                    <a className={`stock Rbox2  ${dispo("EXT")}`}
                        onClick={() => {
                            handleConteneurClick("EXT");
                        }}
                        style={{ cursor: "pointer" }}>EXT</a>
                    {"      "}│{"                  "}
                    {"\n"}
                    {"      "}│{"              "}│{"                                 "}
                    {"\n"}
                    {"      "}└──────────────┘{"                                  "}
                    {"\n"}
                    {"    "}
                    {"\n"}
                    {"    "}
                    {"\n"}
                    {"    "}
                    {"\n"}
                    {"   "}
                    {"\n"}
                    {"    "}
                    {"\t"}
                    {"\t"}
                </pre>
            </div>

            <a></a>
        </>



    );
};
