import React from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";

export const SuprRgl = ({ setSuprRgl, idSelected, onClose }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleConfirm = () => {
    try {
      axios
        .post(`${apiUrl}IHM/api/paiement.php?idSupr=${idSelected}`)

        .then((response) => {
          console.log("okey, suprimé");
          console.log(response);
          toast.success(`Paiement n° ${idSelected} supprimé avec succès!`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setSuprRgl(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("  error");
    }
  };

  const handleCancel = () => {
    toast.error(`Suppression du paiement n° ${idSelected} annulée!`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    setSuprRgl(false);
    if (typeof onClose === "function") {
      onClose(); // Appel de la fonction de rappel , manao mise à jour atsy
      console.log("OnClose");
    }
  };
  return (
    <div>
      <div>
        <div className="modalContainerSupr" style={{ textAlign: "center" }}>
          <p>
            Voulez-vous vraiment supprimer le paiement numero {idSelected} ?
          </p>{" "}
          <Button
            variant="contained"
            color="secondary"
            style={{ margin: "10px" }}
            onClick={handleConfirm}
          >
            Oui
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "10px" }}
            onClick={handleCancel}
          >
            Non
          </Button>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};
