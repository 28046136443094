import React, { useEffect } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
} from "@mui/material";
import * as ClientStyle from "../../client/ClientStyle/clientStyle";
import axios from "axios";
import { useState } from "react";
export const InfoLocation = ({ idInfoLoc, idStock, selectedNomCli }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [paieData, setPaieData] = useState([]);
  const searchPaie = () => {
    axios
      .post(`${apiUrl}IHM/api/paiement.php?searchP=${idInfoLoc} `)
      .then((response) => {
        setPaieData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    searchPaie();
  }, [idInfoLoc]);

  const formattedDate = (dateRecup) => {
    if (dateRecup === "0000-00-00" || dateRecup === "") {
      return ``;
    }
    const date = new Date(dateRecup);
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth()}`
        : date.getMonth().toString();
    const year = date.getFullYear();
    return `${day}-${(parseInt(month) + 1)
      .toString()
      .padStart(2, "0")}-${year}`;
  };

  return (
    <div className=" ">
      <div style={{ textAlign: "center", paddingLeft: "1%" }}>
        {paieData.length > 0 ? (
          <div>
            <div
              style={{
                textAlign: "center",
                color: "#4F4F63",
                textDecoration: "underline",
                padding: "10px",
              }}
            >
              <strong>
                {" "}
                <>
                  {paieData.length} paiement(s) pour la LOC n°{idInfoLoc} (Box:{" "}
                </>
                {idStock})
                <>
                  <br></br>
                  {selectedNomCli}
                </>
              </strong>
            </div>

            <br />

            <div>
              {paieData.map((client) => (
                <div key={client.idPaiement}>
                  <strong>
                    Paiement n° <span>{client.idPaiement}</span>
                  </strong>
                  <br />
                  Date : <span>{formattedDate(client.datePaie)}</span> <br />
                  Somme: <span>{client.valeurPaie} €</span> ({client.typePaie})
                  <br />P :{" "}
                  <span>
                    {formattedDate(client.dateDebut)} au{" "}
                    {formattedDate(client.dateFin)}
                  </span>{" "}
                  <br /> <br />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div>
              {idInfoLoc ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <div>
                    <strong>
                      {" "}
                      <>LOC n°{idInfoLoc} </>
                      <>
                        {" "}
                        <br></br>
                        {selectedNomCli}
                      </>
                    </strong>
                  </div>
                  <br />
                  <br />
                  <div style={{ textAlign: "center" }}>Aucun paiement</div>
                </div>
              ) : (
                <div style={{ textAlign: "center" }}>Détails de paiements</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
