import { Theme, createTheme } from "@mui/material";
import { green, purple } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    primary: { main: "#146C94", light: "#fdb581" }, // Utilisation d'une couleur légère différente
    secondary: {
      // Utilisation d'une couleur légère différente
      main: "#AFD3E2",
    },
  },
});
