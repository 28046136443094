import * as React from "react";

import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import { useState, useRef } from "react";
// import { Test } from "./pages/test/test";
import "primeicons/primeicons.css";
import { Toast } from "primereact/toast";
// import { Test } from "./pages/test/test";
import { Logout } from "./pages/logout/logoutPage/logout";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Logo from "./assets/logo.png";
import Navbar from "./component/Navbar";
import NavbarGauche from "./component/NavbarGauche";
import "./App.css";
import { Link, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Login } from "./pages/login/loginPage/login";
import { Stockage } from "./pages/stockage/stockage";
import { StockageRetard } from "./pages/stockageRetard/stockageRetard";
import { Client } from "./pages/client/ClientPage/client";
import { Reglement } from "./pages/reglement/reglementPage/Reglement";
 
 import { Contrat } from "./pages/contrat/contratPage/contrat";
 import Tasklist from './pages/tasklist/tasklist';
import { Dash } from "./pages/dashboard/dashboardPage/dash";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LocationStockage } from "./pages/location/locationPage/locationStockage";
import { ToastContainer } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect } from "react";
import { Facture } from "./pages/facture/facturePage/facture";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./styles/NavbarGauche.css";
import { Modal } from "@mui/material";

const drawerWidth = 240;

function App(props) {
  const isMobilePortrait = useMediaQuery("(max-width:1400px) ");

  const [nomPageActive, setNomPageActive] = useState("");
  const locationGauche = useLocation();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [deconnectHovered, setDeconnectHovered] = useState(false);

  const [deconnect, setdeconnect] = useState(false);
  // const handleNavigation = (route) => {
  //   switch (route) {
  //     case "Tableau de bord":
  //       navigate("/");
  //       setNomPageActive("TABLEAU DE BORD");
  //       break;
  //     case "Plan du site":
  //       navigate("/stockage");
  //       setNomPageActive("PLAN DU SITE");
  //       break;
  //     case "Clients":
  //       navigate("/client");
  //       setNomPageActive("CLIENTS");
  //       break;
  //     case "Locations":
  //       navigate("/location");
  //       setNomPageActive("LOCATIONS");
  //       break;
  //     case "Paiements":
  //       navigate("/reglementListe");
  //       setNomPageActive("PAIEMENTS");
  //       break;
  //     case "Facture":
  //       navigate("/facture");
  //       setNomPageActive("FACTURE");
  //       break;
  //     case "Contrat":
  //       navigate("/contrat");
  //       setNomPageActive("CONTRAT");
  //       break;
  //     case "Retard":
  //       // Handle Retard route
  //       break;

  //     default:
  //       break;
  //   }
  // };

  const drawer = (
    <div>
      <Divider />

      <div className="navbarGauche">
        <Link
          to="/"
          className={locationGauche.pathname === "/" ? "activeLink" : "ty"}
          onClick={() => {
            setNomPageActive("TABLEAU DE BORD");
          }}
        >
          <img
            src={Logo}
            style={{ marginLeft: "23%", marginTop: "5%", width: "100px" }}
          />
        </Link>{" "}
        <div className="divLink">
          <Link
            to="/"
            className={locationGauche.pathname === "/" ? "activeLink" : "ty"}
            onClick={() => {
              setNomPageActive("TABLEAU DE BORD");
            }}
          >
            <strong> TABLEAU DE BORD</strong>
          </Link>
        </div>

        <div className="divLink">
        <Link
          to="/tasklist"
          className={
            locationGauche.pathname === "/tasklist" ? "activeLink" : "ty"
          }
          onClick={() => {
            setNomPageActive("Listes des tâches");
          }}
        >
          <b>TÂCHES</b>
        </Link>
        </div>
        
        <div className="divLink">
          <Link
            to="/stockage"
            className={
              locationGauche.pathname === "/stockage" ? "activeLink" : "ty"
            }
            onClick={() => {
              setNomPageActive("PLAN DU SITE");
            }}
          >
            <strong> PLAN DU SITE</strong>
          </Link>
        </div>
      {/*
        <div className="divLink">
          <Link
            to="/stockageRetard"
            className={
              locationGauche.pathname === "/stockageRetard" ? "activeLink" : "ty"
            }
            onClick={() => {
              setNomPageActive("PLAN DU SITE AVEC RETARD");
            }}
          >
            <strong> PLAN DU SITE (retard)</strong>
          </Link>

        </div>
*/}
        

        <div className="divLink">
          <Link
            to="/client"
            className={
              locationGauche.pathname === "/client" ? "activeLink" : "ty"
            }
            onClick={() => {
              setNomPageActive("CLIENTS");
            }}
          >
            <strong> CLIENTS</strong>
          </Link>
        </div>

        <div className="divLink">
          <Link
            to="/location"
            className={
              locationGauche.pathname === "/location" ? "activeLink" : "ty"
            }
            onClick={() => {
              setNomPageActive("LOCATIONS");
            }}
          >
            <strong>LOCATIONS </strong>
          </Link>
        </div>

        <div className="divLink">
          <Link
            to="/reglementListe"
            className={
              locationGauche.pathname === "/reglementListe"
                ? "activeLink"
                : "ty"
            }
            onClick={() => {
              setNomPageActive("PAIEMENTS");
            }}
          >
            <strong> PAIEMENTS</strong>
          </Link>
        </div>{" "}

        <div className="divLink">
          <Link
            to="/facture"
            className={
              locationGauche.pathname === "/facture" ? "activeLink" : "ty"
            }
            onClick={() => {
              setNomPageActive("FACTURE");
            }}
          >
            <strong> FACTURE</strong>
          </Link>
        </div>

        <div className="divLink">
        <Link
          to="/contrat"
          className={
            locationGauche.pathname === "/contrat" ? "activeLink" : "ty"
          }
          onClick={() => {
            setNomPageActive("CONTRAT");
          }}
        >
          <b>CONTRAT</b>
        </Link>
        </div> 

      </div>

      <Divider />
      {/* <List>
        {["Facture", "Retard"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <a
                href="#"
                onClick={() => handleNavigation(text)}
                target="_blank"
                style={{ cursor: "pointer" }}
              >
                <ListItemText primary={text} />
              </a>
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </div>
  );
  const toast = useRef(null);
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {" "}
      <Box sx={{ display: "flex" }}>
        <ToastContainer /> <Toast ref={toast} />
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar style={{ backgroundColor: "#AFD3E2", color: "#146C94" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              style={{ width: "40vw", maxWidth: "40vw", marginLeft: "-30px" }}
            >
              {isMobilePortrait && nomPageActive}
            </Typography>
            <div
              style={{ position: "fixed", top: 0, right: 0, padding: "10px" }}
            >
              <Typography variant="h6">
                <span
                  style={{
                    fontSize: "20px",
                    // fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => setDeconnectHovered(true)}
                >
                  {localStorage.getItem("us")}
                </span>{" "}
                <Tooltip title={"Déconnexion"} arrow>
                  <span>
                    <i
                      className="pi pi-sign-out"
                      style={{ fontSize: "1.4rem", cursor: "pointer" }}
                      onClick={() => {
                        setdeconnect(true);
                      }}
                    ></i>
                  </span>
                </Tooltip>
              </Typography>
            </div>
            <Modal
              open={deconnect}
              disableBackdropClick={true}
              aria-describedby="modal-modal-description"
            >
              <Logout
                onClose={() => {
                  setdeconnect(false);
                }}
              />
            </Modal>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            style={{ overflow: " hidden" }}
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "auto",
                height: "auto",
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 2,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />

          <Routes>
            <Route path="/login" element={<Login />} />
            {/* <Route path="/facture" element={<Facture />} /> */}
            <Route path="/" element={<Dash />} />
            {isMobilePortrait ? null : (
              <>
                <Route path="/stockage" element={<Stockage />} /> 
                <Route path="/stockageRetard" element={<StockageRetard />} /> 
                <Route path="/client" element={<Client />} />
              </>
            )}
            <Route path="/location" element={<LocationStockage />} />
            <Route path="/reglementListe" element={<Reglement />} />
            <Route path="/contrat" element={<Contrat />} /> 
             <Route path="/tasklist" element={<Tasklist />} />
              <Route path="/facture" element={<Facture />} /> 
          </Routes>
        </Box>
        {isMobilePortrait ? (
          <>
            <Box
              sx={{
                flexGrow: 1,
                p: 3,
                marginLeft: `-${drawerWidth}px`,
              }}
            >
              <Toolbar />
              <Routes>
                <Route path="/stockage" element={<Stockage />} />{" "}
              </Routes>
            </Box>
          </>
        ) : null}{" "}
         {isMobilePortrait ? (
          <>
            <Box
              sx={{
                flexGrow: 1,
                p: 3,
                marginLeft: `-95px`,
              }}
            >
              <Toolbar />
              <Routes>
                <Route path="/stockageRetard" element={<StockageRetard />} />{" "}
              </Routes>
            </Box>
          </>
        ) : null}{" "}
        {isMobilePortrait ? (
          <>
            <Box
              sx={{
                flexGrow: 1,
                p: 3,
                // marginLeft: `-${drawerWidth}px`,
              }}
              style={{ width: "90vw" }}
            >
              <Toolbar />
              <Routes>
                <Route path="/client" element={<Client />} />{" "}
              </Routes>
            </Box>
          </>
        ) : null}
      </Box>
    </LocalizationProvider>
  );
}

App.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default App;