import React, { useRef, useEffect, useState } from "react";
import Chart from "chart.js/auto";
import axios from "axios";

export const LineChart24 = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const chartRef = useRef(null);
  const [semestreData2024, setSemestreData2024] = useState([]);
  const [semestreData2023, setSemestreData2023] = useState([]);
  const [semestreData2022,setSemestreData2022] = useState([]);
  const chartInstanceRef = useRef(null);  // Référence pour stocker l'instance du graphique

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${apiUrl}IHM/api/dashboard.php?periode=2024`
        );
        setSemestreData2024(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchDataSrt = async () => {
      try {
        const response = await axios.post(
          `${apiUrl}IHM/api/dashboard.php?periode=2023`
        );
        setSemestreData2023(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDataSrt();
  }, []);

  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const response = await axios.post(
          `${apiUrl}IHM/api/dashboard.php?periode=2022`
        );
        setSemestreData2022(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData1();
  }, []);

  useEffect(() => {
    if (chartRef.current && semestreData2024.length > 0 && semestreData2023.length > 0 && semestreData2022.length >0) {
      const ctx = chartRef.current.getContext("2d");

      // Détruire l'ancienne instance de graphique si elle existe
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const data = {
        labels: semestreData2024.map((item) => item.mois),
        datasets: [
          {
            label: "Entrées en 2024",
            data: semestreData2024.map((item) => item.nombre_de_locations),
            borderColor: "#146C94",
            borderWidth: 2,
            fill: false,
          },
          {
            label: "Entrées en 2023",
            data: semestreData2023.map((item) => item.nombre_de_locations),
            borderColor: "#ef476f",
            borderWidth: 2,
            fill: false,
          },
          {
            label: "Entrées en 2022",
            data: semestreData2022.map((item) => item.nombre_de_locations),
            borderColor: "#a49e87",
            borderWidth: 2,
            fill: false,
          },
        ],
      };

      const options = {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        responsive: true,
        aspectRatio: 1.4,
      };

      // Créer un nouveau graphique et stocker l'instance
      chartInstanceRef.current = new Chart(ctx, {
        type: "line",
        data: data,
        options: options,
      });
    }

    // Nettoyage pour détruire le graphique lorsqu'il n'est plus nécessaire
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [semestreData2024,semestreData2023,semestreData2022]);

  return (
    <div className="chart-container">
      <h3 style={{ textAlign: "center" }}>Entrée</h3>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};
