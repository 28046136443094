import React from "react";
import "../../location/locationStyle/ajoutLocation.css";
import axios from "axios";
import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToastContainer, toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  TextareaAutosize,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import { TextField } from "@mui/material";
export const ModifierReglement = ({ setModifierRgm, idPSelected }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [paieData, setPaieData] = useState([]);

  const [modePaiement, setModePaiement] = useState("vir");
  const [valeurPaie, setValeurPaie] = useState(0);
  const [dateFin, setDateFin] = useState("2021-01-01");
  const [dateDebut, setDateDebut] = useState("2021-01-01");
  const [datePaie, setDatePaie] = useState("2021-01-01");
  const [datePaieM, setDatePaieM] = useState(dayjs(datePaie));
  const [dateFinM, setDateFinM] = useState(dayjs(dateFin));
  const [dateDebutM, setDateDebutM] = useState(dayjs(dateDebut));
  const formatDate = (date) => {
    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const [idLocation, setIdLocation] = useState("");
  const [idClient, setIdClient] = useState("");
  const [idStockage, setIdStockage] = useState("");

  const isMobilePortrait = useMediaQuery("(max-width:1207px)  ");
  //mitovy am'ajout
  const [nomCompl, setNomCompl] = useState("");
  const [num, setNum] = useState("");
  const [mail, setMail] = useState("");
  const [adresse, setAdresse] = useState("");
  useEffect(() => {
    console.log("TADAVINA", idPSelected);
    axios
      .get(`${apiUrl}IHM/api/paiement.php`, {
        params: { idPMod: idPSelected },
      })
      .then((response) => {
        console.log(response.data, "THIS IS VALINY");
        const paieData = response.data;
        setPaieData(paieData);
        const adresse = `${paieData[0].ville} - ${paieData[0].codePostal} - ${paieData[0].adresse}`;
        const num = `${paieData[0].num1} - ${paieData[0].num2}`;
        setIdLocation(paieData[0].idLocation);
        setIdClient(paieData[0].idClient);
        setNomCompl(paieData[0].nom);
        setNum(num);
        setMail(paieData[0].mail);
        setAdresse(adresse);
        setIdStockage(paieData[0].idStockage);
        setModePaiement(paieData[0].typePaie);
        setValeurPaie(paieData[0].valeurPaie);
        setDatePaie(paieData[0].datePaie);
        setDateFin(paieData[0].dateFin);
        setDateDebut(paieData[0].dateDebut);
      })
      .catch((error) => {
        console.error("error");
      });
  }, [idPSelected]); // Exécutez cette requête lorsque idPSelectedchange

  //fin client

  const handleModePaiementChange = (e) => {
    setModePaiement(e.target.value);
  };

  const [erreur, setErreur] = useState(false);

  const handleValeurPaieChange = (e) => {
    const inputValue = e.target.value;

    // Vérifie si l'entrée est un nombre positif
    if (/^[+]?\d*\.?\d*$/.test(inputValue) || inputValue === "") {
      setValeurPaie(inputValue);

      setErreur(false);
    } else {
      setErreur(true);
    }
  };

  useEffect(() => {
    setDateDebutM(dayjs(dateDebut));
  }, [dateDebut]);
  useEffect(() => {
    setDatePaieM(dayjs(datePaie));
  }, [datePaie]);
  useEffect(() => {
    setDateFinM(dayjs(dateFin));
  }, [dateFin]);

  const faireModification = async (e) => {
    e.preventDefault();

    console.log("appelle  a update");
    // updateBase();
    let debutFormate;
    let finFormate;
    let paieFormate;

    paieFormate = formatDate(datePaie);
    debutFormate = formatDate(dateDebut);

    finFormate = formatDate(dateFin);

    if (paieData.length > 0) {
      const data = {
        idPaie: idPSelected,
        typePaie: modePaiement,
      
        valeurPaie: valeurPaie,
        datePaie: paieFormate,
        dateDebut: debutFormate,
        dateFin: finFormate,
      };

      try {
        console.log("ato data:", data);
        await axios

          .post(`${apiUrl}IHM/api/paiement.php`, data)

          .then((response) => {
            console.log(response.data);
            setModifierRgm(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log("AIZAEEE error");
      }
      console.log(data, "data re ah");
    }
  };
  const handleDateDChange = (date) => {
    setDateDebut(date);
  };
  const handleDatePChange = (date) => {
    setDatePaie(date);
  };
  const handleDateFChange = (date) => {
    setDateFin(date);
  };
  return (
    <div className=" ">
      {!isMobilePortrait && (
        <div className="modalContainerLoc">
          <div className="">
            <span className="titreEtBtnLoc">
              <CancelIcon
                style={{ cursor: "pointer" }}
                sx={{ marginLeft: "95%", fontSize: 40, marginTop: "-26px" }}
                onClick={() => setModifierRgm(false)}
              />
              <h3 className="titleLoc">Modifier une Paiement</h3>
            </span>
            <form className="" onSubmit={faireModification}>
              <span class="parent-span">
                <span class="left-span">
                  <div>
                    <TextField
                      id="standard-search"
                      label="Id location"
                      type="text"
                      variant="standard"
                      value={idLocation}
                      onChange={(e) => setIdLocation(e.target.value)}
                      disabled
                      style={{ width: "100%" }}
                    />{" "}
                    <br /> <br />
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="modePaiement-label">
                        Mode de Paiement
                      </InputLabel>

                      <Select
                        labelId="modePaiement-label"
                        id="modePaiement"
                        value={modePaiement}
                        onChange={handleModePaiementChange}
                      >
                        <MenuItem value="vir">Virement bancaire</MenuItem>
                        <MenuItem value="espc">Espèce</MenuItem>
                        <MenuItem value="cBl">carte bleu</MenuItem>
                        <MenuItem value="cq">Chèque</MenuItem>
                        <MenuItem value="ABOcBl">ABO carte bleu</MenuItem>
                      </Select>
                    </FormControl>
                    <br /> <br />
                    <TextField
                      id="standard-search"
                      label="Valeur payée (€)"
                      type="text"
                      variant="standard"
                      value={valeurPaie}
                      onChange={handleValeurPaieChange}
                      error={erreur}
                      helperText={erreur ? "Entrez un nombre positif" : ""}
                      style={{ width: "100%" }}
                    />{" "}
                    <br />
                    <br />
                    <DemoContainer components={["DatePicker"]}>
                      <DemoItem>
                        <DatePicker
                          format="DD-MM-YYYY"
                          label="Date de paiement"
                          value={datePaieM}
                          onChange={handleDatePChange}
                          style={{ display: "none" }}
                        />
                      </DemoItem>
                    </DemoContainer>{" "}
                    <br />
                    <br />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <DemoContainer components={["DatePicker"]}>
                          <DemoItem>
                            <DatePicker
                              format="DD-MM-YYYY"
                              label="Date Debut"
                              value={dateDebutM}
                              onChange={handleDateDChange}
                              style={{ display: "none" }}
                            />
                          </DemoItem>
                        </DemoContainer>
                      </Grid>
                      <Grid item xs={6}>
                        <DemoContainer components={["DatePicker"]}>
                          <DemoItem>
                            <DatePicker
                              format="DD-MM-YYYY"
                              label="Date Fin"
                              value={dateFinM}
                              onChange={handleDateFChange}
                              style={{ display: "none" }}
                            />
                          </DemoItem>
                        </DemoContainer>
                      </Grid>
                    </Grid>{" "}
                  </div>
                </span>
                <span class="right-span">
                  <TextField
                    id="standard-search"
                    label="Nom "
                    type="text"
                    variant="standard"
                    value={`${idClient} - ${nomCompl}`}
                    disabled
                  />
                  <br />
                  <TextField
                    id="standard-search"
                    label="Mail"
                    type="text"
                    variant="standard"
                    value={mail}
                    disabled
                  />
                  <br />
                  <TextField
                    id="standard-search"
                    label="Numéro  "
                    type="text"
                    variant="standard"
                    value={num}
                    disabled
                  />
                  <br />
                  <TextField
                    id="standard-search"
                    label="Adresse"
                    type="text"
                    variant="standard"
                    value={adresse}
                    disabled
                  />
                </span>
              </span>
              <br />
              <br />
              <Button
                className="myBouton"
                variant="contained"
                type="submit"
                sx={{ marginLeft: "40%" }}
              >
                Modifier
              </Button>
            </form>
          </div>
        </div>
      )}{" "}
      {isMobilePortrait && (
        <div className="modalContainerLoc">
          <div className="">
            <span className=" ">
              <CancelIcon
                style={{ cursor: "pointer" }}
                sx={{ marginLeft: "90%", fontSize: 30, marginTop: "-6px" }}
                onClick={() => setModifierRgm(false)}
              />

              <div style={{ textAlign: "center" }}>
                <span
                  style={{
                    display: "inline-block",
                    fontSize: 20,
                  }}
                >
                  Modifier une Paiement
                  <br /> <br />
                </span>
              </div>
            </span>
            <form className="" onSubmit={faireModification}>
              <span class=" ">
                <span class=" ">
                  <div>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <span
                        className="LP"
                        style={{
                          textAlign: "right",
                          fontWeight: "700",
                          fontFamily: "Arial, sans-serif",
                          fontSize: "17px",
                          color: "#EC994B",
                          marginTop: "10px 0",
                        }}
                      >
                        {idClient} - {nomCompl}
                      </span>
                    </div>
                    {mail}
                    <br />
                    {num}
                    <br />
                    {adresse} <br /> <br />
                    <TextField
                      id="standard-search"
                      label="Id location : "
                      type="text"
                      variant="standard"
                      value={idLocation}
                      disabled
                      style={{ width: "120%" }}
                    />
                    <br /> <br />
                    <FormControl variant="standard" style={{ width: "120%" }}>
                      <InputLabel id="modePaiement-label">
                        Mode de Paiement
                      </InputLabel>

                      <Select
                        labelId="modePaiement-label"
                        id="modePaiement"
                        value={modePaiement}
                        onChange={handleModePaiementChange}
                      >
                        <MenuItem value="vir">Virement bancaire</MenuItem>
                        <MenuItem value="espc">Espèce</MenuItem>
                        <MenuItem value="cBl">carte bleu</MenuItem>
                        <MenuItem value="cq">Chèque</MenuItem>
                        <MenuItem value="ABOcBl">ABO carte bleu</MenuItem>
                      </Select>
                    </FormControl>
                    <br /> <br />
                    <TextField
                      id="standard-search"
                      label="Valeur payée (€)"
                      type="text"
                      variant="standard"
                      value={valeurPaie}
                      onChange={handleValeurPaieChange}
                      error={erreur}
                      helperText={erreur ? "Entrez un nombre positif" : ""}
                      style={{ width: "120%" }}
                    />
                    <br />
                    <br />
                    <div style={{ width: "120%" }}>
                      <DemoContainer
                        components={["DatePicker"]}
                        style={{ width: "120%" }}
                      >
                        <DatePicker
                          format="DD-MM-YYYY"
                          label="Date de paiement"
                          value={datePaieM}
                          onChange={handleDatePChange}
                          style={{ width: "120%", display: "none" }}
                        />
                      </DemoContainer>
                      <br />
                      <DemoContainer
                        components={["DatePicker"]}
                        style={{ width: "120%" }}
                      >
                        <DatePicker
                          format="DD-MM-YYYY"
                          label="Date Debut"
                          value={dateDebutM}
                          onChange={handleDateDChange}
                          style={{ width: "120%", display: "none" }}
                        />
                      </DemoContainer>
                      <br />
                      <DemoContainer
                        components={["DatePicker"]}
                        style={{ width: "120%" }}
                      >
                        {" "}
                        <DatePicker
                          format="DD-MM-YYYY"
                          label="Date Fin"
                          value={dateFinM}
                          onChange={handleDateFChange}
                          style={{ width: "120%", display: "none" }}
                        />
                      </DemoContainer>
                    </div>
                  </div>
                </span>
              </span>
              <br />
              <br />
              <Button
                className="myBouton"
                variant="contained"
                type="submit"
                sx={{ marginLeft: "40%" }}
              >
                Modifier
              </Button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
