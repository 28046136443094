import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "./AppRouter";
import "./index.css";
import { AuthProvider } from "./pages/login/loginPage/AuthContext";

ReactDOM.render(
  <Router>
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  </Router>,
  document.getElementById("root")
);
