import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import "../../client/ClientStyle/client.css";
import dayjs from "dayjs";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

export const SelectContrat = ({
    setValueFiltre,
    valueFiltre,
    setContrats,
    ajoutContrat,
    modifierCtr,
    suprCtr,
}) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const searchTextFieldRef = useRef(null);
    useEffect(() => {
        if (searchTextFieldRef.current) {
            searchTextFieldRef.current.focus();
        }
    }, []);
    const [typeContrat, setTypeContrat] = useState("toutType");
    const [dateDebutS, setDateDebutS] = useState(dayjs(new Date()));
    const [dateFinS, setDateFinS] = useState(dayjs(new Date()));
    const [idLoc, setIdLoc] = useState("");
    const listerContrat = () => {
        axios
            .put(
                `${apiUrl}IHM/api/contrat.php?filtre=${valueFiltre}&recherche=${searchP}&debut=${dateDebutS}&fin=${dateFinS}&typeCtra=${typeContrat}&locSearch=${idLoc}`
            )

            .then((response) => {
                console.log("listeeSSSSSSSSS");
                console.log(response.data);
                setContrats(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const [searchP, setSearchP] = useState("");
    useEffect(() => {
        listerContrat();

    }, [
        ajoutContrat,
        modifierCtr,
        suprCtr,
        valueFiltre,
        searchP,
        idLoc,
        dateDebutS,
        dateFinS,
        typeContrat,
    ]);
    useEffect(() => {
        listerContrat();
        console.log("listeeeeeeeeeeeeeeeer");
    }, []);

    useEffect(() => {
        setSearchP("");
        setIdLoc("");
        setValueFiltre("actif")
        setTypeContrat("toutType");
        setDateDebutS("");
        setDateFinS("");
    }, [ajoutContrat]);
    function formatDate(date) {
        const selectedDate = new Date(date);
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
        const day = selectedDate.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    const handleDebutChange = (date) => {
        const formattedDate = formatDate(date);
        setDateDebutS(formattedDate);
    };

    const handleFinChange = (date) => {
        const formattedDate = formatDate(date);
        setDateFinS(formattedDate);
    };
    const [erreur, setErreur] = useState(false);
    const handleTypeContratChange = (e) => {
        setTypeContrat(e.target.value);
    };

    




    const handleChange = async (event) => {
        setValueFiltre(event.target.value);
    }
    const renderPaieList = () => {
        return (
            <div className=" ">
                <div className=" ">
                    <div className=" ">
                        <Box>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <TextField
                                    id="standard-search"
                                    label="Client "
                                    type="text"
                                    variant="standard"
                                    value={searchP}
                                    onChange={(e) => {
                                        setSearchP(e.target.value.trimStart());
                                    }}
                                    inputRef={searchTextFieldRef}
                                    style={{ width: "100%", marginRight: "10px" }}
                                />

                               
                            </div>
                            <br /> <br />
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="modePaie-label">Type de contrat</InputLabel>

                                <Select
                                    labelId="modePaie-label"
                                    id="typeContrat"
                                    value={typeContrat}
                                    onChange={handleTypeContratChange}
                                >
                                    <MenuItem value="toutType">Tout</MenuItem>
                                    <MenuItem value="CNS">Contrat non signé (CNS)</MenuItem>
                                    <MenuItem value="CS">Contrat signé (CS)</MenuItem>
                                    <MenuItem value="CE">Contrat envoyé (CE)</MenuItem>
                                    {/* <MenuItem value="CNE">Contrat non envoyé (CNE)</MenuItem>
                                    <MenuItem value="PDCAE">Pas de contrat à envoyer (PDC AE)</MenuItem> */}
                                    <MenuItem value="PDC">Pas de contrat (PDC)</MenuItem>
                                </Select>
                            </FormControl>
                            <br /> <br />
                            <RadioGroup
                                row
                                aria-label="controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={valueFiltre}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="tout" control={<Radio />} label="Tout" />
                                <FormControlLabel value="actif" control={<Radio />} label="Actif" />
                                <FormControlLabel
                                    value="nonActif"
                                    control={<Radio />}
                                    label="Non actif"
                                />
                            </RadioGroup>
                            <br /> <br />

                           
                            <DemoContainer components={["DatePicker"]}>
                                <DemoItem label="Contrat entre: ">
                                    <DatePicker
                                        format="DD-MM-YYYY"
                                        label="Début (Date du contrat)"
                                        onChange={handleDebutChange}
                                    />
                                    <DatePicker
                                        format="DD-MM-YYYY"
                                        label="Fin (Date du contrat)"
                                        onChange={handleFinChange}
                                    />
                                </DemoItem>
                            </DemoContainer>
                            <br /> <br />

                            <div> </div>
                        </Box>
                    </div>
                </div>
            </div>
        );
    };

    //***********************************FIN TABLEAU TABLEAU TABLEAU******************************* */

    return <div>{renderPaieList()}</div>;
};
