import React from 'react';

const EmailLink = ({ email, name ,states }) => {
  // Encode les paramètres pour être sûr qu'ils sont correctement formatés pour une URL
  const encodedName = encodeURIComponent(name);
  const objet = encodeURIComponent("Les Dalons Du Stockage - Votre facture de stockage");
  
  // Concaténer le nom au message
  const body = encodeURIComponent(`Bonjour ${name},\n\nVoici votre facture de stockage.`);
  
  // Crée l'URL de Gmail avec les paramètres
  const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${objet}&body=${body}`;

  if (!states){
    return (
    <a href={gmailLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
      <span style={{ marginLeft: "5px", fontSize: "14px", color: "#555" }}>
        ✉
      </span>
    </a>
  );
  }
  else{
    return (
      <a href={gmailLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        <span style={{ marginLeft: "5px", fontSize: "14px", color: "#555" }}>
          {email}
        </span>
      </a>
    );
  }

  
};

export default EmailLink;