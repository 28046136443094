import "../conteneurStyle/contenneursStyle.css";
import { Conteneur } from "./conteneur";
import { useState, useEffect } from "react";
import axios from "axios";

import { useMediaQuery } from "@mui/material";
import React from "react";

const Contenneurs = ({ setSearch, setMotRecherche ,motRechercheClient ,  setMotRechercheClient}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [triee, setTrie] = useState([]);

  useEffect(() => {
    axios.get(`${apiUrl}IHM/api/stockage.php`).then((response) => {
      const listeOccupe = response.data.map((item) => item.idStockage);
console.log(response.data,'tyyyyyyyyy')
      setTrie(listeOccupe);
    });
  }, []);
  const dispo = (valeur) => {
    return triee.includes(valeur) ? "vert" : "rouge";
  };
  const P = [];
  for (let i = 0; i < 20; i++) {
    P[i] = `P${i}`;
  }

  const G = [];
  for (let j = 0; j < 100; j++) {
    G[j] = `G${j}`;
  }

  const handleConteneurClick = (param) => {
    setMotRecherche(param);
    setSearch(true);

    if (param === "") {
      // Si le champ de recherche est vide, réinitialisez les styles et sortez de la fonction
      const divs = document.querySelectorAll(".stock");
      divs.forEach((div) => {
        div.classList.remove("highlight-yellow");
      });
      return;
    }

    const regex = new RegExp(`\\b${param}\\b`, "i");
    const divs = document.querySelectorAll(".stock");
    divs.forEach((div) => {
      const texteDiv = div.textContent;
      //  recherche
      if (regex.test(texteDiv)) {
        // Si le texte de la div correspond à la recherche, appliquez le style
        div.classList.add("highlight-yellow");
        setTimeout(() => {
          div.classList.remove("highlight-yellow");
        }, 900);

        // Calcul de la position et défilement de la page
        const windowHeight = window.innerHeight;
        const divTopOffset = div.getBoundingClientRect().top;
        const scrollPosition = divTopOffset - windowHeight / 2;

        window.scrollBy({
          top: scrollPosition,
          behavior: "smooth",
        });
      } else {
        // Si le texte de la div ne correspond pas à la recherche, supprimez le style
        div.classList.remove("highlight-yellow");
      }
    });
  };
  const isMobilePortrait = useMediaQuery("(max-width:1400px) ");
  return (
    <div>
      <div className="containerBox">
        <div className="toutStock">
          <div className="ligne">
            <div className="colonne">
              <div className="ligne">
                <div
                  className={` stock contenneurP ${dispo(P[0])}`}
                  onClick={() => {
                    handleConteneurClick(P[0]);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {P[0]}
                  <Conteneur />
                </div>
                <div
                  className={` stock contenneurP ${dispo(P[1])}`}
                  onClick={() => {
                    handleConteneurClick(P[1]);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {P[1]}
                  <Conteneur />
                </div>
              </div>
              <div
                className={` stock contenneurP ${dispo(P[2])}`}
                style={{ marginLeft: "8vh", cursor: "pointer" }}
                onClick={() => {
                  handleConteneurClick(P[2]);
                }}
              >
                {P[2]}
                <Conteneur />
              </div>
            </div>
            <div
              className="ligne"
              style={{ marginTop: "16px", cursor: "pointer" }}
            >
              <div
                className={`stock contenneurG ${dispo(G[1])}`}
                onClick={() => {
                  handleConteneurClick(G[1]);
                }}
              >
                {G[1]}
                <Conteneur />
              </div>
              <div
                className={`stock contenneurG ${dispo(G[2])}`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleConteneurClick(G[2]);
                }}
              >
                {G[2]}
                <Conteneur />
              </div>
              <div
                className={` stock contenneurP ${dispo(P[14])}`}
                style={{  marginLeft: "5vh",marginTop: "11vh", cursor: "pointer" }}
                onClick={() => {
                  handleConteneurClick(P[14]);
                }}
              >
             {P[14]}
                <Conteneur />
              </div>
            </div>
            <div
              className="ligne"
              style={{ marginTop: "16px"  }}
            >
              <div
                style={{
                  cursor: "pointer",
                }}
                className={`stock contenneurG ${dispo(G[4])}`}
                onClick={() => {
                  handleConteneurClick(G[4]);
                }}
              >
                {G[4]}
                <Conteneur />
              </div>
              <div
                style={{
                  cursor: "pointer",
                }}
                className={`stock contenneurG ${dispo(G[3])}`}
                onClick={() => {
                  handleConteneurClick(G[3]);
                }}
              >
                {G[3]}
                <Conteneur />
              </div>
            </div>
            <div className="colonne">
              <div className="ligne">
                {" "}
                <div
                  className={`stock contenneurP ${dispo(P[3])}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleConteneurClick(P[3]);
                  }}
                >
                  {P[3]}
                  <Conteneur />
                </div>
                <div
                  className={`stock contenneurP ${dispo(P[13])}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleConteneurClick(P[13]);
                  }}
                >
                  {P[13]}
                  <Conteneur />
                </div>{" "}
                <div
                  className={`stock contenneurP ${dispo(P[7])}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleConteneurClick(P[7]);
                  }}
                >
                  {P[7]}
                  <Conteneur />
                </div>{" "}
                <div
                  className={`stock contenneurP ${dispo(P[8])}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleConteneurClick(P[8]);
                  }}
                >
                  {P[8]}
                  <Conteneur />
                </div>{" "}
                <div
                  className={`stock contenneurP ${dispo(P[9])}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleConteneurClick(P[9]);
                  }}
                >
                  {P[9]}
                  <Conteneur />
                </div>{" "}
                <div
                  className={`stock contenneurP ${dispo(P[10])}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleConteneurClick(P[10]);
                  }}
                >
                  {P[10]}
                  <Conteneur />
                </div>
              </div>

              <div
                className={`stock contenneurP ${dispo(P[4])}`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleConteneurClick(P[4]);
                }}
              >
                {P[4]}
                <Conteneur />
              </div>
            </div>
          </div>
          <div
            className="ligne"
            style={{ marginTop: "-26px", marginLeft: "85vh" }}
          >
            <div
              className={`stock contenneurP ${dispo(P[12])}`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleConteneurClick(P[12]);
              }}
            >
              {P[12]}
              <Conteneur />
            </div>{" "}
            <div
              className={`stock contenneurP ${dispo(P[11])}`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleConteneurClick(P[11]);
              }}
            >
              {P[11]}
              <Conteneur />
            </div>
          </div>
          <div
            className="ligne"
            style={{
              marginTop: "26px",
            }}
          >
            <div className="ligne">
              <div
                className={`stock contenneurPr ${dispo("M1")}`}
                style={{ cursor: "pointer", marginLeft: "10vh" }}
                onClick={() => {
                  handleConteneurClick("M1");
                }}
              >
                M1
                <Conteneur />
              </div>
              {/* <div
                className={`stock contenneurPr ${dispo("G0")}`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleConteneurClick("G0");
                }}
              >
                G0
                <Conteneur />
              </div> */}
              <div
                className={`stock contenneurPr ${dispo(P[5])}`}
                style={{ marginLeft: "45vh", cursor: "pointer" }}
                onClick={() => {
                  handleConteneurClick(P[5]);
                }}
              >
                {P[5]}
                <Conteneur />
              </div>
              <div
                className={`stock contenneurPr ${dispo(P[6])}`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleConteneurClick(P[6]);
                }}
              >
                {P[6]}
                <Conteneur />
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
export default Contenneurs;
