import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../client/ClientStyle/client.css";
import dayjs from "dayjs";
import * as ClientStyle from "../../client/ClientStyle/clientStyle";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import updateimg from "../../../assets/edit-512.webp";
import deleteimg from "../../../assets/supr.jpg";
import { makeStyles } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonGroup from "@mui/material/ButtonGroup";

import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

export const SelectReglement = ({
  setPaiements,
  ajoutReglement,
  modifierRgm,
  suprRgl,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const searchTextFieldRef = useRef(null);
  useEffect(() => {
    if (searchTextFieldRef.current) {
      searchTextFieldRef.current.focus();
    }
  }, []);
  const [modePaiement, setModePaiement] = useState("");
  const [dateDebutS, setDateDebutS] = useState(dayjs(new Date()));
  const [dateFinS, setDateFinS] = useState(dayjs(new Date()));
  const [idLoc, setIdLoc] = useState("");
  const listerPaiement = () => {
    axios
      .get(
        `${apiUrl}IHM/api/paiement.php?searchPaie=${searchP}&debut=${dateDebutS}&fin=${dateFinS}&modeP=${modePaiement}&locSearch=${idLoc}`
      )

      .then((response) => {
        console.log("paiements leka", response.data);
        setPaiements(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [searchP, setSearchP] = useState("");
  useEffect(() => {
    listerPaiement();
    console.log("listeeeeeeeeeeeeeeeer");
  }, [
    ajoutReglement,
    modifierRgm,
    suprRgl,
    searchP,
    idLoc,
    dateDebutS,
    dateFinS,
    modePaiement,
  ]);

  useEffect(() => {
    setSearchP("");
    setIdLoc("");
    setModePaiement("");
    setDateDebutS("");
    setDateFinS("");
  }, [ajoutReglement]);
  
  function formatDate(date) {
    const selectedDate = new Date(date);
    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = selectedDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleDebutChange = (date) => {
    const formattedDate = formatDate(date);
    setDateDebutS(formattedDate);
  };

  const handleFinChange = (date) => {
    const formattedDate = formatDate(date);
    setDateFinS(formattedDate);
  };
  const [erreur, setErreur] = useState(false);
  const handleModePaiementChange = (e) => {
    setModePaiement(e.target.value);
  };

  const handleIdLocChange = (e) => {
    const inputValue = e.target.value;
    // Vérifie si l'entrée est un nombre positif
    if (/^[+]?\d+(\.\d+)?$/.test(inputValue) || inputValue === "") {
      setIdLoc(inputValue);
      setErreur(false);
    } else {
      setErreur(true);
    }
  };
  const renderPaieList = () => {
    return (
      <div className=" ">
        <div className=" ">
          <div className=" ">
            <Box>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  id="standard-search"
                  label="Client / Paiement"
                  type="text"
                  variant="standard"
                  value={searchP}
                  onChange={(e) => {
                    setSearchP(e.target.value.trimStart());
                  }}
                  inputRef={searchTextFieldRef}
                  style={{ width: "60%", marginRight: "10px" }}
                />

                <TextField
                  id="standard-search"
                  label="Location"
                  type="text"
                  variant="standard"
                  value={idLoc}
                  style={{ width: "33%" }}
                  onChange={handleIdLocChange}
                  error={erreur}
                  helperText={erreur ? "Entrez un nombre positif" : ""}
                />
              </div>
              <br /> <br />
              <br />
              <DemoContainer components={["DatePicker"]}>
                <DemoItem label="Paiement entre: ">
                  <DatePicker
                    format="DD-MM-YYYY"
                    label="Début (Date de Paiement)"
                    onChange={handleDebutChange}
                  />
                  <DatePicker
                    format="DD-MM-YYYY"
                    label="Fin (Date de Paiement)"
                    onChange={handleFinChange}
                  />
                </DemoItem>
              </DemoContainer>
              <br /> <br />
              <FormControl variant="standard" fullWidth>
                <InputLabel id="modePaie-label">Mode de Paiement</InputLabel>

                <Select
                  labelId="modePaie-label"
                  id="modePaiement"
                  value={modePaiement}
                  onChange={handleModePaiementChange}
                >
                  <MenuItem value="">Tout</MenuItem>
                  <MenuItem value="vir">Virement bancaire</MenuItem>
                  <MenuItem value="espc">Espèce</MenuItem>
                  <MenuItem value="cBl">carte bleu</MenuItem>
                  <MenuItem value="cq">Chèque</MenuItem>
                  <MenuItem value="ABOcBl">ABO carte bleu</MenuItem>
                </Select>
              </FormControl>{" "}
              <br /> <br />
              <div> </div>
            </Box>
          </div>
        </div>
      </div>
    );
  };

  //***********************************FIN TABLEAU TABLEAU TABLEAU******************************* */

  return <div>{renderPaieList()}</div>;
};
