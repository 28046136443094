import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from 'primereact/button';

import "react-toastify/dist/ReactToastify.css";
import "../../location/locationStyle/locationStockage.css";
import "../../client/ClientStyle/client.css";
import { SuprFct } from "./suprFact";
import * as ClientStyle from "../../client/ClientStyle/clientStyle";
import { AjoutFacture } from "./ajoutFacture";
import copyImg from "../../../assets/copy.png";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { SelectFacture } from "./SelectFacture";
import { ModifierFacture } from "./modifFacture";
import { useMediaQuery, Tooltip } from "@mui/material";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Grid from '@mui/material/Grid';
import correctEncoding from "../../correctEncoding";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Modal,
} from "@mui/material";
import { toast } from "react-toastify";
import { fontSize } from "@mui/system";


export const Facture = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [copiedText, setCopiedText] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [factures, setFactures] = useState([]);
  const [afficheAjoutFact, setAfficheAjoutFact] = useState(false);
  const [modifierFct, setModifierFct] = useState(false);
  const [valueFiltre, setValueFiltre] = React.useState("tout");
  const [recherche, setRecherche] = useState("");
  const [rescentfact, setRescentFact] = useState("");
  const [suprFct, setSuprFct] = useState(false);
  const [idPSelected, setIdPSelected] = useState("");
  const isMobilePortrait = useMediaQuery("(max-width:1207px)  ");
  function mapTypePaie(typePaie) {
    switch (typePaie) {
      case "vir":
        return "Virement bancaire";
      case "espc":
        return "Espèce";
      case "cq":
        return "Chèque";
      case "cBl":
        return "Carte bleue";
      case "ABOcBl":
        return "ABO carte bleue";
      default:
        return typePaie;
    }
  }

  const listerFact = () => {



  };

  const formattedDate = (dateRecup) => {
    if (dateRecup === "0000-00-00" || !dateRecup) {
      return `-`;
    }
    const date = new Date(dateRecup);
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth()}`
        : date.getMonth().toString();
    const year = date.getFullYear();
    return `${day}-${(parseInt(month) + 1)
      .toString()
      .padStart(2, "0")}-${year}`;
  };

  const copyToClipboard = (text) => {
    if (text !== "") {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            setCopiedText(text);
            setOpenSnackbar(true);
          })
          .catch((error) => {
            console.error(
              "Erreur lors de la copie dans le presse-papiers : ",
              error
            );
          });
      } else {
        // Utiliser l'API de sélection de texte comme solution de secours
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);

        setCopiedText(text);
        setOpenSnackbar(true);
      }
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const renderFactureList = () => {
    console.log("appel à renderClientList");
    return (
      <div className="toutCL">
        <Grid container item xs={12} md={9} spacing={2} direction="column">

          <Modal
            open={afficheAjoutFact}
            disableBackdropClick={true}
            aria-describedby="modal-modal-description"
          >
            <AjoutFacture
              setAfficheAjoutFact={setAfficheAjoutFact}
              onClose={() => {
                setAfficheAjoutFact(false);
                listerFact(""); // Appel de listerFact après la fermeture ,,pour que les données du table se mettent à jour
              }}
            // setIdInfoLoc={setIdInfoLoc}
            />
          </Modal>
          <Modal
            open={modifierFct}
            disableBackdropClick={true}
            aria-describedby="modal-modal-description"
          >
            <ModifierFacture
              setModifierFct={setModifierFct}
              idPSelected={idPSelected}
            />
          </Modal>
          <Modal
            open={suprFct}
            disableBackdropClick={true}
            aria-describedby="modal-modal-description"
          >
            <SuprFct setSuprFct={setSuprFct} idSelected={idPSelected} />
          </Modal>
          {!isMobilePortrait && (
            <div className="flex">

              <div className="gaucheStock">
                <div>
                  <div className="center">

                    {/*
                    <Button
                      className="boutonAjouter"
                      style={{ width: "200px" }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setAfficheAjoutFact(true);
                      }}
                    // onClick={() => setVisible(true)}
                    >
                      Envoyer facture
                    </Button> */}


                  </div>
                  <div>
                    <Snackbar
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                      open={openSnackbar}
                      autoHideDuration={3000}
                      onClose={handleCloseSnackbar}
                      message={`Copie réussie de " ${copiedText === "location.mail" ? "e-mail" : copiedText
                        } "`}
                      action={
                        <React.Fragment>
                          <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseSnackbar}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </React.Fragment>
                      }
                    />
                  </div>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                  }}>
                    <h3 style={{
                      margin: 0,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: 'calc(100% - 120px)' // Adjust based on button width
                    }}>Liste des factures envoyés</h3>
                    <Button
                      label="Facture"
                      icon="pi pi-plus"
                      severity="info"
                      style={{
                        backgroundColor: '#146C94',
                        flexShrink: 0 // Prevents button from shrinking
                      }}
                      onClick={() => {
                        setAfficheAjoutFact(true);
                      }}
                    />
                  </div>

                  <div>
                    <ClientStyle.StyledPaper>
                      <div >
                        <DataTable
                          value={factures}
                          paginator
                          rows={10}
                          rowsPerPageOptions={[5, 8, 10, 25, 50, 500]}
                          tableStyle={{ minWidth: "13rem" }}
                          rowClassName={() => "custom-row-class"}
                          columnSeparator=" "
                          paginatorTemplate={
                            "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                          }
                          paginatorRight={
                            <span style={{ margin: "10px", fontWeight: "bold" }}>
                              Résultats : {factures.length}
                            </span>
                          }
                        >
                          <Column
                            field="numFact"
                            header="Ref"
                            style={{ width: "30%" }}
                            bodyStyle={{fontSize : "13px",}}
                            body={(rowData) => `${rowData.numFact} `}
                          ></Column>

                          <Column
                            className="loc-column-content"
                            field="idClient"
                            header="Client"
                            body={(rowData) => (
                              <Tooltip
                                title={
                                  <div>
                                    {rowData.mail !== "" && (
                                      <div
                                        style={{
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        <span>
                                          <ContentCopyIcon
                                            style={{
                                              cursor: "pointer",
                                              padding: "2px",
                                            }}
                                            fontSize="small"
                                            onClick={() =>
                                              copyToClipboard(rowData.mail)
                                            }
                                          />
                                          <span className="text-sm ">
                                            {rowData.mail}
                                          </span>
                                        </span>
                                      </div>
                                    )}
                                    {rowData.mail2 !== "" && (
                                      <div
                                        style={{
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        <span className=" ">
                                          <ContentCopyIcon
                                            style={{
                                              cursor: "pointer",
                                              padding: "2px",
                                            }}
                                            fontSize="small"
                                            onClick={() =>
                                              copyToClipboard(rowData.mail2)
                                            }
                                          />
                                          {rowData.mail2}
                                        </span>
                                      </div>
                                    )}

                                    {rowData.num1 !== "" && (
                                      <div
                                        style={{
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        <span className=" ">
                                          <ContentCopyIcon
                                            style={{
                                              cursor: "pointer",
                                              padding: "2px",
                                            }}
                                            fontSize="small"
                                            onClick={() =>
                                              copyToClipboard(rowData.num1)
                                            }
                                          />
                                          {rowData.num1}
                                        </span>
                                      </div>
                                    )}

                                    {rowData.num2 !== "" && (
                                      <div
                                        style={{
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        <span className=" ">
                                          <ContentCopyIcon
                                            style={{
                                              cursor: "pointer",
                                              padding: "2px",
                                            }}
                                            fontSize="small"
                                            onClick={() =>
                                              copyToClipboard(rowData.num2)
                                            }
                                          />
                                          {rowData.num2}{" "}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                }
                                arrow
                              >
                                <span className="loc-column-content1">
                                  {rowData.idClient}- {correctEncoding(rowData.nom)}
                                </span>
                              </Tooltip>
                            )}
                          ></Column> 
                          <Column
                            header="Type"
                            style={{ width: "20%" }}
                          
                            body={(rowData) => `${rowData.typeFact} `}
                          ></Column>

                          <Column
                            field="Paiement"
                            header="Paiement"
                            style={{ width: "20%" }}
                            body={(rowData) => (
                              <div>{mapTypePaie(rowData.idPaiement)}
                              </div>
                            )}
                          ></Column>
                          {/*<Column
                          header="Valeur payé"
                          style={{ width: "20%" }}
                          bodyStyle={{ padding: "10px" }}
                          body={(rowData) => `${rowData.tarif} `}
                        ></Column>*/}

                          <Column
                            field="datePaie"
                            header="Date Paie"

                            style={{ width: "30%" }}
                            bodystyle={{ fontSize : "12px" }}

                            body={(rowData) =>
                              <span style={{ width: "25vw", fontSize: "15px" }}>
                                {formattedDate(rowData.dateEnv)}
                                {/*P: {formattedDate(rowData.datePaie 
                            )}*/}
                              </span>
                            }
                          ></Column>

                          {/*} <Column
                        field="dateDebut"
                        header="Date début/fin"
                        style={{ width: "20%" }}
                        body={(rowData) => (
                          <span style={{ width: "25vw" }}>
                            D: {formattedDate(rowData.dateDebut)} <br />
                            F: {formattedDate(rowData.dateFin)}
                          </span>
                        )}
                      ></Column> */}

                          <Column
                            field="Box"
                            header="Box"
                            style={{ width: "15%" }}
                            bodyStyle={{ fontSize: "15px"}}
                            body={(rowData) => `${rowData.idStockage.split('||').join(',')} `}
                          ></Column>
                          
                          <Column
                            field="Notes"
                            header="Notes"
                            style={{ width: "35%" }}
                            bodyStyle={{ fontSize: "15px" }}
                            body={(rowData) => `${correctEncoding(rowData.note)} `}
                          ></Column>

                          {/* <Column
                        field="Stockage"
                        header="Date début/fin"
                        style={{ width: "20%" }}
                        body={(rowData) => (
                          <span style={{ width: "25vw" }}>
                            D: {formattedDate(rowData.dateDebut)} <br />
                            F: {formattedDate(rowData.dateFin)}
                          </span>
                        )}
                      ></Column> */}
                          {localStorage.getItem("pr") === "sad" ||
                            localStorage.getItem("pr") === "st" ||
                            localStorage.getItem("pr") === "ad" ? (
                            <Column
                              header="Actions"
                              style={{ width: "10vw" }}
                              body={(rowData) => (
                                <>
                                  <ModeEditOutlineIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setIdPSelected(rowData.numFact);
                                      // setRescentRgl(rowData.idPaiement);
                                      setModifierFct(true);
                                    }}
                                  />
                                  <DeleteIcon
                                    onClick={() => {
                                      setIdPSelected(rowData.numFact);
                                      setSuprFct(true);
                                      // setRescentRgl("");
                                    }}
                                    style={{
                                      marginLeft: "10px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </>
                              )}
                            ></Column>
                          ) : null}
                        </DataTable>

                      </div>
                    </ClientStyle.StyledPaper>
                  </div>
                </div>
              </div> <div className="droiteStock ">
                <Box
                  sx={{
                    marginLeft: {
                      xs: "10px", // Pour les écrans mobiles (extra small)
                      sm: "10px", // Pour les écrans de taille moyenne (small)
                      md: "10px", // Pour les écrans de taille moyenne (medium)
                      lg: "10px", // Pour les écrans larges (large)
                    },
                  }}
                  className="cente"
                >
                  <h2>FILTRE</h2>
                </Box>
                <SelectFacture
                  setFactures={setFactures}
                  afficheAjoutFact={afficheAjoutFact}
                  modifierFct={modifierFct}
                  suprFct={suprFct}
                />

              </div>
            </div>
          )}
          {isMobilePortrait && (
            <div className="flex">

              <div className="gaucheStock">
                <div>
                  <div className="center">
                    <Button
                      className="boutonAjouter"
                      style={{ width: "200px" }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setAfficheAjoutFact(true);
                      }}
                    // onClick={() => setVisible(true)}
                    >
                      Envoyer facture
                    </Button>
                  </div>
                  <div>
                    <Snackbar
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                      open={openSnackbar}
                      autoHideDuration={3000}
                      onClose={handleCloseSnackbar}
                      message={`Copie réussie de " ${copiedText === "location.mail" ? "e-mail" : copiedText
                        } "`}
                      action={
                        <React.Fragment>
                          <Button
                            color="secondary"
                            size="small"
                            onClick={handleCloseSnackbar}
                          >
                            FERMER
                          </Button>
                          <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseSnackbar}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </React.Fragment>
                      }
                    />
                  </div>
                  <h2 className="titreTableau">Liste des factures envoyés</h2>
                  <div>
                    <ClientStyle.StyledPaper>
                      <div className="center">
                        <DataTable
                          value={factures}
                          paginator
                          rows={10}
                          rowsPerPageOptions={[5, 8, 10, 25, 50, 500]}
                          tableStyle={{ minWidth: "13rem" }}
                          rowClassName={() => "custom-row-class"}
                          columnSeparator=" "
                          paginatorTemplate={
                            "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                          }
                          paginatorRight={
                            <span style={{ margin: "10px", fontWeight: "bold" }}>
                              Résultats : {factures.length}
                            </span>
                          }
                        >
                          <Column
                            field="numFact"
                            header="Numéro facture"
                            style={{ width: "20%" }}

                            body={(rowData) => `${rowData.numFact} `}
                          ></Column>
                          <Column
                            className="loc-column-content"
                            field="idClient"
                            header="Client"
                            body={(rowData) => (
                              <Tooltip
                                title={
                                  <div>
                                    {rowData.mail !== "" && (
                                      <div
                                        style={{
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        <span>
                                          <ContentCopyIcon
                                            style={{
                                              cursor: "pointer",
                                              padding: "2px",
                                            }}
                                            fontSize="small"
                                            onClick={() =>
                                              copyToClipboard(rowData.mail)
                                            }
                                          />
                                          <span className="text-sm ">
                                            {rowData.mail}
                                          </span>
                                        </span>
                                      </div>
                                    )}
                                    {rowData.mail2 !== "" && (
                                      <div
                                        style={{
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        <span className=" ">
                                          <ContentCopyIcon
                                            style={{
                                              cursor: "pointer",
                                              padding: "2px",
                                            }}
                                            fontSize="small"
                                            onClick={() =>
                                              copyToClipboard(rowData.mail2)
                                            }
                                          />
                                          {rowData.mail2}
                                        </span>
                                      </div>
                                    )}

                                    {rowData.num1 !== "" && (
                                      <div
                                        style={{
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        <span className=" ">
                                          <ContentCopyIcon
                                            style={{
                                              cursor: "pointer",
                                              padding: "2px",
                                            }}
                                            fontSize="small"
                                            onClick={() =>
                                              copyToClipboard(rowData.num1)
                                            }
                                          />
                                          {rowData.num1}
                                        </span>
                                      </div>
                                    )}

                                    {rowData.num2 !== "" && (
                                      <div
                                        style={{
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        <span className=" ">
                                          <ContentCopyIcon
                                            style={{
                                              cursor: "pointer",
                                              padding: "2px",
                                            }}
                                            fontSize="small"
                                            onClick={() =>
                                              copyToClipboard(rowData.num2)
                                            }
                                          />
                                          {rowData.num2}{" "}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                }
                                arrow
                              >
                                <span className="loc-column-content1">
                                  {rowData.idClient}- {rowData.nom}
                                </span>
                              </Tooltip>
                            )}
                          ></Column>
                          <Column
                            field="numFact"
                            header="Numéro Paiement"
                            style={{ width: "20%" }}

                            body={(rowData) => `${rowData.typeFact == "FP" ? (rowData.idPaiement) : ("")} `}
                          ></Column>




                          {/* <Column
                        field="Stockage"
                        header="Date début/fin"
                        style={{ width: "20%" }}
                        body={(rowData) => (
                          <span style={{ width: "25vw" }}>
                            D: {formattedDate(rowData.dateDebut)} <br />
                            F: {formattedDate(rowData.dateFin)}
                          </span>
                        )}
                      ></Column> */}
                          {localStorage.getItem("pr") === "sad" ||
                            localStorage.getItem("pr") === "st" ||
                            localStorage.getItem("pr") === "ad" ? (
                            <Column
                              header="Actions"
                              style={{ width: "10vw" }}
                              body={(rowData) => (
                                <>
                                  <ModeEditOutlineIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setIdPSelected(rowData.numFact);
                                      // setRescentRgl(rowData.idPaiement);
                                      setModifierFct(true);
                                    }}
                                  />
                                  <DeleteIcon
                                    onClick={() => {
                                      setIdPSelected(rowData.numFact);
                                      setSuprFct(true);
                                      // setRescentRgl("");
                                    }}
                                    style={{
                                      marginLeft: "10px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </>
                              )}
                            ></Column>
                          ) : null}
                        </DataTable>

                      </div>
                    </ClientStyle.StyledPaper>
                  </div>
                </div>
              </div> <div className="droiteStock ">
                <Box
                  sx={{
                    marginLeft: {
                      xs: "10px", // Pour les écrans mobiles (extra small)
                      sm: "10px", // Pour les écrans de taille moyenne (small)
                      md: "10px", // Pour les écrans de taille moyenne (medium)
                      lg: "10px", // Pour les écrans larges (large)
                    },
                  }}
                  className="cente"
                >
                  <h2>FILTRE</h2>
                </Box>
                <SelectFacture
                  setFactures={setFactures}
                  afficheAjoutFact={afficheAjoutFact}
                  modifierFct={modifierFct}
                  suprFct={suprFct}
                />

              </div>
            </div>
          )}




        </Grid>

      </div>
    );
  };

  //***********************************FIN TABLEAU TABLEAU TABLEAU******************************* */

  // listerClient();

  return <div>{renderFactureList()} </div>;
};
