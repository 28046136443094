import React, { useState, useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip, Modal } from "@mui/material";
import ClientTooltip from "../nameTooltip";
import { AjoutFacture } from "../facture/facturePage/ajoutFacture";
import { ModifierFacture } from "../facture/facturePage/modifFacture";
import { AjoutReglement } from "../reglement/reglementPage/AjoutReglement";
import { ModifierContrat } from "../contrat/contratPage/modifContrat";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

import axios from 'axios';


const apiUrl = process.env.REACT_APP_API_URL;
export default function Tasklist() {
    const [factures, setFactures] = useState([]);
    const [paiement, setPaiement] = useState([]);
    const [copiedText, setCopiedText] = useState("");
    const [contrat, setContrat] = useState([]);
    const [afficheAjoutFact, setAfficheAjoutFact] = useState(false);
    const [ajoutReglement, setAjoutReglement] = useState(false);
    const [modifierCtr, setModifierCtr] = useState(false);
    const [rescentRgl, setRescentRgl] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [modifierFct, setModifierFct] = useState(false);
    const [idPSelected, setIdPSelected] = useState("");

    useEffect(() => {
        axios.get(`${apiUrl}/IHM/api/task.php?taskFact`)
            .then(response => {
                setFactures(response.data); // Mettre à jour les factures dans l'état
            })
            .catch(error => {
                console.error("Erreur lors du chargement des factures :", error);
            });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/IHM/api/task.php?taskRelancePaie`)
            .then(response => {
                setPaiement(response.data); // Mettre à jour les factures dans l'état
            })
            .catch(error => {
                console.error("Erreur lors du chargement des factures :", error);
            });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/IHM/api/task.php?taskContrat`)
            .then(response => {
                setContrat(response.data); // Mettre à jour les factures dans l'état
            })
            .catch(error => {
                console.error("Erreur lors du chargement des factures :", error);
            });
    }, []);

    const copyToClipboard = (text) => {
        if (text !== "") {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard
                    .writeText(text)
                    .then(() => {
                        setCopiedText(text);
                        setOpenSnackbar(true);
                    })
                    .catch((error) => {
                        console.error(
                            "Erreur lors de la copie dans le presse-papiers : ",
                            error
                        );
                    });
            } else {
                // Utiliser l'API de sélection de texte comme solution de secours
                const textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand("copy");
                document.body.removeChild(textArea);

                setCopiedText(text);
                setOpenSnackbar(true);
            }
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <div>
            <Modal
                open={afficheAjoutFact}
                disableBackdropClick={true}
                aria-describedby="modal-modal-description"
            >
                <AjoutFacture
                    setAfficheAjoutFact={setAfficheAjoutFact}
                    onClose={() => {
                        setAfficheAjoutFact(false);

                    }}
                />
            </Modal>
            <Modal
                open={ajoutReglement}
                disableBackdropClick={true}
                aria-describedby="modal-modal-description"
            >
                <AjoutReglement setAjoutReglement={setAjoutReglement}
                    onClose={() => {
                        setAjoutReglement(false);

                    }} />
            </Modal>

            <Modal
                open={modifierCtr}
                disableBackdropClick={true}
                aria-describedby="modal-modal-description"
            >
                <ModifierContrat
                    setModifierCtr={setModifierCtr}
                    idPSelected={idPSelected}
                />
            </Modal>


            <Modal
                open={modifierFct}
                disableBackdropClick={true}
                aria-describedby="modal-modal-description"
            >
                <ModifierFacture
                    setModifierFct={setModifierFct}
                    idPSelected={idPSelected}
                />
            </Modal>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message={`Copie réussie de " ${copiedText === "location.mail" ? "e-mail" : copiedText
                    } "`}
                action={
                    <React.Fragment>

                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseSnackbar}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />

            <h3> Listes des tâches</h3>
            {/*               $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                TABLEAU POUR LES FACTURES               $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                                */}

            <div className="card">

                <DataTable header={'Facture à envoyer'} value={factures} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} size={'small'} paginatorRight={
                    <span style={{ margin: "10px", fontWeight: "bold" }}>
                        Résultats : {factures.length}
                    </span>
                }>

                    <Column style={{ cursor: "pointer" }} field="nom" header="Client" body={(rowData) => (
                        <ClientTooltip
                            nom={rowData.nom}
                            mail={rowData.mail}
                            num1={rowData.num1}
                            copyToClipboard={copyToClipboard}
                        />
                    )} ></Column>
                    <Column field='idStockage' header='Box'></Column>

                   
                        <Column field="dateFin" header="Période" body={(rowData) => {
                        if (rowData.dateFin) {
                            const date = new Date(rowData.dateFin);

                            const day = String(date.getDate()).padStart(2, '0');
                            const month = String(date.getMonth() + 1).padStart(2, '0');
                            const year = date.getFullYear();
                            return `${day}/${month}/${year}`;
                        }
                        }}></Column>
                    

                    <Column header='Actions' body={
                        <div>
                            <Button
                                label="Facture"
                                icon="pi pi-plus"
                                severity="info"
                                style={{
                                    backgroundColor: '#146C94',
                                    flexShrink: 0 // Prevents button from shrinking
                                }}
                                onClick={() => {
                                    setAfficheAjoutFact(true);
                                }}

                            />
                        </div>

                    }>


                    </Column>

                </DataTable>
            </div>
            <Divider />

            {/*               $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                TABLEAU POUR LES PAIEMENTS               $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                                */}
            <div className='card'>
                <DataTable header={'Relances paiements'} value={paiement} size={'small'} paginator rows={15} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} paginatorRight={
                    <span style={{ margin: "10px", fontWeight: "bold" }}>
                        Résultats : {paiement.length}
                    </span>
                }>
                    <Column style={{ cursor: "pointer" }} field="numFact" header="Ref" body={(rowData) => (

                        <div onClick={() => copyToClipboard(rowData.numFact)}>
                            <Tooltip title={
                                <p> Cliquer pour copier</p>
                            } arrow>
                                {rowData.numFact}
                            </Tooltip>

                        </div>
                    )}></Column>
                    <Column field="nom" style={{ cursor: "pointer" }} header="Client" body={(rowData) => (
                        <ClientTooltip
                            nom={rowData.nom}
                            mail={rowData.mail}
                            num1={rowData.num1}
                            copyToClipboard={copyToClipboard}
                        />
                    )} ></Column>
                    <Column field='idStockage' header='Box'></Column>

                    <Column
                        field="dateT"
                        body={(rowData) => {

                            const date = new Date(rowData.dateT);

                            const day = String(date.getDate()).padStart(2, '0');
                            const month = String(date.getMonth() + 1).padStart(2, '0');
                            const year = date.getFullYear();
                            return `${day}/${month}/${year}`;
                        }}
                        header="Date d'envoi"
                    ></Column>
                    <Column
                        field="tarif"
                        body={(rowData) => {

                            const valeur = rowData ? rowData.tarif : 'N/A';
                            return `${valeur} €`;
                        }}
                        header="Montant"
                    ></Column>

                    <Column field='note' header='Note'></Column>

                    <Column header='Actions' body={(rowData) =>

                        <div style={{ display: "flex", }}>


                            <Button
                                icon="pi pi-pencil"
                                severity="contrast"
                                style={{
                                    backgroundColor: '#969696',
                                }}
                                onClick={() => {
                                    setIdPSelected(rowData.numFact);
                                    // setRescentRgl(rowData.idPaiement);
                                    setModifierFct(true);
                                }}
                            outlined
                            text
                            rounded
                            />
                            <Button
                                label="Facture"
                                icon="pi pi-plus"
                                severity="info"
                                style={{
                                    backgroundColor: '#146C94',
                                }}
                                onClick={() => {
                                    setAfficheAjoutFact(true);
                                }}
                            />

                        </div>


                    }></Column>


                </DataTable>
            </div>
            <Divider />
            {/*               $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                TABLEAU POUR LES CONTRATS               $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                                */}

            <div className='card'>
                <DataTable header={'Relances contrat'} value={contrat} size={'small'} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} paginatorRight={
                    <span style={{ margin: "10px", fontWeight: "bold" }}>
                        Résultats : {contrat.length}
                    </span>
                }>
                    <Column field="idContrat" header="ID Contrat"></Column>
                    <Column style={{ cursor: "pointer" }} field="nom" header="Client" body={(rowData) => (
                        <ClientTooltip
                            nom={rowData.nom}
                            mail={rowData.mail}
                            num1={rowData.num1}
                            copyToClipboard={copyToClipboard}
                        />
                    )} ></Column>
                    <Column field="idStockage" header="ID Stock"></Column>
                    <Column field="dateContrat" body={(rowData) => {

                        const date = new Date(rowData.dateContrat);

                        const day = String(date.getDate()).padStart(2, '0');
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const year = date.getFullYear();
                        return `${day}/${month}/${year}`;
                    }} header="Date du contrat"></Column>

                    <Column field="typeContrat" header="Etat du contrat"></Column>
                    <Column header='Actions' body={(rowData) =>
                        <div>
                            <Button
                                label="Contrat"
                                icon="pi pi-pencil"
                                severity="info"
                                style={{
                                    backgroundColor: '#146C94',
                                    flexShrink: 0 // Prevents button from shrinking
                                }}
                                onClick={() => {
                                    setIdPSelected(rowData.idContrat);
                                    setRescentRgl(rowData.idContrat);
                                    setModifierCtr(true);
                                }}
                            />
                        </div>
                    }>
                    </Column>
                </DataTable>
            </div>
        </div >
    );
}
