import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/NavbarGauche.css";

function NavbarGauche() {
  const locationGauche = useLocation();

  return (
    <div className="navbarGauche">
      {/* //on a besoin de ceci */}
      <div className="lien"></div>
      {/* //on a besoin de ceci */}
      <div className="divLink">
        <Link
          to="/"
          className={locationGauche.pathname === "/" ? "activeLink" : "ty"}
        >
          <b>Tableau de bord</b>
        </Link>
      </div>
      <div className="divLink">
        {" "}
        <Link
          to="/stockage"
          className={
            locationGauche.pathname === "/stockage" ? "activeLink" : "ty"
          }
        >
          <b>Plan du site</b>
        </Link>
      </div>{" "}
      <div className="divLink">
        <Link
          to="/client"
          className={
            locationGauche.pathname === "/client" ? "activeLink" : "ty"
          }
        >
          <b>Clients</b>
        </Link>
      </div>
      <div className="divLink">
        <Link
          to="/location"
          className={
            locationGauche.pathname === "/location" ? "activeLink" : "ty"
          }
        >
          <b>Locations</b>
        </Link>
      </div>
      <div className="divLink">
        <Link
          to="/reglementListe"
          className={
            locationGauche.pathname === "/reglementListe" ? "activeLink" : "ty"
          }
        >
          <b>Paiements</b>
        </Link>
      </div>
      {/* <div className="divLink">
        <Link
          to="/facture"
          className={
            locationGauche.pathname === "/facture" ? "activeLink" : "ty"
          }
        >
          <b>Facture</b>
        </Link>
      </div> */}
    </div>
  );
}

export default NavbarGauche;
