import React from "react";
import "../../location/locationStyle/ajoutLocation.css";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { addMonths, format, parseISO } from "date-fns";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers";
import { ToastContainer, toast } from "react-toastify";
import {AjoutReglement} from "../../reglement/reglementPage/AjoutReglement";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@mui/material";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CancelIcon from "@mui/icons-material/Cancel";
import { TextField } from "@mui/material";

export const AjoutFacture = ({ setAfficheAjoutFact }) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const today = new Date();
  const formattedDate = String(today.getDate()).padStart(2, '0')+String(today.getMonth() + 1).padStart(2, '0')+ today.getFullYear();



  const [isSubmitting, setIsSubmitting] = useState(false);
  const [etatInput, setEtatInput] = useState(true);
  const [locationsData, setLocationsData] = useState([]);

  const [newStockage, setNewStockage] = useState("");
  const [idLocation, setIdLocation] = useState("");
  const [idClient, setIdClient] = useState(""); 
  const [idSearch, setIdSearch] = useState("");
  const [nomCompl, setNomCompl] = useState("");
  const [num, setNum] = useState("");
  const [mail, setMail] = useState("");
  const [adresse, setAdresse] = useState("");
  const [numFact, setNumFact] = useState(formattedDate+'-');
  const [numPaie, setNumpaie] = useState("");
  const [typeFact, setTypeFact] = React.useState("FAP");
  const [dateDebut, setDateDebut] = useState(null);
  const [dateFin, setDateFin] = useState(null);
  const [dateEnv, setDateEnv] = useState(null);
  const [labelDate, setLabelDate] = useState("Date d'envoie FAP");
  const [labelLoyer, setLabelLoyer] = useState("Loyer à payer");
  const [labelButton, setLabelButton] = useState("Enregistrer FAP");
  const currentDate = new Date().toISOString().slice(0, 10);
  const [datePaie, setDatePaie] = useState(currentDate);
  const [tarif, setTarif] = useState("");
  const [idStockage, setIdStockage] = useState("");
  const [idClientAlefa, setIdClientAlefa] = useState("");
  const searchTextFieldRef = useRef(null);
  useEffect(() => {
    if (searchTextFieldRef.current) {
      searchTextFieldRef.current.focus();
    }
  }, []);
  const [modePaiement, setModePaiement] = useState("");
  const handleModePaiementChange = (e) => {
    setModePaiement(e.target.value);
  };

  useEffect(() => {
    if (typeFact === "FAP") {
      setLabelDate("Date d'envoie FAP");
      setLabelLoyer("Loyer à payer");
      setLabelButton("Enregistrer FAP");
    } else  if (typeFact === "FP"){
      setLabelButton("Enregistrer FP");
      setLabelDate("Date d'envoie FP");
      setLabelLoyer("Loyer payé");
    } else{
      setLabelButton("Enregistrer EC");
      setLabelDate("Date d'envoie EC");
      setLabelLoyer("Loyer à payé");
    }
  }, [typeFact]);




  
  const [etat, setEtat] = useState("");
  const [situation, setSituation] = useState("");
  const [note, setNote] = useState("");
  const label = note === "" ? "Note" : "";

  //recuperer les info des stockages libre

  const handleChange = (event) => {
    setTypeFact(event.target.value);
  };
  const handleChangeDate = (date, setDate) => {
    const dateko = new Date(date);
    const year = dateko.getFullYear();
    const month = dateko.getMonth() + 1; // Les mois sont indexés de 0 à 11, donc on ajoute 1
    const day = dateko.getDate();

    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    console.log(formattedDate, "tsy ay tony");
    setDate(formattedDate);
  };

  const handleChangeDateDebut = (date) => {
    handleChangeDate(date, setDateDebut);
  };

  const handleChangeDateFin = (date) => {
    handleChangeDate(date, setDateFin);
  };
  const handleChangeDateEnv = (date) => {
    handleChangeDate(date, setDateEnv);
  };

  const [erreur, setErreur] = useState(false);

  const handleValeurPaieChange = (e) => {
    const inputValue = e.target.value;

    // Vérifie si l'entrée est un nombre positif
    if (/^[+]?\d*\.?\d*$/.test(inputValue) || inputValue === "") {
      setTarif(inputValue);
      setErreur(false);
    } else {
      setErreur(true);
    }
  };
  const inputStyle = {
    fontSize: "20px", // Modifiez la taille de la police selon vos besoins
  };
  const inputLabelStyle = {
    fontWeight: "bold",
  };
  const afficheNom = () => {
    if (/^\s*$/.test(idSearch)) {
    } else {
      axios
        .get(`${apiUrl}IHM/api/?searchLoc=${idSearch}`)

        .then((response) => {
          setLocationsData(response.data);

          if (response.data.length > 0) {
            infoLocation(response.data[0].idClient);
            //const nomCompl = `${response.data[0].nom} ${response.data[0].prenom}`;
            const adresse = `${response.data[0].ville} - ${response.data[0].codePostal} - ${response.data[0].adresse}`;
            const num = `${response.data[0].num1} - ${response.data[0].num2}`;
            setIdClientAlefa(response.data[0].idClient);
            setIdClient(response.data[0].idClient);
            setNomCompl(response.data[0].nom);
            setAdresse(adresse);
            setNum(num);
            setMail(response.data[0].mail);
          } else {
            toast.warn("Client non trouvé");

            setEtatInput(true);
            viderChamp();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const infoLocation = (idCli) => {
    axios
      .post(`${apiUrl}IHM/api/paiement.php?idCli=${idCli}`)
      .then(async (response) => {
        setEtatInput(false);
        console.log(response);
        const locationsData = response.data;
        setIdStockage(locationsData[0].idStockage);
        setIdLocation(locationsData[0].idLocation)
        setTarif(locationsData[0].loyer);
        setEtatInput(false);
      })
      .catch((error) => {
        setEtatInput(true);
        console.error("error");
        console.log("t6 lty ah ,t6");
        toast.warn("ce client n'a pas de location en cours");
      });
  };
  const viderChamp = () => {
    setEtatInput(true);
    setNomCompl("");
    setAdresse("");
    setNum("");
    setMail("");
    setTarif("");
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      afficheNom(idClient);
    }
  };

  const ajoutFact = async (e) => {
    e.preventDefault();

    if (dateDebut === null || dateFin === null) {
      toast.warn("Date limite non rempli");

      return; // Arrêter la soumission du formulaire
    }
    if (dateEnv === null) {
      toast.warn("Date d'envoie non rempli");

      return;
    }
    // if ((numPaie === "" ||numPaie === "0") && typeFact ==="FP") {
    //   toast.warn("Veuillez verifier le numéro de paiement");

    //   return; // Arrêter la soumission du formulaire
    // }

    { if (typeFact==="FP"){
      
       if (modePaiement === "") {
      toast.warn("Veuillez verifier le champ mode de paiement");

      return; // Arrêter la soumission du formulaire
    }
      }
      setEtatInput(true);
      const data = {
        numFact: numFact,
        numPaie: numPaie,
        idClient: idClient,
        idLocation: idLocation,
        idStockage: idStockage,
        dateDebut: dateDebut,
        dateFin: dateFin,
        dateEnv: dateEnv,
        
        datePaie: (typeFact === "FAP") ? null : datePaie,
        tarif: tarif,
        typeFact: typeFact,
        note: note,
        typePaie:(typeFact === "FAP") ? null :modePaiement,
      };

      try {
        await axios
          .post(`${apiUrl}IHM/api/facture.php`, data)
 
          .then((response) => {
            if (typeFact==="FP"){ 
             ajoutReglem();}

            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
   
    
    setAfficheAjoutFact(false);
  };
  const [selectedDatePaie, setSelectedDatePaie] = useState(dayjs(datePaie));

  const handleDateChangePaie = (date) => {
    const dateko = new Date(date);
    const year = dateko.getFullYear();
    const month = dateko.getMonth() + 1; // Les mois sont indexés de 0 à 11, donc ajoutez 1
    const day = dateko.getDate();

    // Créez une chaîne de date au format "YYYY-MM-DD"
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    console.log(formattedDate, "tsy ay tony");
    setDatePaie(formattedDate);
  };

  const ajoutReglem =  () => {
    
    console.log("ty le mampifangojy dateFinAjoutreglement", dateFin);
    if (dateDebut === "" || dateFin === "") {
      toast.warn("Date limite non rempli");

      return; // Arrêter la soumission du formulaire
    }

   
   
    if (idClient === "") {
      toast.warn("Veuillez entrer le nom ou numéro téléphone  du Client ");
    
      return;
    } else {
     
      const dataPaie = {
        idLocation: idLocation,
        idStockage: idStockage,
        typePaie: modePaiement,
        datePaie: datePaie,
        valeurPaie: tarif,
        dateDebut: dateDebut,
        dateFin: dateFin,
        numFact:numFact,
      };
    
        try {
        axios
          .put(`${apiUrl}IHM/api/paiement.php`, dataPaie)
          .then((response) => {
            console.log(response.data);
            console.log("paiement   REUSSIE");
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log("AIZAEEE error");
        alert("paiement NON REUSSIE");
      }
       
    }
    
  };

  return (
    <div className=" ">
      <div className="modalContainerLoc">
        {" "}
        <div className="container">
          {" "}
          <span className="titreEtBtnLoc">
            <CancelIcon
              style={{ cursor: "pointer" }}
              sx={{ marginLeft: "98%", fontSize: 40, marginTop: "-26px" }}
              onClick={() => setAfficheAjoutFact(false)}
            />
            <h3 className="titleLoc">Envoyer facture</h3>
          </span>
          <form className="form" onSubmit={ajoutFact}>
            <span class="parent-span">
              <span class="left-span">
                <div>
                  <TextField
                    id="standard-search"
                    label="Client"
                    type="text"
                    variant="standard"
                    value={idSearch}
                    onChange={(e) => {
                      setIdSearch(e.target.value);

                      viderChamp();
                    }}
                    style={{ width: "45vh" }}
                    inputRef={searchTextFieldRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon
                            color="primary"
                            onClick={() => afficheNom()}
                            style={{ cursor: "pointer" }}
                            fontSize="large"
                          />
                        </InputAdornment>
                      ),
                    }}
                    onKeyDown={handleKeyDown}
                  />
                  <br /><br />{" "}
                  <InputLabel id="select-label">Type de facture</InputLabel>
                  <Select
                    value={typeFact}
                    label=""
                    onChange={handleChange}
                    style={{
                      width: "100%",
                    }}
                  >
                    <MenuItem value="FAP">FAP (Facture à Payé)</MenuItem>
                    <MenuItem value="FP">FP (Facture Payé)</MenuItem>
                    
                  </Select>{" "}
                  <br />
                  <br />{" "}
                  <TextField
                    id="standard-search"
                    label="Numéro facture"
                    type="text"
                    variant="standard"
                    value={numFact}
                    onChange={(e) => setNumFact(e.target.value)}
                    style={{ width: "100%" }}
                    required
                  />
                  <br /> <br />
                  <DemoContainer components={["DatePicker"]}>
                    <DemoItem>
                      <DatePicker
                        format="DD-MM-YYYY"
                        label="Debut"
                        value={dateDebut}
                        onChange={handleChangeDateDebut}
                        style={{ width: "45vh" }}
                        required
                      />
                    </DemoItem>{" "}
                    <DemoItem>
                      <DatePicker
                        format="DD-MM-YYYY"
                        label="Fin"
                        value={dateFin}
                        onChange={handleChangeDateFin}
                        style={{ width: "45vh" }}
                        required
                      />
                    </DemoItem>
                  </DemoContainer>
                  <br />
                  <DemoContainer components={["DatePicker"]}>
                    <DemoItem>
                      <DatePicker
                        format="DD-MM-YYYY"
                        label={labelDate}
                        value={dateEnv}
                        onChange={handleChangeDateEnv}
                        style={{ width: "100vh" }}
                        required
                      />
                    </DemoItem>{" "}
                  </DemoContainer>
                   <br /> 
                   <>
                    {typeFact === "FP" && (
                      <TextField
                        id="standard-search"
                        label="Numéro Paiement (laisser vide si pas de paiement)"
                        type="text"
                        variant="standard"
                        value={numPaie}
                        onChange={(e) => setNumpaie(e.target.value)}
                        style={{ width: "100%" }}
                       
                      />
                    )}
                  </>
                  <br /> <br /> 
                  <TextField
                    id="standard-search"
                    label={
                      <span style={inputLabelStyle}>{labelLoyer}</span> // Appliquer le style bold ici
                    }
                    type="text"
                    variant="standard"
                    value={tarif}
                    onChange={handleValeurPaieChange}
                    error={erreur}
                    helperText={erreur ? "Entrez un nombre positif" : ""}
                    style={{ width: "45vh", overflowY: "hidden" }}
                    InputProps={{ style: inputStyle }}
                    required
                  />  <br />   <br /> 



                 {typeFact === "FP" &&
               ( <><FormControl variant="standard" fullWidth>
                    <InputLabel id="modePaiement-label">
                      Mode de Paiement
                    </InputLabel>

                    <Select
                      labelId="modePaiement-label"
                      id="modePaiement"
                      value={modePaiement}
                      onChange={handleModePaiementChange}
                      disabled={etatInput}
                    >
                      <MenuItem value="vir">Virement bancaire</MenuItem>
                      <MenuItem value="espc">Espèce</MenuItem>
                      <MenuItem value="cBl">carte bleu</MenuItem>
                      <MenuItem value="cq">Chèque</MenuItem>
                      <MenuItem value="ABOcBl">ABO carte bleu</MenuItem>
                    </Select>
                  </FormControl>  <br />   <br /> 
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      format="DD-MM-YYYY"
                      handleDateChangePaie
                      label="Date de paiement"
                      value={selectedDatePaie}
                      onChange={handleDateChangePaie}
                      style={{ display: "none" }}
                      disabled={etatInput}
                    />
                  </DemoContainer>
                  
               </> ) }    
                </div>
              </span>
              <span class="right-span">
                <TextField
                  id="standard-search"
                  label="Nom  "
                  type="text"
                  variant="standard"
                  value={`${idClient} - ${nomCompl}`}
                  disabled
                />
                <br />
                <TextField
                  id="standard-search"
                  label="Mail"
                  type="text"
                  variant="standard"
                  value={mail}
                  disabled
                />
                <br />
                <TextField
                  id="standard-search"
                  label="Numéro  "
                  type="text"
                  variant="standard"
                  value={num}
                  disabled
                />
                <br />
                <TextField
                  id="standard-search"
                  label="Adresse"
                  type="text"
                  variant="standard"
                  value={adresse}
                  disabled
                />{" "}
                <br />
                <TextField
                  id="standard-search"
                  label="Stockage"
                  type="text"
                  variant="standard"
                  value={idStockage}
                  disabled
                />
                <br />
                <br /> <br />
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <InputLabel> {label}</InputLabel>
                  <TextareaAutosize
                    variant="standard"
                    aria-label={label}
                    minRows={3} // Définissez le nombre minimum de lignes
                    maxRows={5} // Définissez le nombre maximum de lignes
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </span>
            </span>
            <Button
              className="myBouton"
              variant="contained"
              type="submit"
              sx={{ marginLeft: "38%", width: "auto" }}
              disabled={etatInput}
            >
              {labelButton}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
