import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../client/ClientStyle/client.css";
import "../locationStyle/locationStockage.css";
import * as ClientStyle from "../../client/ClientStyle/clientStyle";
import Button from "@mui/material/Button";
import { InfoLocation } from "./infoLocation";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import updateimg from "../../../assets/edit-512.webp";
import deleteimg from "../../../assets/supr.jpg";
import { makeStyles } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonGroup from "@mui/material/ButtonGroup";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

export const SelectRetard = ({
  setLocations,
  setFiltre,
  filtre,
  setIdSearch,
  idSearch,
  afficherAjoutLoc,
  modifierLocation,
  suprLoc,
  limRetard,
  setLimRetard,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const searchTextFieldRef = useRef(null);
  useEffect(() => {
    if (searchTextFieldRef.current) {
      searchTextFieldRef.current.focus();
    }
    recupererListeLoc("");
  }, []);

  // const [selectedClient, setSelectedClient] = useState({
  //   idClient: "",
  // });

  useEffect(() => {
    recupererListeLoc(idSearch);
  }, [filtre, afficherAjoutLoc, modifierLocation, suprLoc, limRetard]);

  const recupererListeLoc = (search) => {

    if (filtre === "") {
    } else if (filtre === "tout") {
      try {
        axios
          .get(`${apiUrl}IHM/api/location.php?searchLoc=${search}`)

          .then((response) => {
            const locationData = response.data;
            setLocations(locationData);
          });
      } catch (error) {
        if (error.response) {
          console.error("Erreur de réponse du serveur :", error.response.data);
        } else if (error.request) {
          console.error("Pas de réponse du serveur :", error.request);
        } else {
          console.error("Erreur lors de la requête :", error.message);
        }
      }
    } else if (filtre === "tard") {
      axios
        //retard Simple

        .get(
          `${apiUrl}IHM/api/retard.php?retardSpl=${filtre}&searchLoc=${search}&limRetard=${limRetard}`
        )
        .then((response) => {
          const retardsData = response.data;
          setLocations(retardsData);
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (filtre === "enCours") {
      axios
        //Locations encours

        .get(
          `${apiUrl}IHM/api/retard.php?filtreEncours=${filtre}&searchLoc=${search}`
        )
        .then((response) => {
          const retardsData = response.data;
          setLocations(retardsData);
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (filtre === "enRegle") {
      axios
          .get(`${apiUrl}IHM/api/retard.php?filtreEnRegle=${filtre}&searchLoc=${search}`)
          .then((response) => {
              const retardsData = response.data;
  
              // Assurez-vous que les données sont un tableau
              if (Array.isArray(retardsData)) {
                  setLocations(retardsData);
                  console.log(retardsData);
              } else {
                  console.error("Données retournées ne sont pas un tableau", retardsData);
              }
          })
          .catch((error) => {
              console.error(error);
          });
  }
   else {
      axios
        //tardE et tardR (entrée sans paiement || réservé sans paiement)

        .get(
          `${apiUrl}IHM/api/retard.php?filtre=${filtre}&searchLoc=${search}&limRetard=${limRetard}`
        )
        .then((response) => {
          const retardsData = response.data;
          setLocations(retardsData);
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
      console.log("entrée");
    }
  };

  //*************************************************************TABLEAU TABLEAU TABLEAU******************************************************** */

  const handleChange = (event) => {
    setFiltre(event.target.value);
  };

  const [erreur, setErreur] = useState(false);

  const handleLimRetardChange = (e) => {
    const inputValue = e.target.value;

    // Vérifie si l'entrée est un nombre positif
    if (/^[+]?\d+(\.\d+)?$/.test(inputValue) || inputValue === "") {
      setLimRetard(inputValue);
      setErreur(false);
    } else {
      setErreur(true);
    }
  };

  const renderRetardList = () => {
    return (
      <div className=" ">
        <div className=" ">
          <div className=" ">
            <Box
              style={{
                width: "100%",

                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                id="standard-search"
                label="Client / Box "
                type="text"
                variant="standard"
                value={idSearch}
                onChange={(e) => {
                  setIdSearch(e.target.value.trimStart());
                  recupererListeLoc(e.target.value);
                  // viderChamp();
                }}
                style={{ width: "100%", marginRight: "10px" }}
                inputRef={searchTextFieldRef}
              />
              <br /> <br />
              <br />
              <FormControl
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {" "}
                <InputLabel id="select-label">Filtre</InputLabel>
                <Select
                  value={filtre}
                  label="Filtre"
                  onChange={handleChange}
                  style={{
                    width:
                      filtre === "tard" ||
                      filtre === "tardR" ||
                      filtre === "tardE"
                        ? "60%"
                        : "100%",
                  }}
                >
                  <MenuItem value="enCours">En cours</MenuItem>
                  <MenuItem value="tout">Tout</MenuItem>
                  <MenuItem value="tard">Retard</MenuItem>
                  <MenuItem value="tardR">Reservée (sans paiement)</MenuItem>
                  <MenuItem value="tardE">Entrée (sans paiement)</MenuItem>

                  <MenuItem value="enRegle">Règlementaire</MenuItem>
                </Select>
                {filtre === "tard" ||
                filtre === "tardR" ||
                filtre === "tardE" ? (
                  <TextField
                    id="standard-search"
                    label="Limite retard"
                    type="text"
                    variant="standard"
                    style={{ width: "33%", marginLeft: "10px" }}
                    value={limRetard}
                    onChange={handleLimRetardChange}
                    error={erreur}
                    helperText={erreur ? "Entrez un nombre positif" : ""}
                  />
                ) : null}
              </FormControl>
            </Box>
          </div>
        </div>
      </div>
    );
  };

  //***********************************FIN TABLEAU TABLEAU TABLEAU******************************* */

  return <div>{renderRetardList()}</div>;
};
