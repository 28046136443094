import Contenneurs from "./conteneur/conteneurPage/contenneurs";
import Etage from "./etage/etagePage/etage";
import Rees from "./RDC/RDCpage/rees";
import "./stockageStyle.css";
import { TextField, Button } from "@mui/material";
import axios from "axios";
import { useState, useEffect, useRef } from "react";

import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

export const RechercheStockageMob = ({
  search,
  setSearch,
  motRecherche,
  setMotRecherche,
  motRechercheClient,
  setMotRechercheClient,
  setValue
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [infoStock, setInfoStock] = useState([]);
  const [enCours, setEnCours] = useState("");
  const [etat, setEtat] = useState("");
  const [placesData, setPlacesData] = useState({});
  const [txtPDefaut, setTxtPDefaut] = useState(true);
  const searchTextFieldRef = useRef(null);
  const [vol, setVol] = useState(0);
  const [nom, setNom] = useState("");
  const [mail, setMail] = useState("");
  const [dateEntree, setDateEntree] = useState("");
  const [dateSortie, setDateSortie] = useState("");
  const [loyer, setLoyer] = useState(0);
  const [paieData, setPaieData] = useState([]);
  const [idStockDuClient, setIdStockDuClient] = useState("")
  const [placeRecup, setplaceRecup] = useState("B");
  const [totalStock, setTotalStock] = useState("");
  const [typeContrat, setTypeContrat] = useState("");
  const [dateContrat, setDateContrat] = useState("");
  const [numContrat, setNumContrat] = useState("");
  const [numFact, setNumFact] = useState("");
  const [dateFacture, setDateFacture] = useState("");
  const [typeFacture, setTypeFacture] = useState("");

  const [totVol, setTotVol] = useState("");
  useEffect(() => {
    axios.get(`${apiUrl}IHM/api/stockage.php`).then((response) => {
      const listeStockages = response.data;

      listeStockages.sort(
        (a, b) => a.place.localeCompare(b.place) || a.vol.localeCompare(b.vol)
      );

      const placesMap = {};
      listeStockages.forEach((item) => {
        if (!placesMap[item.place]) {
          placesMap[item.place] = {};
        }
        if (!placesMap[item.place][item.vol]) {
          placesMap[item.place][item.vol] = [item.idStockage];
        } else {
          placesMap[item.place][item.vol].push(item.idStockage);
        }
      });

      setPlacesData(placesMap);
    });
  }, []);
  useEffect(() => {
    handleChangePlace();
  }, [placeRecup]);


  const getPlaceLabel = (place) => {
    switch (place) {
      case "B":
        return "Rée de chaussée";
      case "H":
        return "Étage";
      default:
        return "Extérieur";
    }
  };

  const handleRecherche = () => {
    setMotRechercheClient("");
    if (motRecherche === "") {
      // Si le champ de recherche est vide, réinitialisez les styles et sortez de la fonction
      const divs = document.querySelectorAll(".stockRetard");
      divs.forEach((div) => {
        div.classList.remove("highlight-red");
      });
      return;
    }

    recInfoBox(motRecherche);
    // const regex = new RegExp(`\\b${motRecherche}\\b`, "i");// Utilisez une expression régulière pour correspondre au mot exact

    // const divs = document.querySelectorAll(".stockRetard");
    // divs.forEach((div) => {
    //   const texteDiv = div.textContent;
    //   //  recherche
    //   if (regex.test(texteDiv)) {
    //     // Si le texte de la div correspond à la recherche, appliquez le style
    //     div.classList.add("highlight-red");
    //     setTimeout(() => {
    //       div.classList.remove("highlight-red");
    //     }, 2500);

    //     // Calcul de la position et défilement de la page
    //     const windowHeight = window.innerHeight;
    //     const divTopOffset = div.getBoundingClientRect().top;
    //     const scrollPosition = divTopOffset - windowHeight / 2;

    //     window.scrollBy({
    //       top: scrollPosition,
    //       behavior: "smooth",
    //     });
    //     info();
    //   } else {

    //   }
    // });


  };


  function rechercherEtMettreEnEvidence(param) {
    const regex = new RegExp(`\\b${param}\\b`, "i"); // Utilisez une expression régulière pour correspondre au mot exact

    const divs = document.querySelectorAll(".stockRetard");
    divs.forEach((div) => {
      const texteDiv = div.textContent;
      // Recherche
      if (regex.test(texteDiv)) {
        // Si le texte de la div correspond à la recherche, appliquez le style
        div.classList.add("highlight-red");
        setTimeout(() => {
          div.classList.remove("highlight-red");
        }, 2500);

        // Calcul de la position et défilement de la page
        const windowHeight = window.innerHeight;
        const divTopOffset = div.getBoundingClientRect().top;
        const scrollPosition = divTopOffset - windowHeight / 2;

        window.scrollBy({
          top: scrollPosition,
          behavior: "smooth",
        });


      }
    });
  }

  const handleRechercheClient = () => {
    setMotRecherche("");
    if (motRechercheClient == "") { return; }
    recInfoStockClient(motRechercheClient);
  };

  const info = () => {
    setTxtPDefaut(false);

    // recInfoStock(motRecherche);
    // recInfoBox(motRecherche); 


  };
  useEffect(() => {
    if (search) {
      recInfoStock(motRecherche);
      setSearch(false);
    }
  }, [search]);
  //Emplacement par défaut de curseur
  useEffect(() => {
    if (searchTextFieldRef.current) {
      searchTextFieldRef.current.focus();
    }
  }, []);

  const handleKeyDown = (e) => {
    setInfoStock([]);
    setPaieData([]);
    setIdStockDuClient("");
    if (e.key === "Enter") {


      handleRecherche();
      setMotRechercheClient("");
    }
  };

  // recherche à partir du nom client
  const handleKeyDownClient = (e) => {
    setInfoStock([]);
    setPaieData([]);
    setIdStockDuClient("");
    if (e.key === "Enter") {
      handleRechercheClient();
      setMotRecherche("");
    }
  };
  const handleChangePlace = (e) => {
    if (placeRecup === "B") {
      setValue("1");
    } else if (placeRecup === "H") {
      setValue("2");
    } else {
      setValue("3");
    }
  };

  const recInfoBox = (param) => {
    try {
      axios

        .get(`${apiUrl}IHM/api/stockageRetard.php?idStockage=${param}`)
        .then((response) => {
          if (response.data && response.data[0] && response.data[0][0]) {

            setVol(response.data[0][0].vol);
            console.log("DataTEDAVIKO", response.data[0][0])
            setplaceRecup(response.data[0][0].place);
            recInfoStock(param);

            rechercherEtMettreEnEvidence(param);
          }
          else {
            return

          }
        });
    } catch (error) {
      setInfoStock([]);
      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };

  const recDernPaie = (param) => {
    try {
      setPaieData([]);
      axios

        .get(`${apiUrl}IHM/api/paiement.php?idLoc=${param}`)
        .then((response) => {
          if (response.data.length > 0) {
            setPaieData(response.data);
          }


        });
    } catch (error) {
      setInfoStock([]);
      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };
  const recInfoStock = (param) => {
    try {setDateContrat("");
    setTypeContrat("");
    setNumContrat("");
    setDateFacture("");
    setTypeFacture("");
    setNumFact("");

      setInfoStock([]);
      axios

        .get(`${apiUrl}IHM/api/stockageRetard.php?infoStock=${param}`)
        .then((response) => {


          if (response.data.length > 0) {
            setInfoStock(response.data);
            console.log("hereeeeee111");
            console.log(infoStock);
            console.log("hereeeeee22", param, "hereeeeee22");
            setTotalStock(response.data[0].concatIdstockage);
            setVol(response.data[0].vol);
            setTotVol(response.data[0].totVolume);
            setDateEntree(response.data[0].dateEntree);
            setDateSortie(response.data[0].dateSortie);
            setEnCours(response.data[0].enCours);
            setLoyer(response.data[0].loyer);
            setNom(response.data[0].nom);
            setMail(response.data[0].mail);
            recDernPaie(response.data[0].idLocation);
            setTxtPDefaut(false);

            recContrat(response.data[0].idClient);
            recFacture(response.data[0].idClient);
          }

          else {
            console.log("makato 3");
            setTxtPDefaut(false);
          }
        });
    } catch (error) {

      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };

  // recherche à partir du nom client , alors que info() se fait quan on recherche un box
  const recInfoStockClient = (param) => {
    try {
      setDateContrat("");
      setTypeContrat("");
      setNumContrat("");
      setDateFacture("");
      setTypeFacture("");
      setNumFact("");
      setInfoStock([]);
      axios

        .get(`${apiUrl}IHM/api/stockageRetard.php?infoStockClient=${param}`)
        .then((response) => {
          setInfoStock(response.data);
          console.log("recherce par nomMMMM", response.data)
          if (response.data.length > 0) {
            setTotalStock(response.data[0].concatIdstockage);
            setVol(response.data[0].vol);
            setTotVol(response.data[0].totVolume);
            setDateEntree(response.data[0].dateEntree);
            setDateSortie(response.data[0].dateSortie);
            setEnCours(response.data[0].enCours);
            setLoyer(response.data[0].loyer);
            setNom(response.data[0].nom);
            setMail(response.data[0].mail);
            recDernPaie(response.data[0].idLocation);

            setIdStockDuClient(response.data[0].idStockage);
            setTxtPDefaut(false);
            // recupere l'information du box du client
            recInfoBox(response.data[0].idStockage);
            rechercherEtMettreEnEvidence(response.data[0].idStockage);

            recContrat(response.data[0].idClient);
            recFacture(response.data[0].idClient);
          }else{
              
            setTxtPDefaut(false);
            
          }


        });

    } catch (error) {
      setInfoStock([]);
      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };


  const formattedDate = (dateRecup) => {
    if (dateRecup === "0000-00-00" || dateRecup === "" || dateRecup === null) {
      return ``;
    }
    const date = new Date(dateRecup);
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth()}`
        : date.getMonth().toString();
    const year = date.getFullYear();
    return `${day}-${(parseInt(month) + 1)
      .toString()
      .padStart(2, "0")}-${year}`;
  };
  const recContrat= (param) => {
    try { 
      axios

        .get(`${apiUrl}IHM/api/contrat.php?idClientCtr=${param}`)
        .then((response) => { 
          if (response.data.length > 0) {
            setDateContrat(response.data[0].dateContrat);
            setTypeContrat(response.data[0].typeContrat);
            setNumContrat(response.data[0].idContrat);    
          }

          else {
            console.log("makato 3");
             
          }
        });
    } catch (error) {

      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };

  const recFacture= (param) => {
    try {

   
      axios

        .get(`${apiUrl}IHM/api/facture.php?idClientFct=${param}`)
        .then((response) => {


          if (response.data.length > 0) {
            setDateFacture(response.data[0].dateEnv);
            setTypeFacture(response.data[0].typeFact);
            setNumFact(response.data[0].numFact);
            
          }

          else {
            console.log("makato 3");
             
          }
        });
    } catch (error) {

      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };
  function mapTypeCtr(typeCtr) {
    switch (typeCtr) {
      case "CNS":
        return "Contrat non signé";
      case "CS":
        return "Contrat signé ";
      case "CE":
        return "Contrat envoyé";
      case "PDC":
        return "Pas de contrat"; 
      default:
        return typeCtr;
    }
  }
  return (
    <div className="">
      <div className="">
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "10px" }}>
          <TextField
            style={{ width: "45%" }}
            label="Box ou container"
            variant="standard"
            value={motRecherche}
            onChange={(e) => {
              setMotRecherche(e.target.value.trimStart());
              setTxtPDefaut(true);
              setInfoStock([]);
            }}
            inputRef={searchTextFieldRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon
                    color="primary"
                    onClick={handleRecherche}
                    style={{ cursor: "pointer" }}
                    fontSize="large"
                  />
                </InputAdornment>
              ),
            }}
            onKeyDown={handleKeyDown}
          />

          <TextField
            style={{ width: "45%" }}
            label="Client"
            variant="standard"
            value={motRechercheClient}
            onChange={(e) => {
              setMotRechercheClient(e.target.value.trimStart());
              setTxtPDefaut(true);
              setInfoStock([]);
            }}

            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon
                    color="primary"
                    onClick={handleRechercheClient}
                    style={{ cursor: "pointer" }}
                    fontSize="large"
                  />
                </InputAdornment>
              ),
            }}
            onKeyDown={handleKeyDownClient}
          />
        </div>
        {txtPDefaut ? (
          <div>
            <h4 style={{ marginLeft: "5%" }}>Liste des stockages libre:</h4>
            {Object.keys(placesData).map((place, placeIndex) => (
              <div key={placeIndex}>
                <h4 style={{ marginLeft: "5%" }}>{getPlaceLabel(place)} :</h4>
                {Object.keys(placesData[place]).map((volume, volIndex) => (
                  <div style={{ marginLeft: "8%" }} key={volIndex}>
                    {volume} m³ : {placesData[place][volume].join(", ")}
                    <br />
                  </div>
                ))}
              </div>
            ))}
          </div>
        ) : (
          <div>
            {infoStock.length > 0 ? (

              <div className="etat" >
                <span style={{ marginLeft: "20%" }}>
                  {enCours === "non" ? (
                    <>
                      <h3>Box: {motRecherche} - LIBRE</h3>  

                     
                      <h4 style={{ marginLeft: "20px" }}>
                        {" "}
                        Info du dernier location
                      </h4>
                    </>
                  ) : (
                    <h3>Box: {idStockDuClient == "" ? (motRecherche) : (idStockDuClient)} - OCCUPE</h3>
                  )}
                </span>
                <div style={{ display: "flex",   justifyContent: "space-between", margin: "10px" }}>
                <div>
                  <div>

                  </div>
                  {infoStock.length > 0 && (
                    <div style={{ marginLeft: "30px" }}>
                      Volume : {vol} m³ <br />
                      Client : {nom}
                      <br />
                      Mail : {mail}
                      <br />
                      Date d'entrée : {formattedDate(dateEntree)}
                      <br />
                      {/* Date de sortie : {formattedDate(dateSortie)}
                      <br /> */}
                      Loyer : {loyer} €
                      <br />
                    </div>
                  )}
                  {totalStock !== motRecherche && totalStock !== "" ? (
                    <div style={{ marginLeft: "30px" }}>
                      <br />
                      Tout les box : {totalStock}
                      <br />
                      Volume totale: {totVol} m³  
                    </div>
                  ) : (
                    ""
                  )}
                    <div style={{ marginLeft: "30px" }}>
                    <br />  <br />
                     Contrat n°{ numContrat} du {formattedDate(dateContrat)}  <br />
                     Type : {mapTypeCtr(typeContrat)}
                      <br />
                    
                    </div>
                </div>
                <div >
                  {paieData.length > 0 ? (
                    <div>  
                     <strong>  Dernier paiement: </strong>
                      {paieData.map((client) => (
                        <div key={client.idPaiement}>
                          <strong>
                            Paiement n° <span>{client.idPaiement}</span>
                          </strong>
                          <br />
                          Date : <span>
                            {formattedDate(client.datePaie)}
                          </span>{" "}
                          <br />
                          Somme: <span>{client.valeurPaie} €</span> (
                          {client.typePaie})
                          <br />P :{" "}
                          <span>
                            {formattedDate(client.dateDebut)} au{" "}
                            {formattedDate(client.dateFin)}
                          </span>{" "}
                          <br /> <br />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <>
                      <div style={{ marginLeft: "-50px" }}>
                       
                        Aucun paiement
                      </div>
                    </>
                  )}<div style={{ marginLeft: "0px" }}>
                  Facture n° {numFact} du {formattedDate(dateFacture)}  <br />
                 Type : {typeFacture}
                   <br />
                 
                 </div>
                </div> </div>
              </div>
            ) : (
              <div className="etat">
                <h3 style={{ marginLeft: "20%" }}>
                  Box {motRecherche}
                  {"   "} - {"   "}
                  LIBRE
                </h3>
                <br />
                Volume : {vol} m³ <br />
              </div>
            )}

            <br />
          </div>
        )}
      </div>
    </div>
  );
};
