import React from "react";
import "../ClientStyle/ajoutClient.css";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import { Toast } from "primereact/toast";
import {
  FormGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  TextField,
  FormControl,
  TextareaAutosize,
  InputLabel,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import "react-toastify/dist/ReactToastify.css";

import { createTheme } from "@mui/material/styles";

export const AjoutClient = ({ setVisible, setRescentCli, onClose }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const toast = useRef(null);
  const [idClient, setIdClient] = useState("");
  const [nom, setNom] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [adresse, setAdresse] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [ville, setVille] = useState("");
  const [num1, setNum1] = useState("");
  const [num2, setNum2] = useState("");
  const [mail, setMail] = useState("");
  const [mail2, setMail2] = useState("");
  const [note, setNote] = useState("");
  const searchTextFieldRef = useRef(null);
  useEffect(() => {
    if (searchTextFieldRef.current) {
      searchTextFieldRef.current.focus();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Vérifier si les champs nom et prenom ne sont pas vides
    if (!nom) {
      // Afficher un message d'erreur ou prendre d'autres mesures appropriées
      console.log("Le champ nom   ne peut pas être vide.");
      toast.current.show({
        severity: "success",
        summary: `Ajout du client ${nom} réussi`,
        detail: "Message Content",
        life: 3500,
      });
      return; // Arrêter la soumission du formulaire
    } else {
      const data = {
        idClient: idClient,

        nom: nom,
        adresse: adresse,
        codePostal: codePostal,
        ville: ville,
        num1: num1,
        num2: num2,
        mail: mail,
        mail2: mail2,
        note: note,
        user: localStorage.getItem("us"),
      };

      setIsSubmitting(true);
      try {
        await axios
          .put(`${apiUrl}IHM/api/`, data)
          .then((response) => {
            setRescentCli(response.data.lastInsertedId);
            console.log("okeyyyhoaaa", response.data.lastInsertedId);
            toast.current.show({
              severity: "success",
              summary: `Ajout du client ${nom} réussi`,
              detail: "Message Content",
              life: 3500,
            });
            setVisible(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log("AIZAEEE error");
      }

      setNom("");
      setAdresse("");
      setCodePostal("");
      setNum1("");
      setVille("");
      setNum2("");
      setMail("");
      setMail2("");
      setNote("");
    }

    if (typeof onClose === "function") {
      onClose(); // Appel de la fonction de rappel , manao mise à jour atsy
      console.log("OnClose");
    }
  };

  const handleNum1Change = (e) => {
    const inputValue = e.target.value.trimStart();

    // Utilisez une expression régulière pour vérifier si l'entrée est constituée de chiffres et/ou d'espaces
    if (/^[0-9+\s]*$/.test(inputValue)) {
      setNum1(inputValue);
    }
  };
  const handleNum2Change = (e) => {
    const inputValue = e.target.value.trimStart();

    // Utilisez une expression régulière pour vérifier si l'entrée est constituée de chiffres et/ou d'espaces
    if (/^[0-9+\s]*$/.test(inputValue)) {
      setNum2(inputValue);
    }
  };

  const handleMailChange = (e) => {
    const inputValue = e.target.value.trimStart();

    setMail(inputValue);
  };
  const handleMail2Change = (e) => {
    const inputValue = e.target.value.trimStart();

    setMail2(inputValue);
  };

  //cliquer bouton ajouter ou bouton entrée
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };
  return (
    <div>
      <div className="modalContainer">
        {" "}
        <Toast ref={toast} />
        <div className="flex items-center">
          <span className="ml-auto">
            {" "}
            <CancelIcon
              style={{ cursor: "pointer" }}
              sx={{ fontSize: 40 }}
              className="   mt-minus-52"
              onClick={() => {
                setVisible(false);
                if (typeof onClose === "function") {
                  onClose(); // Appel de la fonction de rappel
                  console.log("OnClose");
                }
              }}
            />
          </span>
        </div>
        <span className="titreEtBtn">
          <div className="w-5/6 mx-auto flex items-center justify-center">
            <h4 className="title font-bold text-lg md:text-2xl lg:text-2xl xl:text-2xl">
              Enregistrer un nouveau client
            </h4>
          </div>
        </span>
        <div className="containerAjClient">
          <form className="form" onSubmit={handleSubmit}>
            <br />
            <TextField
              style={{ width: "110%" }}
              id="standard-search"
              label="Nom"
              type="text"
              variant="standard"
              value={nom}
              inputRef={searchTextFieldRef}
              onChange={(e) => setNom(e.target.value)}
              required
            />
            <br />
            <br />
            <TextField
              id="standard-search"
              label="Adresse"
              type="text"
              variant="standard"
              value={adresse}
              onChange={(e) => setAdresse(e.target.value)}
              style={{ width: "110%" }}
            />
            <br />
            <br />
            <TextField
              id="standard-search"
              label="Code Postale"
              type="text"
              variant="standard"
              value={codePostal}
              onChange={(e) => setCodePostal(e.target.value)}
              style={{ width: "110%" }}
            />
            <br />
            <br />
            <TextField
              id="standard-search"
              label="Ville"
              type="text"
              variant="standard"
              value={ville}
              onChange={(e) => setVille(e.target.value)}
              style={{ width: "110%" }}
            />
            <br />
            <br />
            <TextField
              id="standard-search"
              label="Numéro tel1"
              type="text"
              variant="standard"
              value={num1}
              onChange={handleNum1Change}
              style={{ width: "110%" }}
            />
            <br />
            <br />
            <TextField
              id="standard-search"
              label="Numéro tel2"
              type="text"
              variant="standard"
              value={num2}
              onChange={handleNum2Change}
              style={{ width: "110%" }}
            />
            <br />
            <br />
            <TextField
              id="standard-search"
              label="Adresse mail"
              type="mail"
              variant="standard"
              value={mail}
              onChange={handleMailChange}
              style={{ width: "110%" }}
            />
            <br />
            <br />{" "}
            <TextField
              id="standard-search"
              label="Autre adresse mail"
              type="mail"
              variant="standard"
              value={mail2}
              onChange={handleMail2Change}
              style={{ width: "110%" }}
            />
            <br />
            <br />
            <FormControl variant="standard" style={{ width: "100%" }}>
              <InputLabel>Note</InputLabel>

              <TextareaAutosize
                variant="standard"
                minRows={2} // Définissez le nombre minimum de lignes
                maxRows={5} // Définissez le nombre maximum de lignes
                value={note}
                onChange={(e) => setNote(e.target.value)}
                style={{ width: "110%" }}
              />
            </FormControl>
            <br />
            <div>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ marginLeft: "40%" }}
                disabled={isSubmitting}
              >
                Ajouter
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
