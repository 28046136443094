import React from "react";
import "../locationStyle/ajoutLocation.css";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TextField, TextareaAutosize } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { ContentCutOutlined } from "@mui/icons-material";
import { format, isValid, parseISO } from "date-fns";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers";
import useMediaQuery from "@mui/material/useMediaQuery";
export const AjoutLocation = ({
  setAfficherAjoutLoc,
  afficherAjoutLoc,
  setIdInfoLoc,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [selectedValue, setSelectedValue] = useState([]); // State pour stocker les valeurs sélectionnées
  const [selectedId, setSelectedId] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [idLoc, setIdloc] = useState("");
  const [idLocation, setIdLocation] = useState("");
  const [idClient, setIdClient] = useState("");
  const [idClientAlefa, setIdClientAlefa] = useState("");
  const searchTextFieldRef = useRef(null);
  const [idStockage, setIdStockage] = useState("transformedValue");
  const [idStock, setIdStock] = useState("");
  const currentDate = new Date().toISOString().substr(0, 10);
  const [dateReservation, setDateReservation] = useState(currentDate);
  const [idStockageValues, setIdStockageValues] = useState([]);
  const [dateEntree, setDateEntree] = useState("2023-10-10");

  const [dateSortie, setDateSortie] = useState("");
  const [dateEntreePrevue, setDateEntreePrevue] = useState(null);

  const [loyer, setLoyer] = useState("");
  const [etat, setEtat] = useState("");
  const [situation, setSituation] = useState("");
  const [noteLoc, setNoteLoc] = useState("");
  const [etatInput, setEtatInput] = useState(true);
  const [etatNCli, setEtatNCli] = useState(false);
  const [volume, setVolume] = useState([]);
  const [locationsData, setLocationsData] = useState([]);
  //client

  const [nomCompl, setNomCompl] = useState("");
  const [num, setNum] = useState("");
  const [mail, setMail] = useState("");
  const [adresse, setAdresse] = useState("");
  //fin client
  const [sommeVolume, setSommeVolume] = useState(0);
  const [volStockageValues, setVolStockageValues] = useState([]);
  const [variable, setVariable] = useState("");
  const [valeur, setValeur] = useState("");
  const [prixM3, setPrixM3] = useState(12);
  const isMobilePortrait = useMediaQuery("(max-width:1207px)  ");
  //le volume afficherna
  const mettreAJour = (nouvelleValeur) => {
    setSommeVolume((sommeVolume) => sommeVolume + parseInt(nouvelleValeur));
    console.log("appel");
    setVariable((variable) => variable + nouvelleValeur + " m3 +");
    setValeur(variable);
  };
  //keyboard enter re ah
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // Empêcher la soumission du formulaire par défaut
      // e.preventDefault();

      afficheNom(idClient);
    }
  };

  const afficheNom = (id) => {
    if (/^\s*$/.test(id)) {
    } else {
      axios
        .get(`${apiUrl}IHM/api/?searchLoc=${id}`)
        .then((response) => {
          setLocationsData(response.data);

          if (response.data.length > 0) {
            //const nomCompl = `${response.data[0].nom} ${response.data[0].prenom}`;
            const adresse = `${response.data[0].ville} - ${response.data[0].codePostal} - ${response.data[0].adresse}`;
            const num = `${response.data[0].num1}  -  ${response.data[0].num2}`;
            const mail = `${response.data[0].mail}  -  ${response.data[0].mail2}`;
            setIdClientAlefa(response.data[0].idClient);
            setNomCompl(response.data[0].nom);
            setAdresse(adresse);
            setNum(num);
            setMail(mail);
            setEtatInput(false); //verifier si il y a encore un location encours
            verifLoc(response.data[0].idClient);
            setEtatNCli(true);
          } else {
            setNomCompl("");
            setAdresse("");
            setNum("");
            setMail("");
            //disable les autres  champs quand le Ncli change
            setEtatInput(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const verifLoc = (id) => {
    axios
      .get(`${apiUrl}IHM/api/location.php?idCli=${id}`)
      .then((response) => {
        if (response.data.length > 0) {
          console.log(response.data[0]);
          console.log("mbola misy anh");
          setIdloc(response.data[0].idLocation); // Mettre à jour idLoc de manière asynchrone

          setEtatInput(true); // pour bloquer l'ajout de location
        } else {
          console.log("okey, tout va bien, afaka manao ajout");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // Utiliser un effet secondaire pour afficher le toast lorsque idLoc est mis à jour
  };
  useEffect(() => {
    if (idLoc !== "") {
      toast.warn(`${nomCompl} a encore une location en cours n° ${idLoc}`);
      console.log("atotokony itoast be mitsy ah");
      setEtatInput(true);
    }
  }, [idLoc]);

  useEffect(() => {
    const recStockage = () => {
      axios
        .get(`${apiUrl}IHM/api/stockage.php`)
        .then((response) => {
          console.log(response.data);
          const idStockageData = response.data;
          const volStockageValues = idStockageData.map((item) => item.vol);
          const idStockageValues = idStockageData.map(
            (item) => item.idStockage
          );

          setVolStockageValues(volStockageValues);
          setIdStockageValues(idStockageValues);
        })
        .catch((error) => {
          console.error("Erreur lors de la requête Axios : ", error);
        });
    };

    recStockage();
    // Appelez la fonction pour effectuer la requête lorsque le composant est monté
  }, []);

  const ajoutLoc = async (e) => {
    e.preventDefault();

    if (selectedValue.length === 0) {
      toast.warn("Veuillez ajouter au moins un stockage à louer");

      return; // Arrêter la soumission du formulaire
    }

    if (loyer === "") {
      toast.warn("Veuillez entrer le loyer mensuel");

      return; // Arrêter la soumission du formulaire
    }
    if (nomCompl === "") {
      toast.warn("Numéro de client non éxistant");

      return; // Arrêter la soumission du formulaire
    } else {
      if (dateEntreePrevue === null) {
        toast.warn("Veilleez entrer la date d'entrée prévue ");
        return;
      } else {
        setEtatInput(true);
        saveLoc();
      }
    }
    setAfficherAjoutLoc(false);
  };
  //**************************MIX anle id selected******************************* */
  const saveLoc = async () => {
    const data = {
      idClientAlefa,
      selectedId, //ty id  stockage
      dateReservation,
      dateEntree,

      dateEntreePrevue,
      loyer,
      etat,
      situation,
      noteLoc,
      sumVol,
    };
    console.log(selectedId, "selectedId");

    //vers back,  enregistrer location
    try {
      axios
        .put(`${apiUrl}IHM/api/location.php`, data)
        .then((response) => {
          console.log(response.data);
          setIdInfoLoc(response.data.lastInsertedId);
          rendStockOccup();
          setAfficherAjoutLoc(false);

          
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("AIZAEEE error");
    }

    setIdLocation("");
    setIdClient("");
    setIdStockage("");
    setDateReservation("");

    setDateEntreePrevue("");

    setEtat("");
    setSituation("");
    setNoteLoc("");
  };

  const rendStockOccup = () => {
    try {
      axios
        .put(`${apiUrl}IHM/api/stockage.php?idStockage=${selectedId}`)
        .then((response) => {
          console.log(response.data);
          console.log("réussie rend stock occup");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("  error rend stock occup");
    }
  };

  const inputLabelStyle = {
    fontWeight: "bold",
  };
  const inputStyle = {
    fontSize: "20px", // Modifiez la taille de la police selon vos besoins
  };

  const idEtVolume = idStockageValues.map((id, index) => ({
    id: id,
    this: volStockageValues[index],
    label: `${id} - ${volStockageValues[index]} m³ `,
  }));

  //à afficher sur l'option , il faudrait que l'option dans l'autocomplete sera un tableau
  // 1 dimension car il y aura de conflit ,, ca marche mais avec des warnings , d'ou j'ai créé ce idEtVolumeLabels
  const idEtVolumeLabels = idEtVolume.map((option) => option.label);

  const sumVol = selectedValue.reduce((accumulator, currentValue) => {
    // Assurez-vous que currentValue.this est un nombre en le convertissant avec parseFloat
    const thisValue = parseFloat(currentValue.this);
    // Vérifiez si thisValue est un nombre valide
    if (!isNaN(thisValue)) {
      return accumulator + thisValue;
    } else {
      return accumulator;
    }
  }, 0);

  const [erreur, setErreur] = useState(false);

  const handleValeurPaieChange = (e) => {
    const inputValue = e.target.value;

    // Vérifie si l'entrée est un nombre positif
    if (/^[+]?\d*\.?\d*$/.test(inputValue) || inputValue === "") {
      setLoyer(inputValue);
      setErreur(false);
    } else {
      setErreur(true);
    }
  };

  //pour mettre par défaut le curseur sur le recherche
  useEffect(() => {
    if (searchTextFieldRef.current) {
      searchTextFieldRef.current.focus();
    }
  }, []);

  // array pour idStockage
  useEffect(() => {
    setSelectedId(selectedValue.map((item) => item.id));
    console.log(selectedId, "selectedId");
  }, [selectedValue]);
  const viderChamp = () => {
    setNomCompl("");
    setAdresse("");
    setNum("");
    setMail("");

    //disable les autres  champs quand le Ncli change
    setEtatInput(true);
  };

  const handleOptionSelect = (event, newValue) => {
    console.log("Newvalue", newValue);
    if (newValue) {
      const matchingRecords = newValue.map((value) =>
        idEtVolume.find((option) => option.label === value)
      );

      if (matchingRecords.length > 0) {
        console.log("Enregistrements correspondants dans idEtVolume :");
        console.log(matchingRecords);
        setSelectedValue(matchingRecords); // Enregistrez toutes les valeurs correspondantes
      } else {
        console.log("Aucun enregistrement correspondant dans idEtVolume.");
      }
    } else {
      console.log("newValue est vide.");
    }
    console.log("selectedValue", selectedValue);
  };
  const handleChangeEntrPrevue = (date) => {
    const dateko = new Date(date);
    const year = dateko.getFullYear();
    const month = dateko.getMonth() + 1; // Les mois sont indexés de 0 à 11, donc ajoutez 1
    const day = dateko.getDate();

    // Créez une chaîne de date au format "YYYY-MM-DD"
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    console.log(formattedDate, "tsy ay tony");
    setDateEntreePrevue(formattedDate);
  };
  return (
    <div className=" ">
      {!isMobilePortrait && (
        <div className="modalContainerLoc">
          <span className="titreEtBtnLoc">
            <CancelIcon
              style={{ cursor: "pointer" }}
              sx={{ marginLeft: "95%", fontSize: 40, marginTop: "-26px" }}
              onClick={() => setAfficherAjoutLoc(false)}
            />
            <h3 className="titleLoc">Enregistrer une nouvelle location</h3>
          </span>

          <div className="container">
            <form className="form" onSubmit={ajoutLoc}>
              <span class="parent-span">
                <span class="left-span">
                  <span style={{ alignItems: "flex" }}>
                    <TextField
                      id="standard-search"
                      label="Client"
                      type="text"
                      variant="standard"
                      value={idClient}
                      onChange={(e) => {
                        setIdClient(e.target.value);

                        viderChamp();
                      }}
                      style={{ width: "45vh" }}
                      inputRef={searchTextFieldRef}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon
                              color="primary"
                              onClick={() => afficheNom(idClient)}
                              style={{ cursor: "pointer" }}
                              fontSize="large"
                            />
                          </InputAdornment>
                        ),
                      }}
                      onKeyDown={handleKeyDown}
                    />
                    <br />
                    <br />
                    <br />
                    <Autocomplete
                      multiple
                      id="Id Stockage"
                      options={idEtVolumeLabels}
                      onChange={handleOptionSelect}
                      //getOptionLabel={(option) => option.label}
                      //value={selectedValue}
                      // getOptionSelected={(option, value) =>
                      //   option.id === value.id
                      // } // Comparaison basée sur l'ID

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="standard-search"
                          label="Id Stockage"
                          type="text"
                          variant="standard"
                          style={{ width: "45vh" }}
                        />
                      )}
                    />
                    <br />
                    <br />
                    <TextField
                      id="standard-search"
                      label={
                        <span style={inputLabelStyle}>Loyer (€)</span> // Appliquer le style bold ici
                      }
                      type="text"
                      variant="standard"
                      value={loyer}
                      onChange={handleValeurPaieChange}
                      error={erreur}
                      helperText={erreur ? "Entrez un nombre positif" : ""}
                      disabled={etatInput}
                      style={{ width: "45vh", overflowY: "hidden" }}
                      InputProps={{ style: inputStyle }}
                    />
                    <br />
                    <br />
                    {/* <TextField
                    id="standard-search"
                    label="Date d'entrée prévue"
                    type="date"
                    variant="standard"
                    //   value={format(new Date("06/10/10"), "dd-MM-yyyy")}

                    style={{ width: "45vh" }}
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Aligner le label à droite
                        width: "40vh", // Ajuster la largeur du label
                      },
                    }}
                    required
                    disabled={etatInput}
                  /> */}
                    <DemoContainer components={["DatePicker"]}>
                      <DemoItem>
                        <DatePicker
                          format="DD-MM-YYYY"
                          label="Date d'entrée prévue"
                          value={dateEntreePrevue}
                          onChange={handleChangeEntrPrevue}
                          style={{ width: "45vh" }}
                          disabled={etatInput}
                          required
                        />
                      </DemoItem>
                    </DemoContainer>
                    <br /> <br />
                    <InputLabel htmlFor="standard-search">Note</InputLabel>
                    <TextareaAutosize
                      label="Situation  "
                      id="standard-search"
                      aria-label=" Note à propos du location"
                      minRows={3} // Définissez le nombre minimum de lignes
                      maxRows={6} // Définissez le nombre maximum de lignes
                      value={noteLoc}
                      onChange={(e) => setNoteLoc(e.target.value)}
                      style={{ width: "45vh" }}
                      disabled={etatInput}
                    />
                    <br /> <br />
                    <br />
                    <br />
                    <br />
                  </span>
                </span>

                <span class="right-span">
                  <TextField
                    id="standard-search"
                    label="Nom  "
                    type="text"
                    variant="standard"
                    value={nomCompl}
                    disabled
                    style={{ width: "45vh" }}
                  />
                  <br />
                  <TextField
                    id="standard-search"
                    label="Mail"
                    type="text"
                    variant="standard"
                    value={`${mail} `}
                    style={{ width: "45vh" }}
                    disabled
                  />
                  <br />

                  <TextField
                    id="standard-search"
                    label="Numéro  "
                    type="text"
                    variant="standard"
                    value={num}
                    style={{ width: "45vh" }}
                    disabled
                  />
                  <br />
                  <TextField
                    id="standard-search"
                    label="Adresse"
                    type="text"
                    variant="standard"
                    value={adresse}
                    style={{ width: "45vh" }}
                    disabled
                  />
                  <div className="calcul">
                    <TextField
                      id="standard-search"
                      label="loyer par m³ "
                      type="number"
                      variant="standard"
                      value={prixM3}
                      onChange={(e) => setPrixM3(e.target.value)}
                      style={{ width: "45vh", overflowY: "hidden" }}
                    />
                    <br /> <br /> <br />
                    Stockage de
                    {selectedValue.map((vol, index) => (
                      <span key={index}>
                        {index !== 0 && "+"} {vol.this} m³
                      </span>
                    ))}
                    <br />
                    Total cubage= {sumVol}m³ <br />
                    <br />
                    <br /> Loyer aproximatif : {sumVol * prixM3} €<br />
                  </div>
                </span>
              </span>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ marginLeft: "40vh" }}
                disabled={etatInput}
              >
                Ajouter
              </Button>
            </form>
          </div>
        </div>
      )}
      {isMobilePortrait && (
        <div className="modalContainerLoc">
          <span className="">
            <CancelIcon
              style={{ cursor: "pointer" }}
              sx={{ marginLeft: "90%", fontSize: 30, marginTop: "-6px" }}
              onClick={() => setAfficherAjoutLoc(false)}
            />
            <div style={{ textAlign: "center" }}>
              <span
                style={{
                  display: "inline-block",
                  fontSize: 20,
                }}
              >
                Enregistrer une nouvelle location <br />{" "}
              </span>
            </div>
          </span>
          <form className="" onSubmit={ajoutLoc}>
            <span class=" ">
              <span class=" ">
                <span style={{ alignItems: "flex" }}>
                  <TextField
                    id="standard-search"
                    label="Client"
                    type="text"
                    variant="standard"
                    value={idClient}
                    onChange={(e) => {
                      setIdClient(e.target.value);

                      viderChamp();
                    }}
                    style={{ width: "60vw" }}
                    inputRef={searchTextFieldRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon
                            color="primary"
                            onClick={() => afficheNom(idClient)}
                            style={{ cursor: "pointer" }}
                            fontSize="large"
                          />
                        </InputAdornment>
                      ),
                    }}
                    onKeyDown={handleKeyDown}
                  />
                  <br />
                  <br />{" "}
                  <TextField
                    id="standard-search"
                    label="Nom  "
                    type="text"
                    variant="standard"
                    value={nomCompl}
                    disabled
                    style={{ width: "60vw" }}
                  />
                  <br /> <br />{" "}
                  <TextField
                    id="standard-search"
                    label="Mail"
                    type="text"
                    variant="standard"
                    value={`${mail} `}
                    style={{ width: "60vw" }}
                    disabled
                  />
                  <br />
                  <br />
                  <Autocomplete
                    multiple
                    id="Id Stockage"
                    options={idEtVolumeLabels}
                    onChange={handleOptionSelect}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="standard-search"
                        label="Id Stockage"
                        type="text"
                        variant="standard"
                        style={{ width: "60vw" }}
                      />
                    )}
                  />
                  <br />
                  <TextField
                    id="standard-search"
                    label={
                      <span style={inputLabelStyle}>Loyer (€)</span> // Appliquer le style bold ici
                    }
                    type="text"
                    variant="standard"
                    value={loyer}
                    onChange={handleValeurPaieChange}
                    error={erreur}
                    helperText={erreur ? "Entrez un nombre positif" : ""}
                    disabled={etatInput}
                    style={{ width: "60vw", overflowY: "hidden" }}
                    InputProps={{ style: inputStyle }}
                  />
                  <br />
                  <br />
                  <DemoContainer components={["DatePicker"]}>
                    <DemoItem>
                      <DatePicker
                        format="DD-MM-YYYY"
                        label="Date d'entrée prévue"
                        value={dateEntreePrevue}
                        onChange={handleChangeEntrPrevue}
                        style={{ width: "60vw" }}
                        disabled={etatInput}
                        required
                      />
                    </DemoItem>
                  </DemoContainer>
                  <br /> <br />
                  <InputLabel htmlFor="standard-search">Note</InputLabel>
                  <TextareaAutosize
                    label="Situation  "
                    id="standard-search"
                    aria-label=" Note à propos du location"
                    minRows={3} // Définissez le nombre minimum de lignes
                    maxRows={6} // Définissez le nombre maximum de lignes
                    value={noteLoc}
                    onChange={(e) => setNoteLoc(e.target.value)}
                    style={{ width: "60vw" }}
                    disabled={etatInput}
                  />
                  <br />
                </span>
              </span>
            </span>

            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={etatInput}
              >
                Ajouter
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
