import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") === "true"
  );
  const [permissions, setPermissions] = useState([]);

  const login = (userData) => {
    if (userData && userData.length > 0) {
      const user = userData.infoUser[0];
      setUser(user.idUser);

      setIsAuthenticated(true);
      setPermissions(user.perm);
      localStorage.setItem("isAuthenticated", "true");

      const expirationTime = 300 * 60 * 1000;

      setTimeout(() => {
        setIsAuthenticated(false);
        setPermissions([]);
        localStorage.removeItem("isAuthenticated");
      }, expirationTime);
    }
  };
  const logout = () => {
    // Effectuez votre logique de déconnexion ici
    setUser(null);
    setIsAuthenticated(false);
    setPermissions([]);
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("us");
    localStorage.removeItem("pr");
  };

  return (
    <AuthContext.Provider
      value={{ user, isAuthenticated, permissions, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
