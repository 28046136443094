import React from "react";
import "../locationStyle/ajoutLocation.css";
import axios from "axios";
import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { addMonths, format, parseISO } from "date-fns";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers";
import { ToastContainer, toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Button,
  FormControl,
  InputLabel,
  TextareaAutosize,
} from "@mui/material";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CancelIcon from "@mui/icons-material/Cancel";
import { TextField } from "@mui/material";
export const ModifierLocation = ({
  setModifierLocation,
  idSelected,
  setFiltre,
  onClose,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const isMobilePortrait = useMediaQuery("(max-width:1207px)  ");
  const [locationsData, setLocationsData] = useState([]);
  const manahirana = idSelected;
  useEffect(() => {
    axios
      .get(`${apiUrl}IHM/api/location.php`, {
        params: { id: idSelected },
      })
      .then((response) => {
        const locationsData = response.data;
        setLocationsData(locationsData);
        const adresse = `${locationsData[0].ville} - ${locationsData[0].codePostal} - ${locationsData[0].adresse}`;
        const num = `${locationsData[0].num1} - ${locationsData[0].num2}`;
        setIdLocation(locationsData[0].idLocation);
        setIdClient(locationsData[0].idClient);
        setNomCompl(locationsData[0].nom);
        setNum(num);
        setMail(locationsData[0].mail);
        setAdresse(adresse);
        setReserv(locationsData[0].dateReservation);
        // handleChangeDtEntr(locationsData[0].dateEntree);
        setEntree(locationsData[0].dateEntree);
        setSortie(locationsData[0].dateSortie);
        setLoyer(locationsData[0].loyer);
        setTotVolume(locationsData[0].totVolume);

        setEtat(locationsData[0].etat);
        setSituation(locationsData[0].situation);
        setNoteLoc(locationsData[0].noteLocation);
        setIdStockage(locationsData[0].idStockage);
      })
      .catch((error) => {
        console.error("error");
      });
  }, [idSelected]); // Exécutez cette requête lorsque idSelected change
  const [newStockage, setNewStockage] = useState("");
  const [idLocation, setIdLocation] = useState("");
  const [idClient, setIdClient] = useState("");
  const [idStockage, setIdStockage] = useState("");
  const [dateReservation, setDateReservation] = useState("");
  const [reserv, setReserv] = useState("");
  const [dateEntree, setDateEntree] = useState("");
  const [dateSortie, setDateSortie] = useState("");
  const [Entree, setEntree] = useState("");
  const [Sortie, setSortie] = useState("");
  useEffect(() => {
    if (Entree == null) {
      setDateEntree(Entree);
    } else {
      setDateEntree(dayjs(Entree));
    }
  }, [Entree]);
  useEffect(() => {
    if (Entree == null) {
      setDateReservation(reserv);
    } else {
      setDateReservation(dayjs(reserv));
    }
  }, [reserv]);
  useEffect(() => {
    if (Sortie === null) {
      setDateSortie(Sortie);
    } else {
      setDateSortie(dayjs(Sortie));
    }
  }, [Sortie]);
  const [loyer, setLoyer] = useState("");
  const [totVolume, setTotVolume] = useState("");
  const [etat, setEtat] = useState("");
  const [situation, setSituation] = useState("");
  const [noteLoc, setNoteLoc] = useState("");
  const [idStockageValues, setIdStockageValues] = useState([]);
  const label = noteLoc === "" ? "Note" : "";
  const [volStockageValues, setVolStockageValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  useEffect(() => {
    setSelectedId(selectedValue.map((item) => item.id));
    // console.log(selectedId, "concatened");
  }, [selectedValue]);
  const handleOptionSelect = (event, newValue) => {
    console.log("Newvalue", newValue);
    if (newValue) {
      const matchingRecords = newValue.map((value) =>
        idEtVolume.find((option) => option.label === value)
      );

      if (matchingRecords.length > 0) {
        console.log("Enregistrements correspondants dans idEtVolume :");
        console.log(matchingRecords);
        setSelectedValue(matchingRecords); // Enregistrez toutes les valeurs correspondantes
      } else {
        console.log("Aucun enregistrement correspondant dans idEtVolume.");
      }
    } else {
      console.log("newValue est vide.");
    }
    console.log("id ho alefa ee", selectedId);
  };
  const faireModification = async (e) => {
    e.preventDefault();

    if (!idClient && !idStockage) {
      // Vérifier si les champs nom et prenom ne sont pas vides
      // Afficher un message d'erreur ou prendre d'autres mesures appropriées
      console.log("Miditra ato izy zany.");

      return; // Arrêter la soumission du formulaire
    } else {
      setIsSubmitting(true);
      await updateBase();
    }
  };
  async function updateBase() {
    let entreeFormate;
    let sortieFormate;
    if (dateEntree === null) {
      entreeFormate = dateEntree;
    } else {
      entreeFormate = formatDate(dateEntree);
    }
    if (dateSortie === null) {
      sortieFormate = dateSortie;
    } else {
      sortieFormate = formatDate(dateSortie);
    }

    let resFormate;
    if (dateReservation === null) {
      resFormate = dateReservation;
    } else {
      resFormate = formatDate(dateReservation);
    }
    const data = {
      idLocation: idLocation,
      idClient: idClient,
      dateReservation: resFormate,
      dateEntree: entreeFormate,
      dateSortie: sortieFormate,
      totVolume: totVolume,
      loyer: loyer,
      etat: etat,
      situation: situation,
      noteLoc: noteLoc,
      idStockage: idStockage,
    };

    if (changeStkg) {
      if (selectedId.length === 0) {
        toast("Veuillez sélectionner des nouveaux stockages à louer");
        return;
      }

      // Assigner la valeur à idStockage
      data.idStockage = selectedId.join(" || ");

      data.totVolume = sumVol;
    } else {
      // Assigner la valeur à idStockage
      data.idStockage = idStockage;
    }
    console.log("Contenu de data :", data);
    try {
      // libererLastStkg
      axios
        .post(`${apiUrl}IHM/api/stockage.php?idSliberer=${idStockage}`)
        .then((response) => {
          //manao modif mihitsy
          axios
            .post(`${apiUrl}IHM/api/location.php`, data)
            .then((response) => {
              console.log(response.data);
              setModifierLocation(false);
              setFiltre("enCours");
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.error("Erreur lors de la requête Axios : ", error);
        });
    } catch (error) {
      console.log("AIZAEEE error");
    }
  }

  const [idEtVolume, setIdEtVolume] = useState([]);
  const [idEtVolumeLabels, setIdEtVolumeLabels] = useState([]);

  useEffect(() => {
    // Mettez à jour idEtVolume lorsque idStockageValues change
    const updatedIdEtVolume = idStockageValues.map((id, index) => ({
      id: id,
      this: volStockageValues[index],
      label: `${id} - ${volStockageValues[index]} m³`,
    }));

    setIdEtVolume(updatedIdEtVolume); // Met  à jour la variable idEtVolume
    //à afficher sur l'option , il faudrait que l'option dans l'autocomplete sera un tableau
    // 1 dimension car il y aura de conflit ,, ca marche mais avec des warnings , d'ou j'ai créé ce idEtVolumeLabels

    setIdEtVolumeLabels(updatedIdEtVolume.map((option) => option.label));
  }, [idStockageValues]);

  //prendre les info des stockages loué auparavant pour pouvoir les mettre dans l  autocomplete(pour y ajouter aux stockages libres)
  const recupInfoPrevStock = (param) => {
    console.log("tafiditra am recupInfoPrevStock");
    axios
      .put(`${apiUrl}IHM/api/stockage.php?idSRecupInfo=${param}`)
      .then((response) => {
        const flatData = response.data.flatMap((item) => item);
        //aplatir le tableau  pour qu'il ressemble au format des idStockageValues déja contenu(si ils sont deja recupéré (les stockages libres))
        const formattedData = flatData.map((item) => ({
          idStockage: item.idStockage,
          vol: item.vol,
          place: item.place,
        }));

        console.log(formattedData, "le teo amstockage taloha");

        const volStockageValues = formattedData.map((item) => item.vol);
        const idStockageValues = formattedData.map((item) => item.idStockage);

        // on fait pas ceci "setIdStockageValues(idStockageValues);" ou ceci " setIdStockageValues(idStockageValues);"
        //car on y ajoute (si les info a propos des stockages loué auparavant y etes deja)
        setVolStockageValues((prevVolStockageValues) =>
          prevVolStockageValues.concat(volStockageValues)
        );
        setIdStockageValues((prevIdStockageValues) =>
          prevIdStockageValues.concat(idStockageValues)
        );

        console.log("VolStockageValues voalohany");
      })
      .catch((error) => {
        console.error("Erreur lors de la requête Axios : ", error);
      });
  };
  const [changeStkg, setChangeStkg] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      //seulement une fois , la premiere fois quand l'utilisateur veut changer les stockages
      if (changeStkg) {
        try {
          recupInfoPrevStock(idStockage);
          recStockage();
        } catch (error) {
          console.error("Erreur lors de la libération du stockage : ", error);
        }
      }
    };

    fetchData();
  }, [changeStkg]);

  //recuperer les info des stockages libre

  const recStockage = () => {
    axios
      .get(`${apiUrl}IHM/api/stockage.php`)
      .then((response) => {
        // console.log(response.data, " le libre  ");
        const idStockageData = response.data;
        const allVolStockageValues = idStockageData.map((item) => item.vol);
        const allIdStockageValues = idStockageData.map(
          (item) => item.idStockage
        );

        // setVolStockageValues(volStockageValues);
        // setIdStockageValues(idStockageValues);
        // on fait pas ceci "setIdStockageValues(idStockageValues);" ou ceci " setIdStockageValues(idStockageValues);"
        //car on y ajoute (si les info a propos des stockages loué auparavant y etes deja)
        setVolStockageValues((prevVolStockageValues) =>
          prevVolStockageValues.concat(allVolStockageValues)
        );
        setIdStockageValues((prevIdStockageValues) =>
          prevIdStockageValues.concat(allIdStockageValues)
        );
      })
      .catch((error) => {
        console.error("Erreur lors de la requête Axios : ", error);
      });
  };

  // Appelez la fonction pour effectuer la requête apres changement de changeStkg

  const [activationComplete, setActivationComplete] = useState(false);
  //pour que le changement ne va s'executer qu'une seul fois
  const handleSwitchChange = () => {
    if (!activationComplete) {
      setChangeStkg(true); // Active la fonctionnalité
      setActivationComplete(true); // Marque l'activation comme complète
    }
  };
  //mitovy am'ajout
  const [nomCompl, setNomCompl] = useState("");
  const [num, setNum] = useState("");
  const [mail, setMail] = useState("");
  const [adresse, setAdresse] = useState("");
  //fin client
  const [sommeVolume, setSommeVolume] = useState(0);
  const [variable, setVariable] = useState("");
  const [valeur, setValeur] = useState("");
  const [prixM3, setPrixM3] = useState(12);
  const sumVol = selectedValue.reduce((accumulator, currentValue) => {
    // Assurez-vous que currentValue.this est un nombre en le convertissant avec parseFloat
    const thisValue = parseFloat(currentValue.this);
    // Vérifiez si thisValue est un nombre valide
    if (!isNaN(thisValue)) {
      return accumulator + thisValue;
    } else {
      return accumulator;
    }
  }, 0);

  const formatDate = (date) => {
    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const handleChangeDtEntr = (date) => {
    console.log(date, "entrée");

    const formattedDate = formatDate(date);
    console.log(formattedDate, "tsy ay tony");
    setDateEntree(formattedDate);
  };

  const handleChangeDtSort = (dateS) => {
    const formattedDateS = formatDate(dateS);
    if (new Date(formatDate(dateS)) > new Date()) {
      setIsSubmitting(true);
    } else {
      setIsSubmitting(false);
    }
    console.log(formattedDateS, "tsy ay tony");
    setDateSortie(formattedDateS);
  };
  const [erreur, setErreur] = useState(false);
  const handleValeurPaieChange = (e) => {
    const inputValue = e.target.value;

    // Vérifie si l'entrée est un nombre positif

    if (/^[+]?\d*\.?\d*$/.test(inputValue) || inputValue === "") {
      setLoyer(inputValue);
      setErreur(false);
    } else {
      setErreur(true);
    }
  };

  return (
    <div className=" ">
      {!isMobilePortrait && (
        <div className="modalContainerLoc">
          {" "}
          <div className="">
            {" "}
            <span className="titreEtBtnLoc">
              <CancelIcon
                style={{ cursor: "pointer" }}
                sx={{ marginLeft: "95%", fontSize: 40, marginTop: "-26px" }}
                onClick={() => setModifierLocation(false)}
              />
              <h3 className="titleLoc">Modifier une location</h3>
            </span>
            <div className=" ">
              <form onSubmit={faireModification}>
                <span class="parent-span">
                  <span class="left-span">
                    {locationsData.map((location, index) => (
                      <div key={index}>
                        <TextField
                          id="standard-search"
                          label="Id location"
                          type="text"
                          variant="standard"
                          value={idLocation}
                          onChange={(e) => setIdLocation(e.target.value)}
                          disabled
                          style={{ width: "100%" }}
                        />{" "}
                        <br /> <br />
                        <TextField
                          id="standard-search"
                          label={
                            changeStkg
                              ? "Id Stockage loué avant"
                              : "Id Stockage "
                          }
                          type="text"
                          variant="standard"
                          value={idStockage}
                          onChange={(e) => setIdStockage(e.target.value)}
                          disabled
                          style={{ width: "100%" }}
                        />
                        {!changeStkg && (
                          <>
                            <FormControlLabel
                              sx={{
                                display: "block",
                              }}
                              control={
                                <Switch
                                  onChange={handleSwitchChange}
                                  name="loading"
                                  color="primary"
                                  disabled={activationComplete} // Désactive le Switch si l'activation est complète
                                />
                              }
                              label="Modifier les Stockages"
                            />
                          </>
                        )}
                        {changeStkg && (
                          <>
                            <br /> <br />
                            Nouveaux stockages
                            <Autocomplete
                              multiple
                              id="Id Stockage"
                              options={idEtVolumeLabels}
                              onChange={handleOptionSelect}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="standard-search"
                                  label="Id Stockage"
                                  type="text"
                                  variant="standard"
                                  style={{ width: "100%" }}
                                />
                              )}
                            />
                          </>
                        )}
                        <br /> <br />
                        <DemoContainer components={["DatePicker"]}>
                          <DemoItem>
                            <DatePicker
                              format="DD-MM-YYYY"
                              label="Date de réservation"
                              value={dateReservation}
                              style={{ width: "100%" }}
                              disabled
                            />
                          </DemoItem>
                        </DemoContainer>
                        <br />
                        <br />
                        <DemoContainer components={["DatePicker"]}>
                          <DemoItem>
                            <DatePicker
                              format="DD-MM-YYYY"
                              label="Date entree"
                              value={dateEntree}
                              onChange={handleChangeDtEntr}
                              style={{ width: "100%" }}
                            />
                          </DemoItem>
                        </DemoContainer>
                        <br />{" "}
                        <DemoContainer components={["DatePicker"]}>
                          <DemoItem>
                            <DatePicker
                              format="DD-MM-YYYY"
                              disableFuture
                              label="Date sortie"
                              value={dateSortie}
                              onChange={handleChangeDtSort}
                              style={{ width: "100%" }}
                            />
                          </DemoItem>
                        </DemoContainer>
                        <br />
                        <TextField
                          variant="standard"
                          id="standard-search"
                          label="Loyer"
                          type="texte"
                          value={loyer}
                          onChange={handleValeurPaieChange}
                          style={{ width: "100%" }}
                          helperText={erreur ? "Entrez un nombre positif" : ""}
                        />
                        <br />
                        <br />{" "}
                        <FormControl
                          variant="standard"
                          style={{ width: "100%" }}
                        >
                          <InputLabel> {label}</InputLabel>
                          <TextareaAutosize
                            variant="standard"
                            aria-label={label}
                            minRows={3} // Définissez le nombre minimum de lignes
                            maxRows={5} // Définissez le nombre maximum de lignes
                            value={noteLoc}
                            onChange={(e) => setNoteLoc(e.target.value)}
                            style={{ width: "100%" }}
                          />
                        </FormControl>
                      </div>
                    ))}
                  </span>
                  <span class="right-span">
                    <TextField
                      id="standard-search"
                      label="Nom  "
                      type="text"
                      variant="standard"
                      value={`${idClient} - ${nomCompl}`}
                      disabled
                    />
                    <br />
                    <TextField
                      id="standard-search"
                      label="Mail"
                      type="text"
                      variant="standard"
                      value={mail}
                      disabled
                    />
                    <br />
                    <TextField
                      id="standard-search"
                      label="Numéro  "
                      type="text"
                      variant="standard"
                      value={num}
                      disabled
                    />
                    <br />
                    <TextField
                      id="standard-search"
                      label="Adresse"
                      type="text"
                      variant="standard"
                      value={adresse}
                      disabled
                    />
                    <div className="calcul">
                      <TextField
                        id="standard-search"
                        label="loyer par m³ "
                        type="number"
                        variant="standard"
                        value={prixM3}
                        onChange={(e) => setPrixM3(e.target.value)}
                        style={{ width: "45vh", overflowY: "hidden" }}
                      />
                      <br /> <br /> <br />
                      Stockage de
                      {selectedValue.map((vol, index) => (
                        <span key={index}> {vol.this} m³ +</span>
                      ))}{" "}
                      <br />
                      Total cubage={sumVol} m³ <br />
                      <br />
                      <br /> Loyer aproximatif : {sumVol * prixM3} €<br />
                      <br />
                    </div>
                  </span>
                </span>
                <Button
                  className="myBouton"
                  variant="contained"
                  type="submit"
                  sx={{ marginLeft: "40%" }}
                  disabled={isSubmitting}
                >
                  Modifier
                </Button>
              </form>
            </div>
          </div>
        </div>
      )}
      {isMobilePortrait && (
        <div className="modalContainerLoc">
          <div className="">
            <span className=" ">
              <CancelIcon
                style={{ cursor: "pointer" }}
                sx={{ marginLeft: "90%", fontSize: 30, marginTop: "-6px" }}
                onClick={() => setModifierLocation(false)}
              />
              <div style={{ textAlign: "center" }}>
                {" "}
                <span
                  style={{
                    display: "inline-block",
                    fontSize: 20,
                  }}
                >
                  Modifier une location
                  <br />{" "}
                </span>
              </div>
            </span>
            <div className=" ">
              <form onSubmit={faireModification}>
                <span class=" ">
                  <span class=" ">
                    {locationsData.map((location, index) => (
                      <div key={index}>
                        <TextField
                          id="standard-search"
                          label="Id location"
                          type="text"
                          variant="standard"
                          value={idLocation}
                          onChange={(e) => setIdLocation(e.target.value)}
                          disabled
                          style={{ width: "120%" }}
                        />{" "}
                        <br /> <br />{" "}
                        <TextField
                          id="standard-search"
                          label="Nom  "
                          type="text"
                          variant="standard"
                          value={`${idClient} - ${nomCompl}`}
                          style={{ width: "120%" }}
                          disabled
                        />
                        <br />
                        <TextField
                          id="standard-search"
                          label="Mail"
                          type="text"
                          variant="standard"
                          style={{ width: "120%" }}
                          value={mail}
                          disabled
                        />
                        <br />
                        <TextField
                          id="standard-search"
                          label={
                            changeStkg
                              ? "Id Stockage loué avant"
                              : "Id Stockage "
                          }
                          type="text"
                          variant="standard"
                          value={idStockage}
                          onChange={(e) => setIdStockage(e.target.value)}
                          disabled
                          style={{ width: "120%" }}
                        />
                        {!changeStkg && (
                          <>
                            <FormControlLabel
                              sx={{
                                display: "block",
                              }}
                              control={
                                <Switch
                                  onChange={handleSwitchChange}
                                  name="loading"
                                  color="primary"
                                  disabled={activationComplete} // Désactive le Switch si l'activation est complète
                                />
                              }
                              label="Modifier les Stockages"
                            />
                          </>
                        )}
                        {changeStkg && (
                          <>
                            <br /> <br />
                            Nouveaux stockages
                            <Autocomplete
                              multiple
                              id="Id Stockage"
                              options={idEtVolumeLabels}
                              onChange={handleOptionSelect}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="standard-search"
                                  label="Id Stockage"
                                  type="text"
                                  variant="standard"
                                  style={{ width: "120%" }}
                                />
                              )}
                            />
                          </>
                        )}
                        <br /> <br />
                        <div style={{ width: "120%" }}>
                          <DemoContainer components={["DatePicker"]}>
                            <DemoItem>
                              <DatePicker
                                format="DD-MM-YYYY"
                                label="Date de réservation"
                                value={dateReservation}
                                style={{ width: "120%" }}
                                disabled
                              />
                            </DemoItem>
                          </DemoContainer>
                          <br />
                          <br />
                          <DemoContainer components={["DatePicker"]}>
                            <DemoItem>
                              <DatePicker
                                format="DD-MM-YYYY"
                                label="Date entree"
                                value={dateEntree}
                                onChange={handleChangeDtEntr}
                                style={{ width: "120%" }}
                              />
                            </DemoItem>
                          </DemoContainer>
                          <br />{" "}
                          <DemoContainer components={["DatePicker"]}>
                            <DemoItem>
                              <DatePicker
                                format="DD-MM-YYYY"
                                disableFuture
                                label="Date sortie"
                                value={dateSortie}
                                onChange={handleChangeDtSort}
                                style={{ width: "120%" }}
                              />
                            </DemoItem>
                          </DemoContainer>
                        </div>
                        <br />
                        <TextField
                          variant="standard"
                          id="standard-search"
                          label="Loyer"
                          type="texte"
                          value={loyer}
                          onChange={handleValeurPaieChange}
                          style={{ width: "120%" }}
                          helperText={erreur ? "Entrez un nombre positif" : ""}
                        />
                        <br />
                        <br />{" "}
                        <FormControl
                          variant="standard"
                          style={{ width: "120%" }}
                        >
                          <InputLabel> {label}</InputLabel>
                          <TextareaAutosize
                            variant="standard"
                            aria-label={label}
                            minRows={3} // Définissez le nombre minimum de lignes
                            maxRows={5} // Définissez le nombre maximum de lignes
                            value={noteLoc}
                            onChange={(e) => setNoteLoc(e.target.value)}
                            style={{ width: "120%" }}
                          />
                        </FormControl>
                      </div>
                    ))}
                  </span>
                </span>
                <div style={{ textAlign: "center" }}>
                  <Button
                    className="myBouton"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Modifier
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
