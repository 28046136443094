import React from "react";
import axios from "axios";
import { useMediaQuery } from "@mui/material";
import { BoxItem } from "./boxItem";
import "../etageStyle/etageStyle.css";
import { useState, useEffect } from "react";
const Etage = ({ setSearch, setMotRecherche, motRechercheClient, setMotRechercheClient }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [triee, setTrie] = useState([]);

  useEffect(() => {
    axios.get(`${apiUrl}IHM/api/stockage.php`).then((response) => {
      const listeOccupe = response.data.map((item) => item.idStockage);

      setTrie(listeOccupe);
    });
  }, []);

  const dispo = (valeur) => {
    return triee.includes(valeur) ? "vert" : "rouge";
  };
  const handleConteneurClick = (param) => {
    setMotRecherche(param);
    setSearch(true);

    if (param === "") {
      // Si le champ de recherche est vide, réinitialisez les styles et sortez de la fonction
      const divs = document.querySelectorAll(".stock");
      divs.forEach((div) => {
        div.classList.remove("highlight-yellow");
      });
      return;
    }

    const regex = new RegExp(`\\b${param}\\b`, "i");
    const divs = document.querySelectorAll(".stock");
    divs.forEach((div) => {
      const texteDiv = div.textContent;
      //  recherche
      if (regex.test(texteDiv)) {
        // Si le texte de la div correspond à la recherche, appliquez le style
        div.classList.add("highlight-yellow");
        setTimeout(() => {
          div.classList.remove("highlight-yellow");
        }, 900);

        // Calcul de la position et défilement de la page
        const windowHeight = window.innerHeight;
        const divTopOffset = div.getBoundingClientRect().top;
        const scrollPosition = divTopOffset - windowHeight / 2;

        window.scrollBy({
          top: scrollPosition,
          behavior: "smooth",
        });
      } else {
        // Si le texte de la div ne correspond pas à la recherche, supprimez le style
        div.classList.remove("highlight-yellow");
      }
    });
  };
  const isMobilePortrait = useMediaQuery("(max-width:1400px) ");
  return (
    <div>
      <div className="containerBox">
        <div className="toutStock " style={{ width: "fitContent" }}>
          <div className="ligne">
            <div className="colonne" style={{ marginTop: "91px" }}>
              <div className="ligne">
                <div
                  className={` stock Rbox  ${dispo("61B")}`}
                  onClick={() => {
                    handleConteneurClick("61B");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  61B
                  <BoxItem />
                </div>
                <div
                  className={` stock Rbox  ${dispo("61A")}`}
                  onClick={() => {
                    handleConteneurClick("61A");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  61A
                  <BoxItem />
                </div>
                <div
                  className={`stock Rbox  ${dispo("60")}`}
                  onClick={() => {
                    handleConteneurClick("60");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  60
                  <BoxItem />
                </div>
                <div
                  className={` stock Rbox  ${dispo("59")}`}
                  onClick={() => {
                    handleConteneurClick("59");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  59
                  <BoxItem />
                </div>
                <div
                  className={` stock Rbox  ${dispo("58")}`}
                  onClick={() => {
                    handleConteneurClick("58");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  58
                  <BoxItem />
                </div>
              </div>
            </div>
            {/* separate this */}
            <div className="colonne">
              <div
                className={` stock box53 ${dispo("53")}`}
                onClick={() => {
                  handleConteneurClick("53");
                }}
                style={{ cursor: "pointer", marginLeft: "-20px" }}
              >
                53
                <BoxItem />
              </div>
              <div
                className={`stock box57 ${dispo("57")}`}
                onClick={() => {
                  handleConteneurClick("57");
                }}
                style={{ cursor: "pointer" }}
              >
                57
                <BoxItem />
              </div>
            </div>

            <div className="colonne">
              <div
                className={`stock box54 ${dispo("54")}`}
                onClick={() => {
                  handleConteneurClick("54");
                }}
                style={{ cursor: "pointer" }}
              >
                54
                <BoxItem />
              </div>{" "}
              <div
                className={`stock box54 ${dispo("55")}`}
                onClick={() => {
                  handleConteneurClick("55");
                }}
                style={{ cursor: "pointer" }}
              >
                55
                <BoxItem />
              </div>
              <div
                className={`stock box54 ${dispo("56")}`}
                onClick={() => {
                  handleConteneurClick("56");
                }}
                style={{ cursor: "pointer" }}
              >
                56
                <BoxItem />
              </div>
            </div>
            {/* separate */}
            <div style={{ width: "35px" }}></div>
            <div className="colonne">
              <div
                className={`stock box90 ${dispo("90")}`}
                onClick={() => {
                  handleConteneurClick("90");
                }}
                style={{ cursor: "pointer" }}
              >
                90
                <BoxItem />
              </div>
              <div
                className={`stock box90 ${dispo("91")}`}
                onClick={() => {
                  handleConteneurClick("91");
                }}
                style={{ cursor: "pointer" }}
              >
                91
                <BoxItem />
              </div>
            </div>

            <div style={{ width: "5px" }}></div>
            <div className="colonne">
              <div className="ligne">
                <div
                  className={`stock Rbox2 ${dispo("85")}`}
                  onClick={() => {
                    handleConteneurClick("85");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  85
                  <BoxItem />
                </div>{" "}
                <div
                  className={`stock Rbox2 ${dispo("82")}`}
                  onClick={() => {
                    handleConteneurClick("82");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  82
                  <BoxItem />
                </div>
                {/* newwwwww */}
                <div style={{ width: "45px" }}></div>
                <div

                  className={`stock Rbox2 ${dispo("98")}`}
                  onClick={() => {
                    handleConteneurClick("98");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  98
                  <BoxItem />
                </div>
                {/* newwwwww */}
              </div>
              <div className="ligne">
                <div className="colonne">
                  <div
                    className={`stock box54 ${dispo("86")}`}
                    onClick={() => {
                      handleConteneurClick("86");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    86
                    <BoxItem />
                  </div>
                  <div
                    className={`stock box54 ${dispo("87")}`}
                    onClick={() => {
                      handleConteneurClick("87");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    87
                    <BoxItem />
                  </div>
                </div>{" "}
                <div className="colonne" style={{ marginLeft: "42px" }}>
                  <div
                    className={`stock box54 ${dispo("84")}`}
                    onClick={() => {
                      handleConteneurClick("84");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    84
                    <BoxItem />
                  </div>
                  <div
                    className={`stock box54 ${dispo("83")}`}
                    onClick={() => {
                      handleConteneurClick("83");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    83
                    <BoxItem />
                  </div>
                </div>{" "}
                <div className="colonne" style={{ marginLeft: "42px" }}>
                  <div
                    className={`stock box54 ${dispo("81")}`}
                    onClick={() => {
                      handleConteneurClick("81");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    81
                    <BoxItem />
                  </div>
                  <div
                    className={`stock box54 ${dispo("80")}`}
                    onClick={() => {
                      handleConteneurClick("80");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    80
                    <BoxItem />
                  </div>
                </div>
                {/* newwwwww */}       <div className="colonne" style={{ marginLeft: "62px" }}>
                  <div style={{ cursor: "pointer" }} className={`stock carton ${dispo("99")}`} onClick={() => {
                    handleConteneurClick("99");
                  }}>
                    99
                    <BoxItem /></div>
                </div>

                {/* newwwwww */}
              </div>
            </div>

            {/* spareooo  spareooo
          spareooo spareooo 
           */}
          </div>
          <div className="colonne">
            <div
              className="ligne"
              style={{ marginLeft: "134px", marginTop: "104px" }}
            >
              <div className="ligne">
                <div
                  className={`stock Rbox ${dispo("64")}`}
                  onClick={() => {
                    handleConteneurClick("64");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  64
                  <BoxItem />
                </div>
                <div
                  className={`stock Rbox ${dispo("63")}`}
                  onClick={() => {
                    handleConteneurClick("63");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  63
                  <BoxItem />
                </div>
                <div
                  className={`stock Rbox ${dispo("62")}`}
                  onClick={() => {
                    handleConteneurClick("62");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  62
                  <BoxItem />
                </div>
                <div className="ligne" style={{ marginLeft: "104px" }}>
                  <div className="colonne">
                    <div
                      className={`stock Rbox ${dispo("45")}`}
                      onClick={() => {
                        handleConteneurClick("45");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      45
                      <BoxItem />
                    </div>
                    <div
                      className={`stock carton ${dispo("44")}`}
                      onClick={() => {
                        handleConteneurClick("44");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      CARTON (44)
                      <BoxItem />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="ligne"
              style={{ marginLeft: "134px", marginTop: "-50px" }}
            >
              <div
                className={`stock Rbox ${dispo("74")}`}
                onClick={() => {
                  handleConteneurClick("74");
                }}
                style={{ cursor: "pointer" }}
              >
                74
                <BoxItem />
              </div>
              <div
                className={`stock Rbox ${dispo("73")}`}
                onClick={() => {
                  handleConteneurClick("73");
                }}
                style={{ cursor: "pointer" }}
              >
                73
                <BoxItem />
              </div>
              <div
                className={`stock Rbox ${dispo("72")}`}
                onClick={() => {
                  handleConteneurClick("72");
                }}
                style={{ cursor: "pointer" }}
              >
                72
                <BoxItem />
              </div>{" "}
            </div>
          </div>

          {/* MIDINA AMLE TELO MANARAKA
           */}
          <div
            className="ligne"
            style={{ marginLeft: "120px", marginTop: "50px" }}
          >

            <div style={{ marginLeft: "45px" }}>
              <div
                className={`stock Rbox ${dispo("19")}`}
                onClick={() => {
                  handleConteneurClick("19");
                }}
                style={{ cursor: "pointer" }}
              >
                19
                <BoxItem />
              </div></div>
            <div
              className={`stock Rbox ${dispo("18")}`}
              onClick={() => {
                handleConteneurClick("18");
              }}
              style={{ cursor: "pointer" }}
            >
              18
              <BoxItem />
            </div>
            <div
              className={`stock box54 ${dispo("17")}`}
              onClick={() => {
                handleConteneurClick("17");
              }}
              style={{ cursor: "pointer" }}
            >
              17
              <BoxItem />
            </div>
            <div
              className={`stock box54 ${dispo("16")}`}
              onClick={() => {
                handleConteneurClick("16");
              }}
              style={{ cursor: "pointer" }}
            >
              16
              <BoxItem />
            </div>{" "}
            <div
              className={`stock Rbox ${dispo("15")}`}
              onClick={() => {
                handleConteneurClick("15");
              }}
              style={{ cursor: "pointer" }}
            >
              15
              <BoxItem />
            </div>
          </div>

          <div
            className="ligne"
            style={{ marginLeft: "130px", marginTop: "2px" }}
          >
            <div
              className={`stock Rbox ${dispo("100")}`}
              onClick={() => {
                handleConteneurClick("100");
              }}
              style={{ cursor: "pointer" }}
            >
              100
              <BoxItem />
            </div>  </div>

          <div
            className="ligne"
            style={{ marginLeft: "170px", marginTop: "2px" }}
          >
            <div
              className={`stock box90 ${dispo("20D")}`}
              onClick={() => {
                handleConteneurClick("20D");
              }}
              style={{ cursor: "pointer" }}
            >
              20
              <br />D
              <BoxItem />
            </div>
            <div
              className={`stock box90 ${dispo("20G")}`}
              onClick={() => {
                handleConteneurClick("20G");
              }}
              style={{ cursor: "pointer" }}
            >
              20
              <br />G
              <BoxItem />
            </div>
            <div
              className={`stock Rbox ${dispo("21")}`}
              onClick={() => {
                handleConteneurClick("21");
              }}
              style={{ cursor: "pointer" }}
            >
              21
              <BoxItem />
            </div>

            <div
              className={`stock Rbox ${dispo("22")}`}
              onClick={() => {
                handleConteneurClick("22");
              }}
              style={{ cursor: "pointer" }}
            >
              22
              <BoxItem />
            </div>
            <div
              className={`stock Rbox ${dispo("23")}`}
              onClick={() => {
                handleConteneurClick("23");
              }}
              style={{ cursor: "pointer" }}
            >
              23
              <BoxItem />
            </div>
            <div
              className={`stock Rbox ${dispo("24")}`}
              onClick={() => {
                handleConteneurClick("24");
              }}
              style={{ cursor: "pointer" }}
            >
              24
              <BoxItem />
            </div>
            <div
              className={`stock Rbox ${dispo("25")}`}
              onClick={() => {
                handleConteneurClick("25");
              }}
              style={{ cursor: "pointer" }}
            >
              25
              <BoxItem />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Etage;
