import React from "react";
import { Ree } from "./ree";
import "../RDCstyle/reesStyle.css";
import { useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
const Rees = ({ setSearch, setMotRecherche , motRechercheClient ,  setMotRechercheClient }) => {
  const [triee, setTrie] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios.get(`${apiUrl}IHM/api/stockage.php`).then((response) => {
      const listeOccupe = response.data.map((item) => item.idStockage);
      setTrie(listeOccupe);
    });
  }, []);

  const dispo = (valeur) => {
    return triee.includes(valeur) ? "vert" : "rouge";
  };
  
  const handleConteneurClick = (param) => {
    setMotRecherche(param);
    setSearch(true);
    if (param === "") {
      // Si le champ de recherche est vide, réinitialisez les styles et sortez de la fonction
      const divs = document.querySelectorAll(".stock");
      divs.forEach((div) => {
        div.classList.remove("highlight-yellow");
      });
      return;
    }

    const regex = new RegExp(`\\b${param}\\b`, "i");
    const divs = document.querySelectorAll(".stock");
    divs.forEach((div) => {
      const texteDiv = div.textContent;
      //  recherche
      if (regex.test(texteDiv)) {
        // Si le texte de la div correspond à la recherche, appliquez le style
        div.classList.add("highlight-yellow");
        setTimeout(() => {
          div.classList.remove("highlight-yellow");
        }, 900);

        // Calcul de la position et défilement de la page
        const windowHeight = window.innerHeight;
        const divTopOffset = div.getBoundingClientRect().top;
        const scrollPosition = divTopOffset - windowHeight / 2;

        window.scrollBy({
          top: scrollPosition,
          behavior: "smooth",
        });
      } else {
        // Si le texte de la div ne correspond pas à la recherche, supprimez le style
        div.classList.remove("highlight-yellow");
      }
    });
  };
  const isMobilePortrait = useMediaQuery("(max-width:1400px) ");
  return (
    <div>
      <div className="containerBox">
        <div className="toutStock" style={{ width: "fitContent" }}>
          <div className="ligne">
            <div className="colonne" style={{ marginTop: "104px" }}>
              <div className="ligne">
                <div
                  className={`stock Rbox ${dispo("52")}`}
                  onClick={() => {
                    handleConteneurClick("52");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  52
                  <Ree />
                </div>
                <div
                  className={`stock Rbox ${dispo("51")}`}
                  onClick={() => {
                    handleConteneurClick("51");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  51
                  <Ree />
                </div>
                <div
                  className={`stock Rbox ${dispo("50")}`}
                  onClick={() => {
                    handleConteneurClick("50");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  50
                  <Ree />
                </div>
                <div
                  className={`stock Rbox ${dispo("49")}`}
                  onClick={() => {
                    handleConteneurClick("49");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  49
                  <Ree />
                </div>
                <div
                  className={`stock Rbox ${dispo("48")}`}
                  onClick={() => {
                    handleConteneurClick("48");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  48
                  <Ree />
                </div>
              </div>
            </div>
            {/* separate this */}
            <div className="colonne" style={{ marginLeft: "-86px" }}>
              <div
                className={`stock Rbox8 ${dispo("46")}`}
                onClick={() => {
                  handleConteneurClick("46");
                }}
                style={{ cursor: "pointer" }}
              >
                46
                <Ree />
              </div>
              <div
                className={`stock Rbox8 ${dispo("47")}`}
                onClick={() => {
                  handleConteneurClick("47");
                }}
                style={{ cursor: "pointer" }}
              >
                47
                <Ree />
              </div>
            </div>

            <div style={{ width: "35px" }}></div>
            <div className="colonne">
              <div
                className={`stock Rbox3 ${dispo("89")}`}
                onClick={() => {
                  handleConteneurClick("89");
                }}
                style={{ cursor: "pointer" }}
              >
                89
                <Ree />
              </div>
              <div
                className={`stock Rbox3 ${dispo("88")}`}
                onClick={() => {
                  handleConteneurClick("88");
                }}
                style={{ cursor: "pointer" }}
              >
                88
                <Ree />
              </div>
            </div>

            <div style={{ width: "5px" }}></div>
            <div className="colonne" style={{ paddingTop: "50px" }}>
              <div
                className={`stock Rbox ${dispo("39")}`}
                onClick={() => {
                  handleConteneurClick("39");
                }}
                style={{ cursor: "pointer" }}
              >
                39
                <Ree />
              </div>
              <div
                className={`stock Rbox ${dispo("40")}`}
                onClick={() => {
                  handleConteneurClick("40");
                }}
                style={{ cursor: "pointer" }}
              >
                40
                <Ree />
              </div>
            </div>

            <div className="colonne">
              <div
                className={`stock Rbox ${dispo("38")}`}
                onClick={() => {
                  handleConteneurClick("38");
                }}
                style={{ cursor: "pointer" }}
              >
                38
                <Ree />
              </div>
            </div>
            <div className="colonne" style={{ paddingTop: "50px" }}>
              {" "}
              <div
                className={`stock Rbox ${dispo("37")}`}
                onClick={() => {
                  handleConteneurClick("37");
                }}
                style={{ cursor: "pointer" }}
              >
                37
                <Ree />
              </div>{" "}
              <div
                className={`stock Rbox ${dispo("36")}`}
                onClick={() => {
                  handleConteneurClick("36");
                }}
                style={{ cursor: "pointer" }}
              >
                36
                <Ree />
              </div>
            </div>
            <div className="colonne">
              {" "}
              <div
                className={`stock Rbox2 ${dispo("78")}`}
                onClick={() => {
                  handleConteneurClick("78");
                }}
                style={{ cursor: "pointer" }}
              >
                78
                <Ree />
              </div>
            </div>

            <div className="colonne" style={{ paddingTop: "50px" }}>
              {" "}
              <div
                className={`stock Rbox ${dispo("77")}`}
                onClick={() => {
                  handleConteneurClick("77");
                }}
                style={{ cursor: "pointer" }}
              >
                77
                <Ree />
              </div>{" "}
              <div
                className={`stock Rbox ${dispo("76")}`}
                onClick={() => {
                  handleConteneurClick("76");
                }}
                style={{ cursor: "pointer" }}
              >
                76
                <Ree />
              </div>
            </div>
            <div className="colonne">
              {" "}
              <div
                className={`stock Rbox ${dispo("95")}`}
                onClick={() => {
                  handleConteneurClick("95");
                }}
                style={{ cursor: "pointer" }}
              >
                95
                <Ree />
              </div>
              <div
                className={`stock boxL ${dispo("94")}`}
                onClick={() => {
                  handleConteneurClick("94");
                }}
                style={{ cursor: "pointer", marginLeft: "20px" }}
              >
                94
                <Ree />
              </div>
            </div>
          </div>
          <div className="ligne">
            <div className="colonne" style={{ marginTop: "104px" }}>
              <div className="ligne" style={{ marginLeft: "134px" }}>
                <div
                  className={`stock Rbox ${dispo("65")}`}
                  onClick={() => {
                    handleConteneurClick("65");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  65
                  <Ree />
                </div>
                <div
                  className={`stock Rbox ${dispo("66")}`}
                  onClick={() => {
                    handleConteneurClick("66");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  66
                  <Ree />
                </div>
                <div
                  className={`stock Rbox ${dispo("67")}`}
                  onClick={() => {
                    handleConteneurClick("67");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  67
                  <Ree />
                </div>
                <div className="colonne">
                  <div className="ligne">
                    <div
                      className={`stock box97 ${dispo("97")}`}
                      onClick={() => {
                        handleConteneurClick("97");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      97
                      <Ree />
                    </div>
                    <div
                      className={`stock boxL ${dispo("96")}`}
                      onClick={() => {
                        handleConteneurClick("96");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      96
                      <Ree />
                    </div>{" "}
                  </div>
                  <div
                    className={`stock box68 ${dispo("68")}`}
                    onClick={() => {
                      handleConteneurClick("68");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    68
                    <Ree />
                  </div>
                </div>
                <div className="colonne">
                  <div
                    className={`stock Rbox ${dispo("41")}`}
                    onClick={() => {
                      handleConteneurClick("41");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    41
                    <Ree />
                  </div>
                  <div
                    className={`stock Rbox ${dispo("42")}`}
                    onClick={() => {
                      handleConteneurClick("42");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    42
                    <Ree />
                  </div>
                  <div
                    className={`stock Rbox ${dispo("43")}`}
                    onClick={() => {
                      handleConteneurClick("43");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    43
                    <Ree />
                  </div>
                </div>
                {/* separatoion */}
                <div className="colonne" style={{ marginLeft: "50px" }}>
                  <div
                    className={`stock Rbox ${dispo("35")}`}
                    onClick={() => {
                      handleConteneurClick("35");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    35
                    <Ree />
                  </div>
                  <div
                    className={`stock Rbox ${dispo("34")}`}
                    onClick={() => {
                      handleConteneurClick("34");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    34
                    <Ree />
                  </div>
                  <div
                    className={`stock Rbox ${dispo("33")}`}
                    onClick={() => {
                      handleConteneurClick("33");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    33
                    <Ree />
                  </div>
                </div>{" "}
                <div className="colonne" style={{ marginLeft: "100px" }}>
                  <div className="ligne">
                    <div className="colonne">
                      <div
                        className={`stock Rbox ${dispo("32")}`}
                        onClick={() => {
                          handleConteneurClick("32");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        32
                        <Ree />
                      </div>
                      <div
                        className={`stock Rbox ${dispo("31")}`}
                        onClick={() => {
                          handleConteneurClick("31");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        31
                        <Ree />
                      </div>
                      <div
                        className={`stock Rbox ${dispo("30")}`}
                        onClick={() => {
                          handleConteneurClick("30");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        30
                        <Ree
                          onClick={() => {
                            handleConteneurClick("30");
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <div className="colonne">
                      <div className="ligne">
                        <div
                          // className={`stock miniBox ${dispo("92")}`}
                          className={`stock Rbox ${dispo("92")}`}
                          onClick={() => {
                            handleConteneurClick("92");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          92
                          <Ree />
                        </div>
                        <div
                          className={`stock  Rbox ${dispo("93")}`}
                          onClick={() => {
                            handleConteneurClick("93");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          93
                          <Ree />
                        </div>
                      </div>
                      <div
                        className={`stock moyenBox ${dispo("29")}`}
                        onClick={() => {
                          handleConteneurClick("29");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        29
                        <Ree />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* midina amle 71 70 69 */}

              <div
                className="ligne"
                style={{
                  marginTop: "-53px",
                  marginLeft: "134px",
                  width: "200px",
                }}
              >
                {" "}
                <div
                  className={`stock Rbox  ${dispo("71")}`}
                  onClick={() => {
                    handleConteneurClick("71");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  71
                  <Ree />
                </div>
                <div
                  className={`stock Rbox  ${dispo("70")}`}
                  onClick={() => {
                    handleConteneurClick("70");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  70
                  <Ree />
                </div>
                <div
                  className={`stock Rbox  ${dispo("69")}`}
                  onClick={() => {
                    handleConteneurClick("69");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  69
                  <Ree />
                </div>
              </div>
              <div className="ligne" style={{ marginLeft: "83px" }}>
                <div
                  className={`stock Rbox  ${dispo("75")}`}
                  onClick={() => {
                    handleConteneurClick("75");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  75
                  <Ree />
                </div>
              </div>

              {/* sarao */}

              <div className="ligne" style={{ marginLeft: "160px" }}>
                <div
                  className={`stock Rbox7  ${dispo("79")}`}
                  onClick={() => {
                    handleConteneurClick("79");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  79
                  <Ree />
                </div>
                <div
                  className={`stock Rbox7  ${dispo("7")}`}
                  onClick={() => {
                    handleConteneurClick("7");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  7
                  <Ree />
                </div>
                <div
                  className={`stock Rbox7  ${dispo("6")}`}
                  onClick={() => {
                    handleConteneurClick("6");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  6
                  <Ree />
                </div>
                <div
                  className={`stock Rbox7  ${dispo("5")}`}
                  onClick={() => {
                    handleConteneurClick("5");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  5
                  <Ree />
                </div>
                <div
                  className={`stock Rbox7  ${dispo("4")}`}
                  onClick={() => {
                    handleConteneurClick("4");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  4
                  <Ree />
                </div>
                <div
                  className={`stock Rbox7  ${dispo("3")}`}
                  onClick={() => {
                    handleConteneurClick("3");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  3
                  <Ree />
                </div>
                <div
                  className={`stock Rbox7  ${dispo("2")}`}
                  onClick={() => {
                    handleConteneurClick("2");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  2
                  <Ree />
                </div>
                <div
                  className={`stock Rbox7  ${dispo("1")}`}
                  onClick={() => {
                    handleConteneurClick("1");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  1
                  <Ree />
                </div>
                <div className="ligne" style={{ marginLeft: "100px" }}>
                  {" "}
                  <div
                    className={`stock Rbox  ${dispo("28")}`}
                    onClick={() => {
                      handleConteneurClick("28");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    28
                    <Ree />
                  </div>
                  <div
                    className={`stock Rbox  ${dispo("27")}`}
                    onClick={() => {
                      handleConteneurClick("27");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    27
                    <Ree />
                  </div>
                </div>
              </div>

              {/* ligne manaraka */}

              <div
                className="ligne"
                style={{ marginTop: "-10px", marginLeft: "130px" }}
              >
                <div
                  className={`stock Rbox ${dispo("8")}`}
                  onClick={() => {
                    handleConteneurClick("8");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  8
                  <Ree />
                </div>
              </div>

              {/* ligne manaraka */}
              <div className="ligne" style={{ marginLeft: "180px" }}>
                <div
                  className={`stock Rbox  ${dispo("9")}`}
                  onClick={() => {
                    handleConteneurClick("9");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  9
                  <Ree />
                </div>
                <div
                  className={`stock Rbox  ${dispo("10")}`}
                  onClick={() => {
                    handleConteneurClick("10");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  10
                  <Ree />
                </div>
                <div
                  className={`stock Rbox  ${dispo("11")}`}
                  onClick={() => {
                    handleConteneurClick("11");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  11
                  <Ree />
                </div>
                <div
                  className={`stock Rbox  ${dispo("12")}`}
                  onClick={() => {
                    handleConteneurClick("12");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  12
                  <Ree />
                </div>{" "}
                <div
                  className={`stock Rbox  ${dispo("13")}`}
                  onClick={() => {
                    handleConteneurClick("13");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  13
                  <Ree />
                </div>{" "}
                <div
                  className={`stock Rbox  ${dispo("14")}`}
                  onClick={() => {
                    handleConteneurClick("14");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  14
                  <Ree />
                </div>
                <div
                  className={`stock Rbox2  ${dispo("26")}`}
                  onClick={() => {
                    handleConteneurClick("26");
                  }}
                  style={{ marginLeft: "100px", cursor: "pointer" }}
                >
                  Box 26
                  <Ree />
                </div>{" "}
                <div
                  className={`stock Rbox2  ${dispo("EXT")}`}
                  onClick={() => {
                    handleConteneurClick("EXT");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  EXT
                  <Ree />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Rees;
