import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from 'primereact/button';
import { AjoutLocation } from "./ajoutLocation";
import { ModifierLocation } from "./modifierLocation";
import { InfoLocation } from "./infoLocation";
// import { AjoutFacture } from "./ajoutFacture";
import correctEncoding from "../../correctEncoding";
import "../locationStyle/locationStockage.css";
import { SuprLoc } from "./suprLocation";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Modal, TextField, Tooltip, Grid } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SelectRetard } from "./selectRetard";
import EuroIcon from "@mui/icons-material/Euro";
import { Sidebar } from "primereact/sidebar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";



export const LocationStockage = () => {


  const apiUrl = process.env.REACT_APP_API_URL;

  const [visibleBottom, setVisibleBottom] = useState(false);
  const isMobilePortrait = useMediaQuery("(max-width:1207px)  ");

  const [currentPage, setCurrentPage] = useState(1);
  var itemsPerPage = 0;
  if (isMobilePortrait) {
    itemsPerPage = 5;
  } else {
    itemsPerPage = 6;
  }

  const [filtre, setFiltre] = useState("enCours");
  const [limRetard, setLimRetard] = useState("");
  const [idInfoLoc, setIdInfoLoc] = useState("");
  const [afficherAjoutLoc, setAfficherAjoutLoc] = useState(false);
  const [afficherAjoutPaie, setAfficherAjoutPaie] = useState(false);
  const [selectedIdPaie, setSelectedIdPaie] = useState("");
  const [selectedNomClientP, setSelectedNomClientP] = useState("");
  const [modifierLocation, setModifierLocation] = useState(false);
  const [ajoutFact, setAjoutFact] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedNomCli, setSelectedNomCli] = useState("");
  const [locations, setLocations] = useState([]);
  const [copiedText, setCopiedText] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [suprLoc, setSuprLoc] = useState(false);
  const [stockageALibrerer, setStockageALibrerer] = useState("");
  const searchTextFieldRef = useRef(null);

  //récupération de la liste de location
  const recupererListeLoc = () => {

    try {
      axios.get(`${apiUrl}IHM/api/location.php`).then((response) => {
        const locationData = response.data;
        setLocations(locationData);
      });
    } catch (error) {
      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };



  const paie = (idLoc, nomClient) => {
    // console.log(`paiement de ${idLoc}`);
    setAfficherAjoutPaie(true);
    setSelectedIdPaie(idLoc);
    setSelectedNomClientP(nomClient);
  };

  //donne un format à tous les Dates
  const formattedDate = (dateRecup) => {
    if (dateRecup === "0000-00-00" || dateRecup === "" || dateRecup === null) {
      return ``;
    }

    const date = new Date(dateRecup);

    // Options pour le formatage
    const options = {
      day: 'numeric',   // Jour du mois
      month: 'numeric',    // Mois complet (janvier, février, etc.)
      year: 'numeric'   // Année complète
    };

    // Formatage de la date avec les options spécifiées
    return date.toLocaleDateString('fr-FR', options);
  };

  const copyToClipboard = (text) => {
    if (text !== "") {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            setCopiedText(text);
            console.log("copie annnh", text);
            setOpenSnackbar(true);
          })
          .catch((error) => {
            console.error(
              "Erreur lors de la copie dans le presse-papiers : ",
              error
            );
          });
      } else {
        // Utiliser l'API de sélection de texte comme solution de secours
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);

        setCopiedText(text);
        setOpenSnackbar(true);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    // setFiltre("enCours");
    setIdSearch("");
    setLimRetard("");
  }, [modifierLocation, afficherAjoutLoc, suprLoc]);
  const texteAffiche =
    filtre === "tard"
      ? "Locations avec de paiements en retard"
      : filtre === "tardR"
        ? 'Locations "réservés" sans paiements'
        : filtre === "tardE"
          ? 'Locations "entrées" sans paiements'
          : filtre === "enCours"
            ? "Locations en cours"
            : filtre === "enRegle"
              ? "Locations payés à jour"
              : "Liste de tout les locations";
  const [idSearch, setIdSearch] = useState("");
  const [idStock, setIdStock] = useState("");
  const indexOfLastItem = Math.min(
    currentPage * itemsPerPage,
    locations.length
  );
  const indexOfFirstItem = Math.min(
    indexOfLastItem - itemsPerPage,
    locations.length
  );

  const currentLocs = locations.slice(indexOfFirstItem, indexOfLastItem);

  const LocationListe = () => {
    return (
      <div>
        {/* <Toast ref={toast} /> */}
        <div className="toutCL">
          {" "}
          <Modal
            open={afficherAjoutLoc}
            disableBackdropClick={true}
            aria-describedby="modal-modal-description"
          >
            <AjoutLocation
              idSelected={selectedLocation}
              afficherAjoutLoc={afficherAjoutLoc}
              setAfficherAjoutLoc={setAfficherAjoutLoc}

            />
          </Modal>

          <Modal
            open={modifierLocation}
            disableBackdropClick={true}
            aria-describedby="modal-modal-description"
          >
            <ModifierLocation
              idSelected={selectedLocation}
              setModifierLocation={setModifierLocation}
              setFiltre={setFiltre}
              onClose={() => {
                setModifierLocation(false);
                recupererListeLoc(""); // Appel de listerClient après la fermeture ,,pour que les données du table se mettent à jour
              }}
            />
          </Modal>
          <Modal
            open={suprLoc}
            disableBackdropClick={true}
            aria-describedby="modal-modal-description"
          >
            <SuprLoc
              setSuprLoc={setSuprLoc}
              idSelected={selectedLocation}
              stockageALibrerer={stockageALibrerer}
            />
          </Modal>{" "}

          {!isMobilePortrait && (
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={9}>
                  <div className="flex">
                    <div className="gaucheStock">
                      <div>
                        <div className="center">

                          {/* Ajout de location 
                          <Button
                            variant="contained"
                            className="boutonAjouter"
                            sx={{}}
                            color="primary"
                            style={{ width: "350px" }}
                            onClick={() => setAfficherAjoutLoc(true)}
                          >
                            Enregistrer une location
                          </Button>*/}





                          {/* Copie d'élément */}
                          <div>
                            <Snackbar
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              open={openSnackbar}
                              autoHideDuration={3000}
                              onClose={handleCloseSnackbar}
                              message={`Copie réussie de " ${copiedText === "location.mail" ? "e-mail" : copiedText
                                } "`}
                              action={
                                <React.Fragment>
                                  <Button
                                    color="secondary"
                                    size="small"
                                    onClick={handleCloseSnackbar}
                                  >
                                    FERMER
                                  </Button>
                                  <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={handleCloseSnackbar}
                                  >
                                    <CloseIcon fontSize="small" />
                                  </IconButton>
                                </React.Fragment>
                              }
                            />
                          </div>
                        </div>

                        <div>

                          <div>

                            {" "}
                            <div>
                              <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%'
                              }}>
                                <h3 style={{
                                  margin: 0,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  maxWidth: 'calc(100% - 120px)' // Adjust based on button width
                                }}>
                                  {texteAffiche}
                                </h3>
                                <Button
                                  label="Location"
                                  icon="pi pi-plus"
                                  severity="info"
                                  style={{
                                    backgroundColor: '#146C94',
                                    flexShrink: 0 // Prevents button from shrinking
                                  }}
                                  onClick={() => setAfficherAjoutLoc(true)}
                                />
                              </div>
                              <DataTable
                                value={locations}
                                className="p-datatable"
                                paginator
                                rows={isMobilePortrait ? 10 : 10}
                                rowsPerPageOptions={[5, 8, 10, 25, 50, 500]}
                                tableStyle={{ minWidth: "50rem" }}
                                rowClassName={(rowData) =>
                                  ` ${rowData.idlocation === selectedLocation
                                    ? "rescentCli custom-row-class"
                                    : "custom-row-class"
                                  }`
                                }
                                columnSeparator="   "
                                // paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                                paginatorTemplate={
                                  "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                                }
                                paginatorRight={
                                  <span
                                    style={{ margin: "10px", fontWeight: "bold" }}
                                  >
                                    Résultats : {locations.length}
                                  </span>
                                }
                              >

                                <Column
                                  field="idStockage"
                                  header="Box"
                                  style={{ width: "5%" }}
                                  body={(rowData) => (
                                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                      {rowData.idStockage.split('||').join(', ')}
                                    </div>
                                  )}
                                ></Column>
                                <Column
                                  className="loc-column-content"
                                  field="idClient"
                                  header="Client"
                                  body={(rowData) => (
                                    <Tooltip
                                      title={
                                        <div>
                                          {rowData.mail !== "" && (
                                            <div
                                              style={{
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              <span>
                                                <ContentCopyIcon
                                                  style={{
                                                    cursor: "pointer",
                                                    padding: "2px",
                                                  }}
                                                  fontSize="small"
                                                  onClick={() =>
                                                    copyToClipboard(rowData.mail)
                                                  }
                                                />
                                                <span className="text-sm ">
                                                  {rowData.mail}
                                                </span>
                                              </span>
                                            </div>
                                          )}
                                          {rowData.mail2 !== "" && (
                                            <div
                                              style={{
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              <span className=" ">
                                                <ContentCopyIcon
                                                  style={{
                                                    cursor: "pointer",
                                                    padding: "2px",
                                                  }}
                                                  fontSize="small"
                                                  onClick={() =>
                                                    copyToClipboard(rowData.mail2)
                                                  }
                                                />
                                                {rowData.mail2}
                                              </span>
                                            </div>
                                          )}

                                          {rowData.num1 !== "" && (
                                            <div
                                              style={{
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              <span className=" ">
                                                <ContentCopyIcon
                                                  style={{
                                                    cursor: "pointer",
                                                    padding: "2px",
                                                  }}
                                                  fontSize="small"
                                                  onClick={() =>
                                                    copyToClipboard(rowData.num1)
                                                  }
                                                />
                                                {rowData.num1}
                                              </span>
                                            </div>
                                          )}

                                          {rowData.num2 !== "" && (
                                            <div
                                              style={{
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              <span className=" ">
                                                <ContentCopyIcon
                                                  style={{
                                                    cursor: "pointer",
                                                    padding: "2px",
                                                  }}
                                                  fontSize="small"
                                                  onClick={() =>
                                                    copyToClipboard(rowData.num2)
                                                  }
                                                />
                                                {rowData.num2}{" "}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      }
                                      arrow
                                    >
                                      <span
                                        className="loc-column-content1"
                                        style={{
                                          padding: "10px",
                                        }}
                                      >
                                        {rowData.idClient}- {correctEncoding(rowData.nom)}
                                      </span>
                                    </Tooltip>
                                  )}
                                ></Column>
                                <Column
                                  field="dateReservation"
                                  header=" Rés /Entrée /Sortie"
                                  style={{ width: "20%" }}
                                  body={(rowData) => (
                                    <div
                                      style={{
                                        width: "10vw",
                                        padding: "10px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      R: {formattedDate(rowData.dateReservation)}
                                      <br />
                                      E: {formattedDate(rowData.dateEntree)}
                                      <br />
                                      {filtre !== "enCours" && (
                                        <>
                                          S: {formattedDate(rowData.dateSortie)}
                                          <br />
                                        </>
                                      )}
                                    </div>
                                  )}

                                ></Column>

                                <Column
                                  style={{ width: "10%" }}
                                  field="loyer"
                                  header="Loyer"
                                  body={(rowData) => <div style={{ fontSize: "15px" }}>{rowData.loyer}€</div>}
                                />
                                <Column
                                  style={{ width: "10%" }}
                                  field="totVolume"
                                  header="Volume"
                                  body={(rowData) => (
                                    <div style={{ fontSize: "15px" }}>{rowData.totVolume} m³</div>
                                  )}
                                />
                                {filtre === "tardE" ? (
                                  <Column
                                    field="DateDiffE"
                                    header="Durée depuis l' ENTREE"
                                    body={(rowData) => (
                                      <div
                                        style={{
                                          width: "100px",
                                          fontSize: "15px",
                                        }}
                                      >
                                        {rowData.DateDiffE} jours{" "}
                                      </div>
                                    )}
                                  />
                                ) : filtre === "tardR" ? (
                                  <Column
                                    className="loc-column-content"
                                    style={{ width: "20%", textAlign: "left" }}
                                    field="DateDiffE"
                                    header="Durée depuis la RESERVATION"
                                    body={(rowData) => (
                                      <div
                                        style={{
                                          width: "100px",
                                        }}
                                      >
                                        {rowData.DateDiffR} jours{" "}
                                      </div>
                                    )}
                                  />
                                ) : (
                                  <Column
                                    field="DateDiffE"
                                    header=" Paiement"
                                    body={(rowData) => (
                                      <div
                                        style={{
                                          width: "10vw",
                                          padding: "15px",
                                        }}
                                      >
                                        {rowData.datePaiePlusRecente
                                          ? ` P : ${formattedDate(
                                            rowData.datePaiePlusRecente
                                          )} `
                                          : rowData.datePaie}
                                        <br />
                                        {rowData.dateFinPlusRecente
                                          ? `F : ${formattedDate(
                                            rowData.dateFinPlusRecente
                                          )}  `
                                          : ""}{" "}
                                        <br />
                                        {rowData.DateDiff ? (
                                          <strong>
                                            Retard : {rowData.DateDiff} jours
                                          </strong>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    )}
                                  />
                                )}
                                <Column
                                  className="loc-column-content"
                                  field="noteLocation"
                                  header="Notes"
                                  body={(rowData) => (
                                    <div style={{ fonSize: '8px' }}>{correctEncoding(rowData.noteLocation)}</div>
                                  )}
                                />{" "}
                                <Column
                                  className="loc-column-content"

                                  header="Actions"
                                  body={(rowData) =>
                                    localStorage.getItem("pr") === "sad" ||
                                      localStorage.getItem("pr") === "st" ||
                                      localStorage.getItem("pr") === "ad" ? (
                                      <span className="loc-column-content1">
                                        <ModeEditOutlineIcon
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedLocation(rowData.idLocation);
                                            setIdInfoLoc(rowData.idLocation);
                                            setSelectedNomCli(rowData.nom);

                                            setModifierLocation(true);
                                            console.log(
                                              "rowData.idlocation === selectedLocation",
                                              rowData.idlocation ===
                                              selectedLocation,
                                              rowData.idlocation,
                                              "1",
                                              selectedLocation,
                                              "2"
                                            );
                                          }}
                                        />
                                        <DeleteIcon
                                          style={{
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setSelectedLocation(rowData.idLocation);
                                            setStockageALibrerer(
                                              rowData.idStockage
                                            );
                                            setSuprLoc(true);
                                          }}
                                        />
                                        <EuroIcon
                                          style={{
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setIdInfoLoc(rowData.idLocation);
                                            setSelectedNomCli(rowData.nom);
                                            setIdStock(rowData.idStockage);
                                          }}
                                        />
                                      </span>
                                    ) : (
                                      <span className="loc-column-content1">
                                        <EuroIcon
                                          style={{
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setIdInfoLoc(rowData.idLocation);
                                            setSelectedNomCli(rowData.nom);
                                            setIdStock(rowData.idStockage);
                                          }}
                                        />
                                      </span>
                                    )
                                  }
                                ></Column>
                              </DataTable>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>

                    <div className="droiteStock">
                      <Box
                        sx={{
                          marginLeft: {
                            xs: "10px", // Pour les écrans mobiles (extra small)
                            sm: "10px", // Pour les écrans de taille moyenne (small)
                            md: "10px", // Pour les écrans de taille moyenne (medium)
                            lg: "10px", // Pour les écrans larges (large)
                          },
                        }}
                      >
                        <h2>FILTRE</h2>
                      </Box>
                      <SelectRetard
                        setLocations={setLocations}
                        setFiltre={setFiltre}
                        filtre={filtre}
                        setIdSearch={setIdSearch}
                        idSearch={idSearch}
                        afficherAjoutLoc={afficherAjoutLoc}
                        modifierLocation={modifierLocation}
                        suprLoc={suprLoc}
                        limRetard={limRetard}
                        setLimRetard={setLimRetard}
                      />
                      <div
                        className="infoLoc"
                        style={{
                          width: "80%",
                          textAlign: "center",

                        }}
                      >
                        <InfoLocation
                          idInfoLoc={idInfoLoc}
                          idStock={idStock}
                          selectedNomCli={selectedNomCli}
                        />
                      </div>
                      <br />
                    </div>
                  </div>

                </Grid>
              </Grid>

            </Box>

          )}{" "}
          {isMobilePortrait && (
            <div>
              <div className=" ">
                <div>
                  <div
                    className="center flex"
                    style={{ padding: "10px", width: "88vw" }}
                  >
                    <Box style={{ width: "90vw" }}>
                      <div>
                        <SelectRetard
                          setLocations={setLocations}
                          setFiltre={setFiltre}
                          filtre={filtre}
                          setIdSearch={setIdSearch}
                          idSearch={idSearch}
                          afficherAjoutLoc={afficherAjoutLoc}
                          modifierLocation={modifierLocation}
                          suprLoc={suprLoc}
                          limRetard={limRetard}
                          setLimRetard={setLimRetard}
                        />
                      </div>
                    </Box>
                    <Button
                      variant="contained"
                      className="boutonAjouter"
                      color="primary"
                      onClick={() => setAfficherAjoutLoc(true)}
                    >
                      <i
                        className="pi pi-plus"
                        style={{ fontSize: "1rem" }}
                      ></i>
                    </Button>
                  </div>
                  <div>
                    <Snackbar
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      open={openSnackbar}
                      autoHideDuration={3000}
                      onClose={handleCloseSnackbar}
                      message={`Copie réussie de " ${copiedText === "location.mail" ? "e-mail" : copiedText
                        } "`}
                      action={
                        <React.Fragment>
                          <Button
                            color="secondary"
                            size="small"
                            onClick={handleCloseSnackbar}
                          >
                            FERMER
                          </Button>
                          <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseSnackbar}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </React.Fragment>
                      }
                    />
                  </div>
                  <div>
                    <div>
                      <h2 className="titreTableau ">{texteAffiche}</h2>{" "}
                      <div>

                        <DataTable
                          value={locations}
                          className="p-datatable"
                          paginator
                          rows={isMobilePortrait ? 5 : 25}
                          rowsPerPageOptions={[5, 8, 10, 25, 50, 500]}
                          tableStyle={{ minWidth: "13rem" }}
                          rowClassName={(rowData) =>
                            ` ${rowData.idlocation === selectedLocation
                              ? "rescentCli custom-row-class"
                              : "custom-row-class"
                            }`
                          }
                          columnSeparator="   "
                          // paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                          paginatorTemplate={
                            "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                          }
                          paginatorRight={
                            <span
                              style={{ margin: "10px", fontWeight: "bold" }}
                            >
                              Résultats : {locations.length}
                            </span>
                          }
                        >
                          <Column
                            className="small-font-column"
                            style={{ width: "2vw", maxWidth: "2vw" }}
                            field="idStockage"
                            header="Box"
                            body={(rowData) => (
                              <div>{rowData.idStockage}</div>
                            )}
                          ></Column>
                          <Column
                            className="loc-column-content"
                            style={{ width: "8vw", maxWidth: "8vw" }}
                            field="idClient"
                            header="Client"
                            body={(rowData) => (
                              <span style={{ whiteSpace: "nowrap" }}>
                                {rowData.idClient}- {rowData.nom}
                              </span>
                            )}
                          ></Column>
                          <Column
                            style={{
                              width: "4vw",
                              maxWidth: "4vw",
                            }}
                            field="loyer"
                            header="Loyer"
                            body={(rowData) => (
                              <div>
                                {rowData.loyer} €<br />
                                {rowData.totVolume} m³
                              </div>
                            )}
                          />

                          <Column
                            className="loc-column-content"
                            style={{
                              width: "8vw",
                              maxWidth: "8vw",
                            }}
                            header="Actions"
                            body={(rowData) =>
                              localStorage.getItem("pr") === "sad" ||
                                localStorage.getItem("pr") === "st" ||
                                localStorage.getItem("pr") === "ad" ? (
                                <span className="loc-column-content1">
                                  <ModeEditOutlineIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setSelectedLocation(rowData.idLocation);
                                      setIdInfoLoc(rowData.idLocation);
                                      setSelectedNomCli(rowData.nom);

                                      setModifierLocation(true);
                                      console.log(
                                        "rowData.idlocation === selectedLocation",
                                        rowData.idlocation ===
                                        selectedLocation,
                                        rowData.idlocation,
                                        "1",
                                        selectedLocation,
                                        "2"
                                      );
                                    }}
                                  />
                                  <DeleteIcon
                                    style={{
                                      marginLeft: "10px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setSelectedLocation(rowData.idLocation);
                                      setStockageALibrerer(
                                        rowData.idStockage
                                      );
                                      setSuprLoc(true);
                                    }}
                                  />
                                  <EuroIcon
                                    style={{
                                      marginLeft: "10px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setIdInfoLoc(rowData.idLocation);
                                      setSelectedNomCli(rowData.nom);
                                      setIdStock(rowData.idStockage);
                                      setVisibleBottom(true);
                                    }}
                                  />
                                </span>
                              ) : (
                                <span className="loc-column-content1">
                                  <EuroIcon
                                    style={{
                                      marginLeft: "10px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setIdInfoLoc(rowData.idLocation);
                                      setSelectedNomCli(rowData.nom);
                                      setIdStock(rowData.idStockage);
                                      setVisibleBottom(true);
                                    }}
                                  />
                                </span>
                              )
                            }
                          ></Column>
                        </DataTable>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>

        <div className="">
          <Sidebar
            visible={visibleBottom}
            position="bottom"
            onHide={() => setVisibleBottom(false)}
            className="h-[30vh]  "
            style={{ backgroundColor: "#F6F6F6" }} // h-96 correspond à 24rem ou 96 pixels
          >
            <InfoLocation
              idInfoLoc={idInfoLoc}
              idStock={idStock}
              selectedNomCli={selectedNomCli}
            />
          </Sidebar>
        </div>
      </div>
    );
  };


  return <div> {LocationListe()}</div>;
};
