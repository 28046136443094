import React, { useEffect, useState } from "react";
import axios from "axios";
import { MyPieChart } from "../pieChart";
import "../../dashboardStyle/dashStock.css";

export const DashStkTout = ({ dim }) => {
  
  const apiUrl = process.env.REACT_APP_API_URL;
  const [totalStockages, setTotalStockages] = useState(0);
  const [stockagesOccupes, setStockagesOccupes] = useState(0);
  const [stockagesLibres, setStockagesLibres] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}IHM/api/dashboard.php?box=tout`);
        if (response.data && response.data.totalStockages !== undefined) {
          setTotalStockages(response.data.totalStockages);
          setStockagesOccupes(response.data.stockagesOccupes);
          setStockagesLibres(response.data.stockagesLibres);
        } else {
          console.error("La réponse de l'API n'est pas valide:", response.data);
          setError("Les données reçues ne sont pas valides.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
        setError("Erreur lors de la récupération des données.");
      }
    };

    fetchData();
  }, [apiUrl]);

  if (error) {
    return <div>{error}</div>;
  }

  if (totalStockages === 0) {
    return <div>Chargement des données...</div>;
  }

  return (
    <div>
      <MyPieChart data={[stockagesOccupes, stockagesLibres]} title="Tous les stockages" dim={dim} />
    </div>
  );
};