import React, { useEffect, useState } from "react";
import axios from "axios";
import { MyPieChart } from "../pieChart";
import "../../dashboardStyle/dashStock.css"; //
import { DashStkTout } from "./dashPrincStoc";
import { textAlign } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
export const DashStk = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [RDCData, setRDCData] = useState([]);
  const [etgData, setEtgData] = useState([]);
  const [contData, setContData] = useState([]);


  //RDC
  const [totalStockagesRDC,setTotalStockagesRDC] = useState(0);
  const [stockagesOccupesRDC,setStockagesOccupesRDC] = useState(0);
  const [stockagesLibresRDC,setStockagesLibresRDC] = useState(0);


   //Etage
   const [totalStockagesEtg,setTotalStockagesEtg] = useState(0);
   const [stockagesOccupesEtg,setStockagesOccupesEtg] = useState(0);
   const [stockagesLibresEtg,setStockagesLibresEtg] = useState(0);


   //Etage
   const [totalStockagesCont,setTotalStockagesCont] = useState(0);
   const [stockagesOccupesCont,setStockagesOccupesCont] = useState(0);
   const [stockagesLibresCont,setStockagesLibresCont] = useState(0);

   //on est fou
   const [totalLocation,setTotalLocation] =  useState(0);
   const [retardLocation,setRetardLocation] = useState(0);
   
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}IHM/api/dashboard.php?box=RDC`);
        if (response.data) {
          setTotalStockagesRDC(response.data.totalStockages);
          setStockagesOccupesRDC(response.data.stockagesOccupes);
          setStockagesLibresRDC(response.data.stockagesLibres);
        } else {
          console.error("La réponse de l'API n'est pas valide:", response.data);
          
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
     
      }
    };

    fetchData();
  }, [apiUrl]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}IHM/api/dashboard.php?box=etg`);
        if (response.data) {
          setTotalStockagesEtg(response.data.totalStockages);
          setStockagesOccupesEtg(response.data.stockagesOccupes);
          setStockagesLibresEtg(response.data.stockagesLibres);
        } else {
          console.error("La réponse de l'API n'est pas valide:", response.data);
          
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
     
      }
    };

    fetchData();
  }, [apiUrl]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}IHM/api/dashboard.php?box=cont`);
        if (response.data) {
          setTotalStockagesCont(response.data.totalStockages);
          setStockagesOccupesCont(response.data.stockagesOccupes);
          setStockagesLibresCont(response.data.stockagesLibres);
        } else {
          console.error("La réponse de l'API n'est pas valide:", response.data);
          
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
     
      }
    };

    fetchData();
  }, [apiUrl]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}IHM/api/dashboard.php?loc=enCours`);
        setTotalLocation(response.data.tt); 
        console.log("total des locations :", response.data.tt);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchData();
  }, [apiUrl]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}IHM/api/dashboard.php?loc=retard`);
        setRetardLocation(response.data.retard);
        console.log("total des retards :", response.data.retard);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchData();
  }, [apiUrl]);


  const occupRDC = stockagesOccupesRDC;
  const libreRDC = stockagesLibresRDC;

  const occupEtg = stockagesOccupesEtg;
  const libreEtg = stockagesLibresEtg;

  
  const occupCont = stockagesOccupesCont;
  const libreCont = stockagesLibresCont;

 const isMobilePortrait = useMediaQuery("(max-width:1207px) ");
  return (
    <>
      {isMobilePortrait && (
        <div   >
          <div  >
          <span style={{ fontWeight: "900", marginBottom: "20px" ,display: 'flex', justifyContent: 'center' }}>
       
              TAUX D’OCCUPATION 
            </span>
            <br/>
             <div className="Item">
              <DashStkTout dim="mob" />
            </div>  
             </div>
          <div   style={{ marginTop: "5%" }}>
            <div className="Item">
              <MyPieChart
                data={[occupRDC, libreRDC]}
                title="Rez de chaussée"
                dim="mob"
              />
            </div>
            <div className="Item">
              <MyPieChart data={[occupEtg, libreEtg]} title="Etage" dim="mob" />
            </div>
            <div className="Item">
              <MyPieChart
                data={[occupCont, libreCont]}
                title="Containers"
                dim="mob"
              />
            </div>
          </div>
          <div className="pie-chart-container"></div>
        </div>)}
      {!isMobilePortrait && (
        <div className="dashboard-container">
          <div className="piePrincipal">
            <span style={{ fontWeight: "900", marginBottom: "20px" }}>
              TAUX D’OCCUPATION
            </span>
            <DashStkTout />
          </div>
          <div className="containerDash" style={{ marginTop: "5%" }}>
            <div className="Item">
              <MyPieChart
                data={[occupRDC, libreRDC]}
                title="Rez de chaussée"
                dim="p"
              />
            </div>
            <div className="Item">
              <MyPieChart data={[occupEtg, libreEtg]} title="Etage" dim="p" />
            </div>
            <div className="Item">
              <MyPieChart
                data={[occupCont, libreCont]}
                title="Containers"
                dim="p"
              />
            </div>
          </div>
          <div className="pie-chart-container"></div>
        </div>)}
    </>
  );
};
