import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ClientTooltip = ({ nom, mail, num1, copyToClipboard }) => {
  return (
    <Tooltip
      title={
        <div>
          {mail !== "" && (
            <div>
              <div>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <ContentCopyIcon
                    style={{ cursor: "pointer", padding: "2px" }}
                    onClick={() => copyToClipboard(mail)}
                  />
                  {mail}
                </span>
              </div>
              <div>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <ContentCopyIcon
                    style={{ cursor: "pointer", padding: "2px" }}
                    onClick={() => copyToClipboard(num1)}
                  />
                  {num1}
                </span>
              </div>
            </div>
          )}
        </div>
      }
      arrow
    >
      <span className="loc-column-content1">{nom}</span>
    </Tooltip>
  );
};

export default ClientTooltip;
