// tableStyles.js
import { styled } from "@mui/system";
import { Paper, TableRow, TableHead } from "@mui/material";

export const StyledPaper = styled(Paper)`
  /* Styles pour Paper */

  background-color: #eaeaea;
`;

export const StyledTableRow = styled(TableRow)`
  /* Styles pour TableRow */
  &:hover {
    background-color: lightblue;
  }
`;

export const BoldTableHead = styled(TableHead)`
  /* Styles pour TableHead */
  th {
    font-weight: bold;
  }
`;
