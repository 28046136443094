import { DoughnutChart2 } from "../doughnutChart2";
export const DashRetard2 = () => {
  return (
    <div>
      <div className="contRetard">
        <span style={{ fontWeight: "900", marginTop: "20px" }}>
          DETAILS RETARDS
        </span>
      </div>

      <DoughnutChart2 />
    </div>
  );
};
