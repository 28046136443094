import React, { useRef, useEffect, useState } from "react";
import Chart from "chart.js/auto";
import axios from "axios";

export const LineChart23 = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const chartRef = useRef(null);
  const [semestreData2024, setSemestreData2024] = useState([]);
  const [semestreDataSrt2023, setSemestreDataSrt2023] = useState([]);
  const [semestreDataSrt2022, setSemestreDataSrt2022] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${apiUrl}IHM/api/dashboard.php?periodeSrt=2024`
        );
        setSemestreData2024(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchDataSrt = async () => {
      try {
        const response = await axios.post(
          `${apiUrl}IHM/api/dashboard.php?periodeSrt=2023`
        );
        setSemestreDataSrt2023(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchDataSrt();
  }, []);

  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const response = await axios.post(
          `${apiUrl}IHM/api/dashboard.php?periodeSrt=2022`
        );
        setSemestreDataSrt2022(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData1();
  }, []);

  useEffect(() => {
    if (chartRef.current && semestreData2024.length > 0 && semestreDataSrt2023.length > 0) {
      const ctx = chartRef.current.getContext("2d");

      const data = {
        labels: semestreData2024.map((item) => item.mois),
        datasets: [
          {
            label: "Sorties en 2024",
            data: semestreData2024.map((item) => item.nombre_de_locations),
            borderColor: "#146C94",
            borderWidth: 2,
            fill: false,
          },
          {
            label: "Sorties en 2023",
            data: semestreDataSrt2023.map((item) => item.nombre_de_locations),
            borderColor: "#ef476f",
            borderWidth: 2,
            fill: false,
          },
          {
            label: "Sorties en 2022",
            data: semestreDataSrt2022.map((item) => item.nombre_de_locations),
            borderColor: "#a49e87",
            borderWidth: 2,
            fill: false,
          }
        ],
      };

      const options = {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        responsive: true,
        aspectRatio: 1.4,
      };

      // Détruire le graphique existant pour éviter les doublons
      if (chartRef.current.chart) {
        chartRef.current.chart.destroy();
      }

      chartRef.current.chart = new Chart(ctx, {
        type: "line",
        data: data,
        options: options,
      });
    }
  }, [semestreData2024, semestreDataSrt2023]);

  return (
    <div className="chart-container">
      <h3 style={{ textAlign: "center" }}>Sortie</h3>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};
