// FiltreClient.js

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const FiltreClient = ({
  valueFiltre,
  handleChange,
  recherche,
  filtrerClient,
  searchTextFieldRef,
}) => {
  return (
    <div>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          id="standard-search"
          label="Recherche"
          type="text"
          variant="standard"
          value={recherche}
          onChange={(e) => {
            filtrerClient(e.target.value);
          }}
          inputRef={searchTextFieldRef}
        />
      </Box>

      <br />
      <FormControl
        component="fieldset"
        style={{ display: "flex", alignItems: "center" }}
      >
        {/* <FormLabel component="legend">Filtre</FormLabel> */}
        <RadioGroup
          row
          aria-label="controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={valueFiltre}
          onChange={handleChange}
        >
          <FormControlLabel value="tout" control={<Radio />} label="Tout" />
          <FormControlLabel value="actif" control={<Radio />} label="Actif" />
          <FormControlLabel
            value="nonActif"
            control={<Radio />}
            label="Non actif"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default FiltreClient; // Make sure to export FiltreClient as the default export
