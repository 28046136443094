import { DoughnutChart } from "../doghnutChart";

export const DashRetard = () => {
  return (
    <div>
      <div className="contRetard">
        <span style={{ fontWeight: "900" }}>RETARDS</span>
      </div>
      <DoughnutChart />
    </div>
  );
};
