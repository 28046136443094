import React, { useState,useRef, useEffect } from "react";
import Chart from "chart.js/auto";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const DoughnutChart2 = () => {
  const chartRef = useRef(null);
  const [sansPaiement,setSansPaiement] =  useState(0);
  const [retardPaiement,setRetardPaiement] = useState(0);
  
  const [retardLocation,setRetardLocation] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}IHM/api/dashboard.php?loc=sansPaie`);
        setSansPaiement(response.data.sansPaie); 
       
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchData();
  }, [apiUrl]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}IHM/api/dashboard.php?loc=retard`);
        setRetardLocation(response.data.retard);
        console.log("total des retards :", response.data.retard);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchData();
  }, [apiUrl]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}IHM/api/dashboard.php?loc=retardPaie`);
        setRetardPaiement(response.data.retardPaie);
        
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchData();
  }, [apiUrl]);


const retardPaie = ((retardPaiement / retardLocation)*100).toFixed(0);;
const sansPaie = ((sansPaiement / retardLocation)*100).toFixed(0);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      const data = {
        labels: [`Sans paiement : ${sansPaie}% (${sansPaiement}) `,`Retard de paiement ${retardPaie}% (${retardPaiement})`],
        datasets: [
          {
            data: [sansPaie, retardPaie], 
            backgroundColor: ["#FEC868", "#7EAA92"],
            borderColor: ["#feab1b", "#517b64"],
            borderWidth: 1,
          },
        ],
      };

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 70, 
      };

      const doughnutChart = new Chart(ctx, {
        type: "doughnut",
        data: data,
        options: options,
      });

      return () => {
        doughnutChart.destroy(); 
      };
    }
  }, [sansPaiement,retardPaiement,retardLocation]);

  return (
    <div style={{ marginTop: "5%" }} className="canvas-container">
      <canvas ref={chartRef} />
    </div>
  );
};
