import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "./AuthContext"; // Importez le hook useAuth

const defaultTheme = createTheme();

export const Login = () => {
  const [isConnected, setIsConnected] = useState(false);
  const { login } = useAuth(); //   hook useAuth pour accéder à la fonction login
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const apiUrl = process.env.REACT_APP_API_URL;
    const data = new FormData(event.currentTarget);

    axios
      .post(`${apiUrl}IHM/api/login.php`, {
        email: data.get("email"),
        password: data.get("password"),
      })
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("isAuthenticated", "true");
          localStorage.setItem("us", response.data.infoUser[0].idUser);
          localStorage.setItem("pr", response.data.infoUser[0].perm);

          setIsConnected(true);
          login(response.data);
          navigate("/"); // Redirige l'utilisateur après la connexion
        } else {
          setIsConnected(false);
        }
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API :", error);
        setIsConnected(false);
      });
  };

  if (isConnected) {
    return <Navigate to="/" />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 2, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5"></Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Addresse mail"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Se connecter
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2"></Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
