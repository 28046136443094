const correctEncoding = (text) => {
    try {
      // Remplace les caractères mal encodés par les caractères corrects
      return text
        .replace(/â‚¬/g, '€') // Remplacer â‚¬ par €
        .replace(/â€™/g, '’') // Remplacer â€™ par ’
        .replace(/Ã©/g, 'é') // Remplace Ã© par é
        .replace(/Ã¨/g, 'è') // Remplace Ã¨ par è
        .replace(/Ã¢/g, 'â') // Remplace Ã¢ par â
        .replace(/Ã§/g, 'ç') // Remplace Ã§ par ç
        .replace(/Ã´/g, 'ô') // Remplace Ã´ par ô
        .replace(/Ãª/g, 'ê') // Remplace Ãª par ê
        .replace(/Ã³/g, 'ó') // Remplace Ã³ par ó
        .replace(/Ã¡/g, 'á') // Remplace Ã¡ par á
        .replace(/Ãº/g, 'ú') // Remplace Ãº par ú
        .replace(/Ã±/g, 'ñ') // Remplace Ã± par ñ
        .replace(/ÃŸ/g, 'ß') // Remplace ÃŸ par ß
        .replace(/Ã‰/g, 'É')
        .replace(/Ã/g,'à')
        .replace(/à«/g,'ë')
        // Ajoutez d'autres remplacements si nécessaire
    } catch (e) {
      console.error('Error correcting encoding:', e);
      return text; // Retourne le texte original en cas d'erreur
    }
  };
  
  export default correctEncoding