import React from "react";
import axios from "axios";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import { useAuth } from "../../login/loginPage/AuthContext";
import { Navigate } from "react-router-dom";

export const Logout = ({ onClose }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { logout } = useAuth();

  const [redirect, setRedirect] = useState(false);

  const handleConfirm = () => {
    logout();

    // Crée un BroadcastChannel nommé "auth"
    const eventChannel = new BroadcastChannel("auth");

    // Envoie un message "logout" à travers le canal
    eventChannel.postMessage("logout");

    // Ferme le canal après l'envoi du message
    eventChannel.close();

    setRedirect(true);
  };

  const handleCancel = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  if (redirect) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <div>
        <div className="modalContainer" style={{ textAlign: "center" }}>
          <p>Voulez-vous vraiment vous déconnecter ? </p>
          <Button
            variant="contained"
            color="secondary"
            style={{ margin: "10px" }}
            onClick={handleConfirm}
          >
            Oui
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "10px" }}
            onClick={handleCancel}
          >
            Non
          </Button>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};
