import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";

export const MyPieChart = ({ data, title, dim }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    // Nettoyez le graphique existant s'il y en a un
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      // Vérifiez que les données sont valides
      const total = parseInt(data[0]) + parseInt(data [1]);

      if (total === 0) {
        console.error("Total des données est zéro.");
        return;
      }

      const occupiedPercentage = ((data[0] / total) * 100).toFixed(2);
      const freePercentage = ((data[1] / total) * 100).toFixed(2);

      const labels = [
        `Occupés : ${Math.round(occupiedPercentage)}% (${data[0]})`,
        `Libres : ${Math.round(freePercentage)}% (${data[1]})`,
      ];

      const chartData = {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: ["#FEC868", "#7EAA92"], // Couleurs pour occupé / libre
            borderColor: ["#feab1b", "#517b64"],
            borderWidth: 1,
          },
        ],
      };

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: function(tooltipItem) {
                const label = tooltipItem.label || '';
                const value = tooltipItem.raw || '';
                return `${label}: ${value}`;
              }
            }
          }
        }
      };

      // Créez un nouveau graphique
      chartInstance.current = new Chart(ctx, {
        type: "pie",
        data: chartData,
        options: options,
      });
    }

    // Nettoyez le graphique lors du démontage du composant
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  const containerClass = `chart-container ${dim === "p" ? "petit" : dim === "mob" ? "mob" : "grand"}`;

  return (
    <div>
      <h3 style={{ textAlign: "center" }}>{title}</h3>
      <div className={containerClass}>
        <canvas ref={chartRef} />
      </div>
    </div>
  );
};