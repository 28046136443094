import React, { useState, useRef } from "react";
import Rees from "./RDC/RDCpage/rees";
import Etage from "./etage/etagePage/etage";
import Contenneurs from "./conteneur/conteneurPage/contenneurs";
import "./stockageStyle.css";
import { TextField, useMediaQuery } from "@mui/material";
import { RechercheStockage } from "./rechercheStockage";
import { RechercheStockageMob } from "./rechercheStockageMob";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { Dialog } from "primereact/dialog";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box } from '@mui/system';
import { CP } from "../stockage/stockageCP";
import Grid from '@mui/material/Grid';

import { stockageRetard } from "../stockageRetard/stockageRetard";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';


export const Stockage = () => {
  const navigate = useNavigate();
  const op = useRef(null);
  const [search, setSearch] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const isMobilePortrait = useMediaQuery("(max-width:600px) ");
  const [value, setValue] = React.useState('1');
  const [motRecherche, setMotRecherche] = useState("");
  const [motRechercheClient, setMotRechercheClient] = useState("");
  const togglePopupVisibility = () => {
    setPopupVisible((prevVisibility) => !prevVisibility);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleButtonClick = () => {
    navigate('/stockageRetard');
  };



  return (
    <div
      style={
        !isMobilePortrait
          ? {
            left: "0",
          }
          : {
            left: "0",
            marginLeft: "60px",
            padingRight: "10px",
            width: "90vw",
            maxWidth: "90vw",
          }
      }
    >
      <div>
        {isMobilePortrait && (
          <div>
            <div className="resizeIcon">

              <div className="popup">


                <RechercheStockageMob
                  search={search}
                  motRecherche={motRecherche}
                  setMotRecherche={setMotRecherche}
                  setSearch={setSearch}
                  motRechercheClient={motRechercheClient}
                  setMotRechercheClient={setMotRechercheClient}
                  setValue={setValue}
                />
              </div>
              <div className="parentBgMobile">
                <TabContext value={value}>
                  <Box sx={{ width: '100%', bgcolor: 'background.paper', display: 'flex', justifyContent: 'center' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                      <Tab label="Rez de chaussée" value="1" />
                      <Tab label="Etage" value="2" />
                      <Tab label="CONTAINERS" value="3" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">

                    <div className="zoom-out"      >
                      <div className="bgMobile "     >

                        <Rees
                          setSearch={setSearch}
                          setMotRecherche={setMotRecherche}
                          motRechercheClient={motRechercheClient}
                          setMotRechercheClient={setMotRechercheClient}
                        />
                      </div></div>

                  </TabPanel>
                  <TabPanel value="2">
                    <div className="zoom-out "    >
                      <div className="bgMobile "    >

                        <Etage
                          setSearch={setSearch}
                          setMotRecherche={setMotRecherche}
                          motRechercheClient={motRechercheClient}
                          setMotRechercheClient={setMotRechercheClient}
                        />{" "}
                      </div>  </div>


                  </TabPanel>
                  <TabPanel value="3">
                    <div className="zoom-out-cont  "    >
                      <div className="bgMobile"     >

                        <Contenneurs
                          setSearch={setSearch}
                          setMotRecherche={setMotRecherche}

                          motRechercheClient={motRechercheClient}
                          setMotRechercheClient={setMotRechercheClient}
                        />
                      </div>
                    </div>
                  </TabPanel>
                </TabContext>
              </div>
            </div>
          </div>
        )}
        <div className="flex">
          {!isMobilePortrait && (
            <div className="gaucheStock">

              <Grid container item xs={12} md={9} spacing={2} direction="column">
                <Button label="Retard" severity="warning" rounded text raised icon="pi pi-arrow-right" onClick={handleButtonClick} />
                
                <Grid item xs={12} md={6}>
                <CP 
                setSearch={setSearch}
                setMotRecherche={setMotRecherche}
                motRechercheClient={motRechercheClient}
                setMotRechercheClient={setMotRechercheClient}/>
                </Grid>
              {/* <Grid item xs={12} md={6}>

                  <div className={isMobilePortrait ? "bgMobile" : "background"}>
                    <h3 className="titre">
                      <strong>Rez de chaussée</strong>
                    </h3>
                    <Rees
                      setSearch={setSearch}
                      setMotRecherche={setMotRecherche}
                      motRechercheClient={motRechercheClient}
                      setMotRechercheClient={setMotRechercheClient}
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={isMobilePortrait ? "bgMobile" : "background"}>
                    <h3 className="titre">
                      <strong>étage</strong>
                    </h3>
                    <Etage
                      setSearch={setSearch}
                      setMotRecherche={setMotRecherche}
                      motRechercheClient={motRechercheClient}
                      setMotRechercheClient={setMotRechercheClient}
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={isMobilePortrait ? "bgMobile" : "background"}>
                    <h3 className="titre">
                      <strong>CONTAINERS</strong>
                    </h3>
                    <Contenneurs
                      setSearch={setSearch}
                      setMotRecherche={setMotRecherche}
                      motRechercheClient={motRechercheClient}
                      setMotRechercheClient={setMotRechercheClient}
                    />
                  </div>
                </Grid> */}

                
               

              </Grid>
            </div>

          )}

          {!isMobilePortrait && (
            <div className="droiteStock">
              <RechercheStockage
                search={search}
                motRecherche={motRecherche}
                setMotRecherche={setMotRecherche}
                setSearch={setSearch}

                motRechercheClient={motRechercheClient}
                setMotRechercheClient={setMotRechercheClient}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
